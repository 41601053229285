import { CONSTANTS } from '../Global';
import socket from '../config/socket';
interface IParams {
    [key: string]: any;
}

const methods = ['GET', 'POST', 'DELETE', 'PUT', 'PATCH'];
export default function apiCall(path: string, params: IParams = {}, method: string = 'GET'): Promise<IParams> {

    return new Promise((resolve, reject) => {
        if (methods.indexOf(method) === -1) {
            reject('Method not allowed!')
        }
        let body = undefined;
        let queryString = '';
        if (method === 'GET') {
            queryString = new URLSearchParams(params).toString();
        }
        else { 
            body = JSON.stringify(params);
        }

        fetch(`${socket.serverUrl}${path}${queryString ? `?${queryString}` : ''}`, {
            method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${socket.token}`,
            },
            body
        })
            .then(response => response.json())
            .then(response => {
                resolve(response);
            }).catch((err: any) => {
                console.log('API CALL ERROR: ', err);
                reject(err);
            });
        
    })
}
