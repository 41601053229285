import { IColors, IGroupSymbol } from "../Global";
import config from "../config";
import { dayjs, newDayjs, now } from "./dateUtil";

interface IMarketStatusController {
  title: string;
  id: number;
  isClosed: boolean;
  buyBlock: boolean;
  sellBlock: boolean;
  bgColor: "ERROR" | "INFO" | "SUCCESS" | "WARNING";
}
export const marketStatusControllerConst = <Array<IMarketStatusController>>[
  {
    title: "TradeStatus_0",
    id: 0,
    isClosed: true,
    buyBlock: true,
    sellBlock: true,
    bgColor: "ERROR",
  },
  {
    title: "TradeStatus_1",
    id: 1,
    isClosed: false,
    buyBlock: false,
    sellBlock: true,
    bgColor: "INFO",
  },
  {
    title: "TradeStatus_2",
    id: 2,
    isClosed: false,
    buyBlock: true,
    sellBlock: false,
    bgColor: "INFO",
  },
  {
    title: "TradeStatus_3",
    id: 3,
    isClosed: false,
    buyBlock: true,
    sellBlock: true,
    bgColor: "INFO",
  },
  {
    title: "TradeStatus_4",
    id: 4,
    isClosed: false,
    buyBlock: false,
    sellBlock: false,
    bgColor: "SUCCESS",
  },
  {
    title: "TradeStatus_5",
    id: 5,
    isClosed: true,
    buyBlock: true,
    sellBlock: true,
    bgColor: "WARNING",
  },
];

export const getSession = (sessions: any, day: number): Array<{open: Date, close: Date}> => {
  let session = [];
  const timeZoneOffset = new Date().getTimezoneOffset();

  for (let i = 0; i < sessions[day].length; i++) {
    const {Open, Close} = sessions[day][i];

		const open = new Date();
		const close = new Date();

		open.setHours(0, Number(Open) - timeZoneOffset - config.serverTimeZone,0,0);
		close.setHours(0, Number(Close) - timeZoneOffset - config.serverTimeZone, 0, 0);

    session.push({open, close});
  }

  return session;
};


export function marketStatusController(
  groupSymbol: IGroupSymbol,
  lastTickDate: number
) {

  const tradeMode = groupSymbol.TradeMode ?? groupSymbol.Symbol.TradeMode;
  if (tradeMode == 0) {
    return marketStatusControllerConst[0];
  }

  const isClosedForSessions = !isMarketOpen(groupSymbol.Symbol.Sessions);
  if (isClosedForSessions) {
    return marketStatusControllerConst[0];
  }

  const isClosedForLastTickDate = newDayjs
    .unix(lastTickDate)
    .add(groupSymbol.Symbol.QuotesTimeout, "seconds")
    .isBefore(now());

  if (isClosedForLastTickDate) {
    return marketStatusControllerConst[5];
  }

  if (tradeMode == 1 || tradeMode == 2 || tradeMode == 3) {
    return marketStatusControllerConst[tradeMode];
  }

  return marketStatusControllerConst[4];
}

export const isMarketOpen = (sessions: any) => {
  let isMarketOpen = false;
  for (let i = 0; i < sessions.length; i++) {
    const { Open, Close } = sessions[i];
    if (now().isAfter(newDayjs(Open * 1000)) && now().isBefore(newDayjs(Close * 1000))) {
      isMarketOpen = true;
      break;
    }
  }
  return isMarketOpen;
};

export const findTradeMode = (
  mode: number,
  isClosed: boolean,
  colors: IColors
) => {
  if (isClosed) {
    return {
      title: "Kotasyon güncelleniyor.",
      color: colors.TRADE_MODE_ONLY_BUY_TEXT,
      bgColor: colors.TRADE_MODE_ONLY_BUY,
      isClosed: true,
      isShowing: true,
    };
  } else {
    switch (mode) {
      case 0:
        return {
          title: "Market Kapalı",
          color: colors.TRADE_MODE_CLOSED_TEXT,
          bgColor: colors.TRADE_MODE_CLOSED,
          isClosed: true,
          isShowing: true,
        };
      case 1:
        return {
          title: "Sadece Alış İşlemi Yapılabilir",
          color: colors.TRADE_MODE_ONLY_BUY_TEXT,
          bgColor: colors.TRADE_MODE_ONLY_BUY,
          isClosed: false,
          isShowing: true,
        };
      case 2:
        return {
          title: "Sadece Satış İşlemi Yapılabilir",
          color: colors.TRADE_MODE_ONLY_SELL_TEXT,
          bgColor: colors.TRADE_MODE_ONLY_SELL,
          isClosed: false,
          isShowing: true,
        };
      case 3:
        return {
          title: "Yalnızca Kapatma İşlemi Yapılabilir",
          color: colors.TRADE_MODE_ONLY_CLOSE_TEXT,
          bgColor: colors.TRADE_MODE_ONLY_CLOSE,
          isClosed: true,
          isShowing: true,
        };
      case 4:
        return {
          title: "İşleme Açık",
          color: colors.TRADE_MODE_BUY_SELL_TEXT,
          bgColor: colors.TRADE_MODE_BUY_SELL,
          isClosed: false,
          isShowing: false,
        };

      default:
        return {
          title: "İşleme Açık",
          color: colors.TRADE_MODE_BUY_SELL_TEXT,
          bgColor: colors.TRADE_MODE_BUY_SELL,
          isClosed: false,
          isShowing: false,
        };
    }
  }
};
