class Config {
  private _serverTimeDiff: number = 0;
  private _serverTimeZone: number = 0;
  private _accessToken?: string;

  setAccessToken(token: string) {
    this._accessToken = token;
  }

  get accessToken() {
    return this._accessToken;
  }

  setServerTime(serverTime: number){
    console.log('serverTime', serverTime, Date.now() - serverTime);
    this._serverTimeDiff = Date.now() - serverTime;
  }
  
  setServerTimeZone(serverTimeZone: number){
    console.log('serverTimeZone', serverTimeZone);
    this._serverTimeZone = serverTimeZone;
  }

  get serverTimeDiff(){
    return this._serverTimeDiff;
  }

  get serverTimeZone(){
    return this._serverTimeZone;
  }
}

const config = new Config();

export default config;