const reportTypes = [
  {id: 1, value: 'Kapanan Pozisyonlar', url: 'Positions'},
//  {id: 2, value: 'Tamamlanan Emirler', url: 'Orders'},
//{id: 3, value: 'Anlaşmalar', url: 'get-deals'},
{id: 4, value: 'Bakiye Geçmişi', url: 'Balance'},
];

const closedColumns = [
  {id: 4,  name: 'İşlem Tipi', key: 'Action'},
  {id: 1,  name: 'Tarih', key: 'Time'},
  {id: 2,  name: 'Sembol', key: 'Symbol'},
  {id: 3,  name: 'Anlaşma', key: 'Deal'},
  {id: 5,  name: 'Hacim', key: 'Volume'},
  {id: 6,  name: 'Fiyat', key: 'Price'},
  {id: 7,  name: 'Değer', key: 'Value'},
  {id: 8,  name: 'Swap', key: 'Storage'},
  {id: 9,  name: 'Fiyat SL', key: 'PriceSL'},
  {id: 10, name: 'Fiyat TP', key: 'PriceTP'},
  {id: 11, name: 'Kar', key: 'Profit'},
];
const closedColumnsNoDetails = [
  {id: 4, name: 'İşlem Tipi', key: 'Action'},
  {id: 1, name: 'Tarih', key: 'Time'},
  {id: 2, name: 'Sembol', key: 'Symbol'},
  {id: 6, name: 'Fiyat', key: 'Price'},
  {id: 11, name: 'Kar', key: 'Profit'},
];

const dealColumns = [
  {id: 5, name: 'İşlem Tipi', key: 'Action'},
  {id: 1, name: 'Tarih', key: 'Time'},
  {id: 2, name: 'Sembol', key: 'Symbol'},
  {id: 3, name: 'Anlaşma', key: 'Deal'},
  {id: 4, name: 'Emir', key: 'Order'},
  {id: 6, name: 'Yön', key: 'Entry'},
  {id: 7, name: 'Hacim', key: 'Volume'},
  {id: 8, name: 'Fiyat', key: 'Price'},
  {id: 9, name: 'Değer', key: 'Value'},
  {id: 10, name: 'Fiyat SL', key: 'PriceSL'},
  {id: 11, name: 'Fiyat TP', key: 'PriceTP'},
  {id: 12, name: 'Yorum', key: 'Comment'},
  {id: 13, name: 'Kar', key: 'Profit'},
];
const dealColumnsNoDetails = [
  {id: 5,  name: 'İşlem Tipi', key: 'Action'},
  {id: 1,  name: 'Tarih', key: 'Time'},
  {id: 2,  name: 'Sembol', key: 'Symbol'},
  {id: 3,  name: 'Anlaşma', key: 'Deal'},
  {id: 13, name: 'Kar', key: 'Profit'},
];

const orderColumns = [
  {id: 4, name:  'İşlem Tipi', key: 'Action'},
  {id: 1, name:  'Açılış Tarihi', key: 'TimeSetup'},
  {id: 2, name:  'Sembol', key: 'Symbol'},
  {id: 3, name:  'Emir', key: 'Order'},
  {id: 5, name:  'Hacim', key: 'VolumeCurrent'},
  {id: 6, name:  'Fiyat', key: 'PriceCurrent'},
  {id: 7, name:  'Fiyat SL', key: 'PriceSL'},
  {id: 8, name:  'Fiyat TP', key: 'PriceTP'},
  {id: 9, name:  'Kapanış Tarihi', key: 'TimeDone'},
  {id: 10, name: 'Durum', key: 'State'},
  {id: 11, name: 'Yorum', key: 'Comment'},
];
const orderColumnsNoDetails = [
  {id: 4, name:  'İşlem Tipi', key: 'Action'},
  {id: 9, name:  'Kapanış Tarihi', key: 'TimeDone'},
  {id: 2, name:  'Sembol', key: 'Symbol'},
  {id: 6, name:  'Fiyat', key: 'PriceCurrent'},

];
const balanceColumns = [
  {id: 3, name: 'İşlem Tipi', key: 'Action'},
  {id: 1, name: 'Tarih', key: 'Time'},
  {id: 2, name: 'Anlaşma', key: 'Deal'},
  {id: 4, name: 'Yön', key: 'Entry'},
  {id: 5, name: 'Kar', key: 'Profit'},
  {id: 6, name: 'Yorum', key: 'Comment'},
];

export {reportTypes, closedColumns, closedColumnsNoDetails, dealColumns, dealColumnsNoDetails, orderColumns, orderColumnsNoDetails, balanceColumns};
