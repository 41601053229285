interface ILanguage {
	[key: string]: string;
}

export const Japanase: ILanguage = {
	Exit: '終了',
	Login: 'ログイン',
	LoginID: 'ID',
	UserName: 'ユーザー名',
	Password: 'パスワード',
	Buy: '購入',
	Sell: '売却',
	Profit: '利益',
	Loss: '損失',
	Home: 'ホーム',
	Markets: 'マーケット',
	Transaction: '取引履歴',
	Profile: 'プロフィール',
	Spread: 'スプレッド',
	Search: '検索',
	Name: '名前',
	FirstName: '名',
	LastName: '姓',
	Email: 'Eメール',
	Phone: '電話',
	Amount: '金額',
	Button_SubmitDepositRequest: '入金リクエストの提出',
	Button_SubmitWithdrawRequest: '引き出しリクエストを送信する',
	Button_SubmitYourComplaint: '苦情の提出',
	Position: 'ポジション',
	Order: '注文',
	Login_RegisteredAccounts: '登録済みアカウント',
	Login_Servers: 'サーバー',
	Login_NoRegisteredAccounts: '登録されたアカウントが見つかりません。',
	Login_ToolTip_1: 'ログイン画面が更新されました。',
	Login_ToolTip_2: 'ユーザー名とパスワードを入力した後、[ログイン] ボタンをクリックするとログインし、ログイン情報が保存されます。',
	Login_ToolTip_3: '[ログイン] ボタンを長押しすると、ログイン情報を保存せずにログインします。',
	Login_ToolTip_4: '登録済みのユーザーがある場合、希望のユーザーでログインできます。',
	Login_ToolTip_5: '登録済みのユーザーを長押しすると、選択したアカウントが保存されていないものとして削除されます。',
	Login_ToolTip_6: 'ここからサーバーを選択する必要があります。',
	Login_ToolTip_7: 'ログイン時に選択したサーバーに特に注意してください。',
	SideMenu_Deposit: '入金',
	SideMenu_Withdraw: '出金',
	SideMenu_Notifications: '通知',
	SideMenu_BankStatement: '取引明細書',
	SideMenu_Transaction: '取引履歴 ',
	SideMenu_ChangePassword: 'パスワードの変更',
	SideMenu_ChangePasswordForInvestor: '観客のパスワードを変更する',
	SideMenu_Traders: 'トレーダー',
	SideMenu_Robots: 'ロボット',
	SideMenu_LiveSupport: 'ライブサポート',
	SideMenu_CustomerComplaint: '顧客の苦情',
	SideMenu_Settings: '設定',
	SideMenu_Share: '友達と共有',
	SideMenu_References: '紹介',
	Favorites: 'お気に入り',
	Forex: '外国為替',
	Crypto: '暗号通貨',
	Indices: '指数',
	Metal: '金属',
	Energy: 'エネルギー',
	Stock: '株式',
	History: '履歴',
	Positions: 'オープンポジション',
	Orders: 'オープンオーダー',
	Home_RecommendedActions: '本日のおすすめアクション',
	Home_Announcement: 'フォローしたい通貨ペアにスターをつけることができます。',
	Router_Login: 'ログイン',
	Router_TransactionDetails: '取引履歴',
	Router_Trade: '取引',
	Router_TradingView: '詳細チャート',
	Router_DepositSelection: '入金',
	Router_DepositWithCash: '銀行振込での入金',
	Router_Withdraw: '銀行振込での出金',
	Router_CurrencySelection: '銀行振込での入金',
	Router_BankTransaction: '銀行振込での入金',
	Router_DepositWithCrypto: '暗号通貨での入金',
	Router_ChangePassword: 'パスワードの変更',
	Router_ChangePasswordForInvestor: '観客のパスワードを変更する',
	Router_CustomerComplaint: '顧客の苦情',
	Router_References: '紹介',
	Router_AddReferences: '紹介を追加',
	Router_Settings: '設定',
	Router_Home: 'ホーム',
	Router_Profile: 'プロフィール',
	Router_Symbols: 'マーケット',
	Router_Transaction: '私の取引',
	Balance: '残高',
	Equity: '資産総額',
	Credit: '信用取引',
	Margin: '使用された証拠金',
	Margin_Free: '自由証拠金',
	Margin_Level: '証拠金レベル',
	Share: '友達と共有',
	Floating: '浮動損益',
	UserCard_PhoneError: '電話番号が登録されていません！',
	DepositSelection_WithCash: '銀行振込で入金',
	DepositSelection_WithCrypto: '暗号通貨で入金',
	DepositSelection_Warning: '適切な入金方法を選択してください。',
	DepositWithCash_Warning: '適切な銀行振込情報を取得するには、入金したい銀行を選択し、通貨を選択してください。',
	CurrencySelection_Warning: '適切な銀行振込情報を取得するには、入金したい銀行を選択し、通貨を選択してください。',
	DepositTransaction_BankName: '銀行名',
	DepositTransaction_Name: '受取人名',
	DepositTransaction_Currency: '通貨',
	DepositTransaction_IBAN: 'IBAN',
	DepositTransaction_Description: '説明',
	DepositWithCrypto_Warning_1: '適切な暗号通貨情報を取得するには、入金したい暗号通貨を選択し、ネットワークを選択してください。',
	DepositWithCrypto_Warning_2: 'ネットワークユニットを選択した後、情報カードからコピーできます。',
	DepositWithCrypto_SelectCrypto: '暗号通貨を選択',
	DepositWithCrypto_SelectNetwork: 'ネットワークユニットを選択',
	DepositWithCrypto_SelectNetwork_Warning: 'まずは暗号通貨を選択してください',
	DepositWithCrypto_Message: 'メッセージ',
	CustomerComplaint_Message: 'あなたのメッセージ',
	CustomerComplaint_Warning: '以下のフィールドに記入して問題を伝えることができます。WhatsApp経由で送信されます。',
	Settings_Language: '言語',
	Settings_SearchLanguage: '言語を検索...',
	Settings_DarkMode: 'ダークモード',
	Settings_Notifications: '通知',
	Trade_AdditionalTransactions: '追加取引',
	Trade_MarketOrder: '成行注文',
	Trade_TakeProfit: '利益確定注文',
	Trade_StopLoss: '損失制限注文',
	Trade_Create: '作成',
	Trade_BuyOrder: '買い注文を作成',
	Trade_SellOrder: '売り注文を作成',
	Trade_BuyLimit: '指値買い注文を作成',
	Trade_SelllLimit: '指値売り注文を作成',
	Trade_BuyStop: '逆指値買い注文を作成',
	Trade_SellStop: '逆指値売り注文を作成',
	TradeStatus_0: '市場が閉じています',
	TradeStatus_1: '購入のみ可能',
	TradeStatus_2: '売りのみ可能',
	TradeStatus_3: 'クローズのみ可能',
	TradeStatus_4: '取引可能',
	TradeStatus_5: '見積もり待ち',
	Trade_Type_0: '買い',
	Trade_Type_1: '売り',
	Trade_Type_2: '指値買い',
	Trade_Type_3: '指値売り',
	Trade_Type_4: '逆指値買い',
	Trade_Type_5: '逆指値売り',
	Trade_Type_6: '逆指値・指値買い',
	Trade_Type_7: '逆指値・指値売り',
	Trade_Type_8: '決済注文',
	Positions_TimeCreate: '日付',
	Positions_Position: 'ポジション',
	Positions_VolumeCurrent: 'ロット',
	Positions_PriceCurrent: '現在の価格',
	Positions_PriceSL: '損失制限価格',
	Positions_PriceTP: '利益確定価格',
	Positions_Swap: 'スワップ',
	Positions_Profit: '損益',
	Position_PartialClose: '部分決済',
	Position_ClosePosition: 'ポジションを閉じる',
	Position_ModifyPosition: '変更',
	ModifyPosition_Title: 'ポジションを変更',
	ModifyPosition_Position: 'ポジション番号',
	ModifyPosition_PriceCurrent: '現在の価格',
	ModifyPosition_PriceSL: '損失制限価格',
	ModifyPosition_PriceTP: '利益確定価格',
	ModifyPosition_Accept: '承諾',
	ModifyPosition_Cancel: 'キャンセル',
	ModifyOrder_Title: '注文を変更',
	ModifyOrder_Order: '注文番号',
	ModifyOrder_PriceCurrent: '現在価格',
	ModifyOrder_PriceOrder: '注文価格',
	ModifyOrder_PriceSL: '損失制限',
	ModifyOrder_PriceTP: '利益確定',
	ModifyOrder_LastTime: '有効期限',
	ModifyOrder_LastTimeMessage: '注文の有効期限を選択してください。',
	ModifyOrder_Accept: '承諾',
	ModifyOrder_Cancel: 'キャンセル',
	ModifyOrder_LastTimeOk: 'OK',
	ModifyOrder_LastTimeCancel: 'キャンセル',
	Orders_Order: 'チケット',
	Orders_Lot: 'ロット',
	Orders_PriceOrder: '注文価格',
	Orders_PriceSL: '損失制限',
	Orders_PriceTP: '利益確定',
	Orders_PriceCurrent: '現在価格',
	Orders_Reason: '理由',
	Orders_Comment: 'コメント',
	Orders_DeleteOrder: '注文を削除',
	Orders_ModifyOrder: '変更',
	Orders_Type_0: 'カスタマー',
	Orders_Type_1: 'エキスパート',
	Orders_Type_2: 'セラー',
	Orders_Type_3: 'SL',
	Orders_Type_4: 'TP',
	Orders_Type_5: 'SO',
	Orders_Type_6: '丸め',
	Orders_Type_7: '外部顧客',
	Orders_Type_8: 'Vトレランス',
	SymbolProperties_SwapRates: 'スワップレート',
	SymbolProperties_Sessions: 'セッション',
	SymbolProperties_SessionsTrade: 'トレード',
	SymbolProperties_ContractSize: '契約サイズ',
	SymbolProperties_Spread: 'スプレッド',
	SymbolProperties_StopsLevel: 'ストップレベル',
	SymbolProperties_CurrencyMargin: '通貨マージン',
	SymbolProperties_VolumeMin: '最小取引量',
	SymbolProperties_VolumeMax: '最大取引量',
	SymbolProperties_Monday: '月曜日',
	SymbolProperties_Tuesday: '火曜日',
	SymbolProperties_Wednesday: '水曜日',
	SymbolProperties_Thursday: '木曜日',
	SymbolProperties_Friday: '金曜日',
	SymbolProperties_Saturday: '土曜日',
	SymbolProperties_Sunday: '日曜日',
	History_Button: 'フィルター',
	History_Positions: 'クローズポジション',
	History_Balance: '残高履歴',
	History_MyTransactions: '私の取引履歴',
	History_DatePicker_StartTitle: '開始日を選択',
	History_DatePicker_EndTitle: '終了日を選択',
	History_DatePicker_Ok: 'OK',
	History_DatePicker_Cancel: 'キャンセル',
	History_NoData: '表示する履歴はありません。',
	HistoryPositions_Symbol: 'シンボル',
	HistoryPositions_Deal: 'ディール',
	HistoryPositions_Volume: 'ロット',
	HistoryPositions_Price: 'オープニング価格',
	HistoryPositions_PricePosition: 'クロージング価格',
	HistoryPositions_PriceSL: '損失制限',
	HistoryPositions_PriceTP: '利益確定',
	HistoryPositions_Swap: 'スワップ',
	HistoryPositions_Profit: '損益',
	HistoryPositions_Action_0: '売り',
	HistoryPositions_Action_1: '買い',
	HistoryPositions_Action_2: '指値売り',
	HistoryPositions_Action_3: '指値買い',
	HistoryPositions_Action_4: '逆指値売り',
	HistoryPositions_Action_5: '逆指値買い',
	HistoryPositions_Action_6: '逆指値売り(リミット)',
	HistoryPositions_Action_7: '逆指値買い(リミット)',
	HistoryPositions_Action_8: '注文による決済',
	HistoryBalance_Time: '日付',
	HistoryBalance_Ticket: '取引',
	HistoryBalance_Profit: '価格',
	HistoryBalance_Type: 'タイプ',
	HistoryBalance_Comment: 'コメント',
	HistoryBalance_Action_2: '残高',
	HistoryBalance_Action_3: 'クレジット',
	HistoryBalance_Action_4: '追加料金/引き出し',
	HistoryBalance_Action_5: '訂正',
	HistoryBalance_Action_6: 'ボーナス',
	HistoryBalance_Action_7: '手数料',
	ChangePassword_CurrentPassword: '現在のパスワード',
	ChangePassword_NewPassword: '新しいパスワード',
	ChangePassword_ConfirmNewPassword: '新しいパスワードを確認',
	ChangePassword_Button: '保存',
	ChangePassword_Checker_UpperCase: '大文字を使用してください',
	ChangePassword_Checker_LowerCase: '小文字を使用してください',
	ChangePassword_Checker_Number: '数字を使用してください',
	ChangePassword_Checker_SpecialChar: '特殊文字を使用してください',
	ChangePassword_Checker_Length: '少なくとも6文字である必要があります',
	ChangePassword_Checker_OriginalPassword: '古いパスワードは正しい必要があります',
	ChangePassword_Checker_PasswordsMatch: '新しいパスワードは一致する必要があります',
	ReferencesAdd_Button: '参照を追加',
	ReferencesAdd_Error: '参照の追加中にエラーが発生しました。\n後でもう一度お試しください。',
	FlowMessage_Timeout: 'トランザクション後に応答がありませんでした。',
	FlowMessage_Success: 'トランザクションが正常に完了しました。',
	FlowMessage_Error: 'トランザクションの処理中にエラーが発生しました。後でもう一度やり直してください。',
	FlowMessage_InvalidPrice: '有効な価格を入力してください。',
	FlowMessage_InfoCardCopied: '値がクリップボードにコピーされました。',
	FlowMessage_UnderConstruction: 'アクセスしようとしているページは現在工事中です。',
	FlowMessage_InvalidLogin: 'ユーザー名またはパスワードが間違っています。',
	FlowMessage_LoginSuccess: 'ログインに成功しました。ようこそ！',
	FlowMessage_LoginError: 'ログイン中にエラーが発生しました！後でもう一度やり直してください。',
	FlowMessage_UnfilledFieldWarning: 'すべてのフィールドに入力してください。',
	FlowMessage_ModifySuccess: '変更が正常に完了しました。',
	FlowMessage_PositionClose: 'ポジションが正常にクローズされました。',
	FlowMessage_OrderDelete: '注文が正常に削除されました。',
	FlowMessage_PositionCloseRejected: 'ポジションのクローズに失敗しました。',
	FlowMessage_OrderDeleteRejected: '注文の削除に失敗しました。',
	FlowMessage_Login_InvalidInformation: '不完全または正しくないログイン情報です。',
	FlowMessage_Login_GroupNotFound: 'グループが見つかりません。',
	FlowMessage_Login_CUN_Error: 'ログインするには最新のアプリケーションをダウンロードする必要があります。',
	FlowMessage_Logout_N: 'ログアウトに成功しました。',
	FlowMessage_Logout_R: 'ログアウトしました。再度ログインしています...',
	FlowMessage_Logout_0: 'サーバーメンテナンスのため、ログアウトされました。',
	FlowMessage_Logout_1: '接続が失われたため、ログアウトされました。',
	FlowMessage_Logout_UserDeleted: 'ユーザーが削除されたため、ログアウトされました。',
	FlowMessage_Logout_DataSourceIsNotReady: 'セッションの有効期限が切れました。もう一度ログインしてください。',
	FlowMessage_Logout_LoginFromAnotherDevice: '別のデバイスからログインしました。ログアウトされました。',
	FlowMessage_Logout_GroupChanged: '接続が失われたため、ログアウトされました。もう一度ログインしてください。',
	FlowMessage_Logout_ConnectionFailed: '接続が失われたため、ログアウトされました。もう一度ログインしてください。',
	FlowMessage_ChangeInformationSuccess: '情報が更新されました。\nログイン画面にリダイレクトしています。',
	FlowMessage_ChangeInformationError: 'エラーが発生しました。パスワードを変更できませんでした。後でもう一度やり直してください。',
	FlowMessage_CustomerComplaintSuccess: 'お問い合わせを受け付けました。',
	FlowMessage_InvestorError: '投資家アカウントでは取引できません。',
	FlowMessage_SymbolNotFound: 'シンボル情報が見つかりません。',
	FlowMessage_Trade_Waiting: '注文処理中です。お待ちください...',
	FlowMessage_Trade_InvalidOrderType: '無効な注文形式です。',
	FlowMessage_Trade_InvalidRequestType: '無効なリクエスト形式です。',
	FlowMessage_Trade_InvalidVolume: '無効な取引量です。',
	FlowMessage_Trade_InvalidPrice: '無効な価格です。',
	FlowMessage_Trade_Buy_MarketLimit: 'マーケットリミット値は買値以下にすることはできません。',
	FlowMessage_Trade_Buy_StopLoss: 'ストップロス値は買値以上にすることはできません。',
	FlowMessage_Trade_Buy_TakeProfit: 'テイクプロフィット値は買値以下にすることはできません。',
	FlowMessage_Trade_Sell_MarketLimit: 'マーケットリミット値は売値以下にすることはできません。',
	FlowMessage_Trade_Sell_StopLoss: 'ストップロス値は売値以下にすることはできません。',
	FlowMessage_Trade_Sell_TakeProfit: 'テイクプロフィット値は売値以上にすることはできません。',
	FlowMessage_Deposit: '入金通知後、投資専門家に通知してください。',
	FlowMessage_Withdraw: '出金通知後、投資専門家に通知してください。',
	FlowMessage_PhoneError: '無効な電話番号です！\n電話番号は数字のみで構成されている必要があります。',
	FlowMessage_EmailError: '無効なメールアドレスです！\n有効なメールアドレスを入力してください。',
	FlowMessage_ReferenceAdded: '参照が正常に追加されました。',
	FlowMessage_ReferenceAllReadyCollected: 'ボーナスはすでに受け取っています！',
	FlowMessage_ReferenceUnderReview: 'ボーナスはまだ承認されていません！',
	FlowMessage_ReferenceNotEnough: 'ボーナスのための参照が不足しています！',
	FlowMessage_InvalidComplaint: '有効な苦情を入力してください。',
	Retcode_0: '',
	Retcode_10001: 'リクエストが進行中です。',
	Retcode_10002: 'リクエストが受け入れられました。',
	Retcode_10003: 'リクエストが処理中です。',
	Retcode_10004: 'リクエストに応じて提供されたオファー。',
	Retcode_10005: 'リクエストに応じて提供された価格。',
	Retcode_10006: 'リクエストが拒否されました。',
	Retcode_10007: 'リクエストがキャンセルされました。',
	Retcode_10008: 'リクエストの結果として配置された注文。',
	Retcode_10009: 'リクエストが完了しました。',
	Retcode_10010: 'リクエストが部分的に実行されました。',
	Retcode_10011: '一般的なリクエストエラー。',
	Retcode_10012: 'リクエストのタイムアウト。',
	Retcode_10013: '無効なリクエスト。',
	Retcode_10014: '無効なボリューム。',
	Retcode_10015: '無効な価格。',
	Retcode_10016: '不正確なストップレベルまたは価格。',
	Retcode_10017: '取引が無効になっています。',
	Retcode_10018: '市場は閉じられています。',
	Retcode_10019: '十分な資金がありません。',
	Retcode_10020: '価格が変動しました。',
	Retcode_10021: '価格が利用できません。',
	Retcode_10022: '無効な注文期間。',
	Retcode_10023: '注文が修正されました。',
	Retcode_10024: '取引リクエストが多すぎます。たとえば、管理者APIインスタンスから128件を超える取引リクエストを送信しようとすると、このエラーが発生する可能性があります。',
	Retcode_10025: 'リクエストに変更が含まれていません。',
	Retcode_10026: 'サーバーでオートトレーディングが無効になっています。',
	Retcode_10027: 'クライアント側でオートトレーディングが無効になっています。',
	Retcode_10028: 'ディーラーによってブロックされたリクエスト。',
	Retcode_10029: '注文またはポジションが市場に対して近すぎて変更が失敗しました。',
	Retcode_10030: '埋め込みモードはサポートされていません。',
	Retcode_10031: '接続がありません。',
	Retcode_10032: '実際の口座でのみ許可されています。',
	Retcode_10033: '注文の数制限に達しました。',
	Retcode_10034: 'ボリューム制限に達しました。',
	Retcode_10035: '無効または禁止された注文タイプ。',
	Retcode_10036: 'ポジションはすでに閉じられています。たとえば、ポジションのストップレベルを変更しようとすると、このエラーが発生する可能性があります。',
	Retcode_10037: '内部目的で使用。',
	Retcode_10038: 'クローズされているオープンポジションの現在のボリュームを超えてクロージングボリュームがあります。',
	Retcode_10039: 'ポジションをクローズするための注文がすでにあります。このエラーは、逆のポジションでポジションを閉じようとするか、既存のポジションをすべてまたは一部閉じようとする場合に発生します。既存のクローズ注文の合計ボリュームがポジションの現在のボリュームを超え、新しく発注された注文がある場合にエラーが生成されます。',
	Retcode_10040:
		'アカウントで同時にオープンできるポジションの数は、グループ設定で制限できます。制限に達すると、注文を配置しようとするとサーバーはRetcode_REQUEST_LIMIT_POSITIONSを返します。制限は、ポジション計算タイプに応じて異なります。ネッティング - オープンポジションの数が考慮されます。制限に達すると、プラットフォームはオープンポジションの数を増やす可能性がある取引の配置を無効にします。実際、プラットフォームは既にオープンポジションを持っているシンボルのためにのみ注文を配置することを許可します。既存のペンディング注文は考慮されません。なぜなら、それらは既存のポジションに変更をもたらす可能性があるが、ポジションの数を増やすことはできないからです。ヘッジ - ペンディング注文はオープンポジションと一緒に考慮されます。制限に達すると、プラットフォームは新しい市場注文を配置して新しいポジションを開くことを無効にし、ペンディング注文を配置することも無効にします。',
	Retcode_10041: 'リクエストが拒否され、注文がキャンセルされました。このコードは、ルーティングルールでIMTConRoute::ACTION_CANCEL_ORDERアクションが適用された場合に返されます。',
	Retcode_10042: 'シンボル(IMTConSymbol::TRADE_LONGONLY)に「長いポジションのみ許可」ルールが設定されているため、リクエストが拒否されました。',
	Retcode_10043: 'シンボル(IMTConSymbol::TRADE_SHORTONLY)に「短いポジションのみ許可」ルールが設定されているため、リクエストが拒否されました。',
	Retcode_10044: 'シンボル(IMTConSymbol::TRADE_CLOSEONLY)に「クローズポジションのみ許可」ルールが設定されているため、リクエストが拒否されました。',
	Retcode_10045: 'FIFOルールに準拠していないため、ポジションのクローズができません。これは、IMTConGroup::TRADEFLAGS_FIFO_CLOSEオプションが有効になっているグループで使用されます。このオプションでは、すべてのポジションは順番に閉じる必要があります。最古のポジションが最初に閉じられ、次に次のポジションが閉じられます。',
	Retcode_10046: '逆の注文やポジションが既にある状態でユーザーが取引を試みると、IMTConGroup::TRADEFLAGS_HEDGE_PROHIBITフラグが有効になっている場合、ポジションを開くかペンディングオーダーを出すことはできません。このエラーが返されます。',
};
