import React from 'react';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';
import { Fontisto } from '@expo/vector-icons';
import {useTheme} from '../../context/Theme/ThemeProvider';

interface ICheckboxProps {
  onPress(): void;
  label: string;
  checked: boolean;
  checkBoxWidth?: number;
  checkBoxHeight?: number;
}

const Checkbox: React.FC<ICheckboxProps> = ({onPress, checked, label, checkBoxHeight=25, checkBoxWidth= 25}) => {
  const {colors} = useTheme();

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={onPress}>
      {checked ? (
        <Fontisto name='checkbox-active'
          size={(checkBoxHeight + checkBoxWidth) / 2}
          color={colors.TEXT}
        />
      ) : (
        <Fontisto name='checkbox-passive'
          size={(checkBoxHeight + checkBoxWidth) / 2}
          color={colors.TEXT}
        />
      )}
      <Text style={[styles.label, {color: colors.TEXT}]}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: 16,
    marginLeft: 5,
  },
});

export default Checkbox;
