import {Image, ImageRequireSource, StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle} from 'react-native';
// global
import {FONTSIZE, FONTWEIGHT} from '../../Global';
import { AntDesign } from '@expo/vector-icons';
import {Icons} from '../../screens/Bank/constants';
import {IColors} from '../../Global';
import {useTheme} from '../../context/Theme/ThemeProvider';

interface Props {
  icon: any;
  title: string;
  route: string;
  param: any;
  iconIsBox?: boolean;
  navigation: any;
  style?: StyleProp<ViewStyle>;
	iconColor?: string;
}

const getIcon = (icon: string) => {
	if (Icons[icon] != undefined) {
		return Icons[icon];
	}
	return Icons.bank;
};
const index = ({title, route, icon, param, iconIsBox, navigation, style, iconColor}: Props) => {
  const {colors} = useTheme();

  const styles = Styles(colors);

  const handlePress = () => {
    navigation.navigate(route,param);
  };

  return (
    <TouchableOpacity
			style={[styles.BankCardWithIcon, style]}
			onPress={handlePress}
		>
			<View style={{ flex: iconIsBox ? 0.8 : 1.6,height:'100%'}}>
				<Image
					source={getIcon(icon)}
					style={[{ resizeMode: 'contain', height: '100%', maxWidth: '100%' }, !!iconColor && { tintColor: iconColor }]}
				/>
			</View>
			<Text style={[iconIsBox ? styles.menuTextBOX : styles.menuText, { flex: iconIsBox ? 4.5 : 3.7 }]}>{title}</Text>
			<AntDesign name='doubleright' size={15} color={colors.TEXT} style={{textAlign: 'right',flexGrow: 0, flex: 0.7}} />
		</TouchableOpacity>
    
  );
};

export default index;

const Styles = (COLOR: IColors) =>
	StyleSheet.create({
		BankCardWithIcon: {
			flexDirection: 'row',
			alignItems: 'center',
			paddingHorizontal: '5%',
			paddingVertical: 20,
			height: 70,
			borderRadius: 8,
			backgroundColor: COLOR.CARD_BACKGROUND1,
			width: '100%',
		},
		menuText: {
			fontSize: FONTSIZE.l,
			color: COLOR.TEXT,
			overflow: 'hidden',
			marginHorizontal: 10,
		},
		menuTextBOX: {
			fontSize: FONTSIZE.l,
			color: COLOR.TEXT,
			overflow: 'hidden',
			marginHorizontal: 10,
		},
	});
