import {Text, View} from 'react-native';
import {FONTSIZE, PADDING} from '../../Global';
import BankCardIcon from '../../components/BankCardWithIcon';
import {useTheme} from '../../context/Theme/ThemeProvider';
import { useLanguage } from '../../context/Language/LanguageProvider';

const CurrencySelection = (props: any) => {
  const bank = props.route.params.Bank;
  const {colors, darkMode} = useTheme();
  const {words} = useLanguage();

  return (
    <View style={{flex: 1, flexDirection: 'column', gap: 5, paddingHorizontal: PADDING.S, backgroundColor: colors.SCREEN_BACKGROUND}}>
    <View style={{backgroundColor: colors.CARD_BACKGROUND1, borderRadius: 5, marginVertical: 5}}>
      <Text style={{textAlign: 'justify', paddingVertical: 8, paddingHorizontal: '5%', fontSize: FONTSIZE.m, color: colors.TEXT}}>{words["CurrencySelection_Warning"]}</Text>
    </View>
    <View style={{flex: 1, flexDirection: 'column', gap: 10}}>
      {bank?.currencies.map((item: any) => (
        <BankCardIcon
          key={item.currency}
          title={bank.title + ' / ' + item.currency + ' Hesabı'}
          icon={bank.id}
          route="BankTransaction"
          param={{Bank: bank, Currency: item.currency, Phone: props.route.params.Phone, iconColor: darkMode ? colors.WHITE: undefined }}
          navigation={props.navigation}
          iconColor={darkMode ? colors.WHITE: undefined}
        />
      ))}
    </View>
  </View>
  );
};

export default CurrencySelection;
