import { useContext, useEffect, useState } from "react";
import { Text, TextInput, View } from "react-native";
import InfoCard from "../../components/InfoCard";
import MainButton from "../../components/MainButton";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/Theme/ThemeProvider";
import apiCall from "../../utils/apiCall";
import { useLanguage } from "../../context/Language/LanguageProvider";
import { showFlowMessage } from "../../components/FlowMessage";
import { Audio } from "expo-av";
import dayjs from "dayjs";
import InputCard from "../../components/InputCard";

async function SoundError() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../../assets/sounds/soundserror.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

async function SoundSuccess() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../../assets/sounds/soundssuccess.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

const Withdraw = (props: any) => {
  const { colors } = useTheme();
  const { words } = useLanguage();
  const { user } = useContext(AuthContext);
  const [price, setPrice] = useState("0");
  const [currency, setCurrency] = useState("USD");

  const [phone, setPhone] = useState();

  // useEffect(() => {
  //   if (!phone) {
  //     apiCall("/banks")
  //       .then((response) => {
  //         setPhone(response.data.WinexMarkets.Phones.bank.withdraw);
  //       })
  //       .catch((err: any) => {
  //         console.log(err);
  //       });
  //   }
  // }, []);

  function WhatsappMessageSend() {
    if (!price || isNaN(Number(price))) {
      SoundError();
      showFlowMessage({
        message: words["FlowMessage_InvalidPrice"],
        type: "error",
        duration: 1000,
      });
      return;
    } else if (price == "0") {
      SoundError();
      showFlowMessage({
        message: words["FlowMessage_InvalidPrice"],
        type: "error",
        duration: 1000,
      });
      return;
    }

    const type = 0;
    const subject = `Para Çekme Talebi - Hesap ID: ${user?.ID} - ${user?.Name}`;
		const message = `
Sayın Yatırım Uzmanı,

İlgili Hesap Sahibi: ${user?.Name}
Hesap ID: ${user?.ID}
İşlem Tarihi: ${dayjs().startOf('second').format('DD/MM/YYYY HH:mm')}
		
Yatırımcımız ${user?.Name}, ${dayjs().startOf('second').format('DD/MM/YYYY HH:mm')} tarihinde ${Number(price)} ${currency} miktarında para çekme talebinde bulunmuştur.
		
Lütfen gerekli işlemleri gerçekleştirip talebin değerlendirilmesini sağlayınız.
		
Detaylı bilgi için lütfen yatırımcı iletişime geçiniz.
		
Yatırımcı Telefon Numarası: ${user?.Phone}
		
		

Bu Mesaj WinexMarkets Mobile Tarafından İletilmiştir.`;

const datas = {
  price: Number(price),
  currency: currency,
}
const body = {
  type: type,
  subject: subject,
  message: message,
  data: datas,
};

    apiCall("/send-mail", body, "POST")
      .then((response) => {
        SoundSuccess();
        showFlowMessage({
          message: words["FlowMessage_Withdraw"],
          type: "success",
          duration: 5000,
        });
        props?.navigation?.navigate("Home");
      })
      .catch((err) => {
        console.log(err);
        SoundError();
        showFlowMessage({
          message: words["FlowMessage_Error"],
          type: "error",
          duration: 5000,
        });
      });
  }

  return (
    <View style={{ height: "100%", backgroundColor: colors.SCREEN_BACKGROUND }}>
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
          padding: 10,
          backgroundColor: colors.SCREEN_BACKGROUND,
        }}
      >
        <View style={{ width: "100%", gap: 10 }}>
          <InfoCard
            type="basic"
            args={{ title: "LOGIN", value: user?.ID }}
          />
          <InfoCard type="basic" args={{ title: "İsim", value: user?.Name }} />
        </View>
        <View
          style={{
            width: "100%",
            height: 2,
            borderRadius: 10,
            backgroundColor: colors.TEXT,
          }}
        ></View>
        <InputCard
          type="withCurrency"
          args={{
            title: words["Amount"],
            value: price || '0',
            currency: 'USD',
          }}
          containerStyle={{ backgroundColor: colors.CARD_BACKGROUND2}}
          keyboardType="numeric"
          response={(newText) => setPrice(newText)}
        />
        {/* <View
          style={{
            width: "100%",
            backgroundColor: colors.CARD_BACKGROUND2,
            borderRadius: 5,
            flexDirection: "row",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Text style={{ flexBasis: "25%", color: colors.TEXT }}>Miktar</Text>
          <TextInput
            keyboardType="numeric"
            style={{ flexBasis: "60%", textAlign: "right", color: colors.TEXT }}
            onChangeText={(newText) => setPrice(newText)}
            defaultValue={price}
          />
          <Text
            style={{ flexBasis: "15%", textAlign: "right", color: colors.TEXT }}
          >
            {currency}
          </Text>
        </View> */}
        <View style={{ width: "100%" }}>
          <MainButton
            onPress={WhatsappMessageSend}
            text={words['Button_SubmitWithdrawRequest']}
          />
        </View>
      </View>
    </View>
  );
};

export default Withdraw;
