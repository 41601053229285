import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import SymbolIcon from "../Symbol/SymbolListItem/SymbolIcon";

import { useTheme } from "../../context/Theme/ThemeProvider";
import { IColors, ISymbol } from "../../Global";
import apiCall from "../../utils/apiCall";
import { AntDesign } from "@expo/vector-icons";

interface Props {
  Symbol: ISymbol;
  symbolDescription: string;
  isFavorited: boolean;
  onFavoriteChange: any;
}

const SymbolCard: React.FC<Props> = ({
  Symbol,
  symbolDescription,
  isFavorited,
  onFavoriteChange,
}: Props) => {
  const { colors } = useTheme();
  const styles = Styles(colors);

  const pushPopFavorite = async (symbol: string) => {
    apiCall(
      "/set-favorite",
      {
        symbol: symbol,
        isFavorited: !isFavorited,
      },
      "POST"
    ).then((response) => {
      onFavoriteChange(response.isFavorited);
    });
    //  try {
    //    // check if symbol is in favorites
    //    const isSymbolInFavorites = favorites.includes(symbol);
    //    if (isSymbolInFavorites) {
    //      // remove symbol from favorites
    //      const newFavorites = favorites.filter((item:any) => item !== symbol);
    //      await AsyncStorage.setItem('favorites', JSON.stringify(newFavorites));
    //      setFavorites(newFavorites);
    //    } else if (!isSymbolInFavorites) {
    //      // add symbol to favorites
    //      const newFavorites = [...favorites, symbol];
    //      await AsyncStorage.setItem('favorites', JSON.stringify(newFavorites));
    //      setFavorites(newFavorites);
    //    }
    //  } catch (e) {
    //    console.log(e);
    //  }
  };

  return (
    <View style={styles.symbolCardWrapper}>
      <View style={styles.symbolWrapper}>
        <SymbolIcon
          Symbol={Symbol.Symbol}
          CurrencyBase={Symbol.CurrencyBase}
          CurrencyProfit={Symbol.CurrencyProfit}
          test={Symbol}
          Category={Symbol.Category}
        />
        <View style={styles.symbolTextWrapper}>
          <Text style={styles.textShort}>{Symbol.Symbol}</Text>
          <Text style={styles.textLong}>{symbolDescription}</Text>
        </View>
      </View>
      <View>
        <TouchableOpacity onPress={() => pushPopFavorite(Symbol.Symbol)}>
          {!isFavorited ? (
            <AntDesign name="staro" size={30} color={colors.TEXT} />
          ) : (
            <AntDesign name="star" size={30} color={colors.TEXT} />
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SymbolCard;

const Styles = (COLOR: IColors) =>
  StyleSheet.create({
    symbolCardWrapper: {
      flex: 1,
      backgroundColor: COLOR.CARD_BACKGROUND1,
      padding: 15,
      borderRadius: 5,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 10,
      shadowColor: COLOR.SHADOW3,
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.1,
      shadowRadius: 2,
      maxHeight: 100,
    },
    symbolWrapper: {
      flex: 1,
      flexDirection: "row",
      gap: 10,
    },
    symbolTextWrapper: {
      flexDirection: "column",
      gap: 5,
    },
    textShort: {
      fontSize: 13,
      opacity: 0.7,
      color: COLOR.TEXT,
    },
    textLong: {
      fontSize: 18,
      fontWeight: "500",
      color: COLOR.TEXT,
    },
  });
