import React from 'react'
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import CustomHeader from '../CustomHeader';
import { IColors } from '../../Global';
import Home from "../../screens/Home";
import Symbols from "../../screens/Symbols";
import Transaction from "../../screens/Transaction";
import Profile from "../../screens/Profile";
import { AntDesign } from '@expo/vector-icons';
import { ILanguage } from '../../screens/Settings/constants';
import { useLanguage } from '../../context/Language/LanguageProvider';
import { useTheme } from '../../context/Theme/ThemeProvider';

export const BottomTabs = () => {
    const { words } = useLanguage();
    const { colors } = useTheme();

    const Tab = createBottomTabNavigator();
    return (
        <Tab.Navigator
          screenOptions={{
            header: CustomHeader,
            headerShown: true,
            unmountOnBlur: true,
            tabBarActiveTintColor: colors.BOTTOM_TEXT_FOCUSED,
            tabBarInactiveTintColor: colors.BOTTOM_TEXT,
            tabBarStyle: {
              backgroundColor: colors.HEADER_BACKGROUND,
              borderTopWidth: 0,
              elevation: 0,
              paddingVertical: 5,
              gap: 5,
            },
          }}
        >
          <Tab.Screen
            key="Home"
            name="Home"
            component={Home}
            options={{
              title: words['Router_Home'],
              tabBarIcon: ({ focused }) => (
                <AntDesign
                  name="home"
                  size={25}
                  color={focused ? colors.BOTTOM_ICON_FOCUSED : colors.BOTTOM_ICON}
                />
              ),
              tabBarIconStyle: {
                color: colors.TEXT,
              },
            }}
          />
    
          <Tab.Screen
            key="Symbols"
            name="Symbols"
            component={Symbols}
            options={{
              title: words['Router_Symbols'],
              tabBarIcon: ({ focused }) => (
                <AntDesign
                  name="appstore-o"
                  size={25}
                  color={focused ? colors.BOTTOM_ICON_FOCUSED : colors.BOTTOM_ICON}
                />
              ),
              tabBarIconStyle: {
                color: colors.TEXT,
              },
            }}
          />
          <Tab.Screen
            key="Transaction"
            name="Transaction"
            component={Transaction}
            options={{
              title: words['Router_Transaction'],
              tabBarIcon: ({ focused }) => (
                <AntDesign
                  name="retweet"
                  size={25}
                  color={focused ? colors.BOTTOM_ICON_FOCUSED : colors.BOTTOM_ICON}
                />
              ),
              tabBarIconStyle: {
                color: colors.TEXT,
              },
            }}
          />
    
          <Tab.Screen
            key="Profile"
            name="Profile"
            component={Profile}
            options={{
              title: words['Router_Profile'],
              tabBarIcon: ({ focused }) => (
                <AntDesign
                  name="profile"
                  size={25}
                  color={focused ? colors.BOTTOM_ICON_FOCUSED : colors.BOTTOM_ICON}
                />
              ),
              tabBarIconStyle: {
                color: colors.TEXT,
              },
            }}
          />
        </Tab.Navigator>
      );
}
