interface ILanguage {
	[key: string]: string;
}

export const Persian: ILanguage = {
	Exit: 'خروج',
	Login: 'ورود',
	LoginID: 'شناسه',
	UserName: 'نام کاربری',
	Password: 'رمز عبور',
	Buy: 'خرید',
	Sell: 'فروش',
	Profit: 'سود',
	Loss: 'ضرر',
	Home: 'خانه',
	Markets: 'بازارها',
	Transaction: 'تراکنش‌های من',
	Profile: 'پروفایل',
	Spread: 'اسپرد',
	Search: 'جستجو',
	Name: 'نام',
	FirstName: 'نام',
	LastName: 'نام خانوادگی',
	Email: 'ایمیل',
	Phone: 'تلفن',
	Amount: 'مقدار',
	Button_SubmitDepositRequest: 'ثبت درخواست واریز',
	Button_SubmitWithdrawRequest: 'ثبت درخواست برداشت',
	Button_SubmitYourComplaint: 'ثبت شکایت شما',
	Position: 'موقعیت',
	Order: 'سفارش',
	Login_RegisteredAccounts: 'حساب‌های ثبت‌نام شده',
	Login_Servers: 'سرورها',
	Login_NoRegisteredAccounts: 'حساب ثبت‌نام شده‌ای یافت نشد.',
	Login_ToolTip_1: 'صفحه ورود مجدداً بارگذاری شد.',
	Login_ToolTip_2: "پس از وارد کردن نام کاربری و رمز عبور، کلیک کردن روی دکمه 'ورود' شما را وارد می‌کند و اطلاعات ورود شما را ذخیره می‌کند.",
	Login_ToolTip_3: "اگر دکمه 'ورود' را فشار دهید و نگه دارید، به صورت بدون ذخیره اطلاعات ورود وارد شوید.",
	Login_ToolTip_4: 'هنگامی که کاربران ثبت‌نام شده دارید، می‌توانید با هر کاربری که می‌خواهید وارد شوید.',
	Login_ToolTip_5: 'اگر روی یک کاربر ثبت‌نام شده فشار دهید و نگه دارید، حساب فشار داده شده را از لیست حساب‌های ذخیره شده حذف می‌کنید.',
	Login_ToolTip_6: 'شما باید یک سرور را از اینجا انتخاب کنید.',
	Login_ToolTip_7: 'هنگام ورود، به خصوص به سرور انتخاب شده توجه کنید.',
	SideMenu_Deposit: 'واریز',
	SideMenu_Withdraw: 'برداشت',
	SideMenu_Notifications: 'اطلاعیه‌ها',
	SideMenu_BankStatement: 'گواهی بانکی',
	SideMenu_Transaction: 'تاریخچه تراکنش',
	SideMenu_ChangePassword: 'تغییر رمز عبور',
	SideMenu_ChangePasswordForInvestor: 'تغییر رمز عبور برای سرمایه‌گذار',
	SideMenu_Traders: 'معامله‌گران',
	SideMenu_Robots: 'ربات‌ها',
	SideMenu_LiveSupport: 'پشتیبانی زنده',
	SideMenu_CustomerComplaint: 'شکایت مشتری',
	SideMenu_Settings: 'تنظیمات',
	SideMenu_Share: 'اشتراک گذاری با دوستان',
	SideMenu_References: 'مراجع',
	Favorites: 'مورد علاقه‌ها',
	Forex: 'فارکس',
	Crypto: 'رمزارز',
	Indices: 'شاخص‌ها',
	Metal: 'فلزات',
	Energy: 'انرژی',
	Stock: 'سهام',
	History: 'تاریخچه',
	Positions: 'مواقع باز',
	Orders: 'سفارشات باز',
	Home_RecommendedActions: 'اقدامات پیشنهادی روز',
	Home_Announcement: 'می‌توانید جفت مورد نظر خود را ستاره‌دار کنید.',
	Home_ProminentInvestors: 'سرمایه‌گذاران برجسته',
	Router_Login: 'ورود',
	Router_TransactionDetails: 'جزئیات تراکنش من',
	Router_Trade: 'معامله',
	Router_TradingView: 'نمودار دقیق',
	Router_DepositSelection: 'واریز',
	Router_DepositWithCash: 'واریز با انتقال بانکی',
	Router_Withdraw: 'برداشت با انتقال بانکی',
	Router_CurrencySelection: 'واریز با انتقال بانکی',
	Router_BankTransaction: 'واریز با انتقال بانکی',
	Router_DepositWithCrypto: 'واریز با رمزارز',
	Router_ChangePassword: 'تغییر رمز عبور',
	Router_ChangePasswordForInvestor: 'تغییر رمز عبور برای سرمایه‌گذار',
	Router_CustomerComplaint: 'شکایت مشتری',
	Router_References: 'مراجع',
	Router_AddReferences: 'افزودن مراجع',
	Router_Settings: 'تنظیمات',
	Router_Home: 'خانه',
	Router_Profile: 'پروفایل',
	Router_Symbols: 'بازارها',
	Router_Transaction: 'تراکنش‌های من',
	Balance: 'تراز',
	Equity: 'سهام',
	Credit: 'اعتبار',
	Margin: 'مارجین استفاده شده',
	Margin_Free: 'مارجین آزاد',
	Margin_Level: 'سطح مارجین',
	Share: 'اشتراک‌گذاری با دوستان',
	Floating: 'سود/ضرر شناور',
	UserCard_PhoneError: 'شماره تلفن ثبت نشده است!',
	DepositSelection_WithCash: 'واریز با انتقال بانکی',
	DepositSelection_WithCrypto: 'واریز با رمزارز',
	DepositSelection_Warning: 'لطفاً روش واریز مناسب را انتخاب کنید.',
	DepositWithCash_Warning: 'برای دریافت اطلاعات مناسب انتقال بانکی، لطفاً بانک مورد نظر خود را انتخاب کنید و سپس ارز مورد نظر را انتخاب کنید.',
	CurrencySelection_Warning: 'برای دریافت اطلاعات مناسب انتقال بانکی، لطفاً بانک مورد نظر خود را انتخاب کنید و سپس ارز مورد نظر را انتخاب کنید.',
	DepositTransaction_BankName: 'نام بانک',
	DepositTransaction_Name: 'نام گیرنده',
	DepositTransaction_Currency: 'ارز',
	DepositTransaction_IBAN: 'شماره IBAN',
	DepositTransaction_Description: 'توضیحات',
	DepositWithCrypto_Warning_1: 'برای دریافت اطلاعات رمزارز مناسب، لطفاً رمزارز مورد نظر خود را انتخاب کنید، سپس شبکه را انتخاب کنید.',
	DepositWithCrypto_Warning_2: 'پس از انتخاب واحد شبکه، می‌توانید آن را از کارت اطلاعات کپی کنید.',
	DepositWithCrypto_SelectCrypto: 'انتخاب رمزارز',
	DepositWithCrypto_SelectNetwork: 'انتخاب واحد شبکه',
	DepositWithCrypto_SelectNetwork_Warning: 'ابتدا یک رمزارز را انتخاب کنید',
	DepositWithCrypto_Message: 'پیام',
	CustomerComplaint_Message: 'پیام شما',
	CustomerComplaint_Warning: 'می‌توانید مشکل خود را با نوشتن آن در فیلد زیر انتقال دهید. این اطلاعات از طریق WhatsApp ارسال خواهد شد.',
	Settings_Language: 'زبان',
	Settings_SearchLanguage: 'جستجوی زبان...',
	Settings_DarkMode: 'حالت تیره',
	Settings_Notifications: 'اطلاعیه‌ها',
	Trade_AdditionalTransactions: 'تراکنش‌های اضافی',
	Trade_MarketOrder: 'سفارش بازار',
	Trade_MarketClosed: 'بازار بسته است',
	Trade_TakeProfit: 'سود گرفتن',
	Trade_StopLoss: 'توقف ضرر',
	Trade_Create: 'ایجاد',
	Trade_BuyOrder: 'ایجاد سفارش خرید',
	Trade_SellOrder: 'ایجاد سفارش فروش',
	Trade_BuyLimit: 'ایجاد سفارش خرید محدود',
	Trade_SelllLimit: 'ایجاد سفارش فروش محدود',
	Trade_BuyStop: 'ایجاد سفارش خرید توقف',
	Trade_SellStop: 'ایجاد سفارش فروش توقف',
	TradeStatus_0: 'بازار بسته است',
	TradeStatus_1: 'فقط خرید مجاز است',
	TradeStatus_2: 'فقط فروش مجاز است',
	TradeStatus_3: 'فقط بستن مجاز است',
	TradeStatus_4: 'معامله باز است',
	TradeStatus_5: 'منتظر نقل قول',
	Trade_Type_0: 'خرید',
	Trade_Type_1: 'فروش',
	Trade_Type_2: 'سفارش خرید محدود',
	Trade_Type_3: 'سفارش فروش محدود',
	Trade_Type_4: 'سفارش خرید توقف',
	Trade_Type_5: 'سفارش فروش توقف',
	Trade_Type_6: 'سفارش خرید توقف محدود',
	Trade_Type_7: 'سفارش فروش توقف محدود',
	Trade_Type_8: 'بستن با سفارش',
	Positions_NoData: 'هیچ موقعیت بازی برای نمایش وجود ندارد.',
	Positions_TimeCreate: 'تاریخ',
	Positions_Position: 'موقعیت',
	Positions_VolumeCurrent: 'لات',
	Positions_PriceCurrent: 'قیمت فعلی',
	Positions_PriceSL: 'توقف ضرر',
	Positions_PriceTP: 'سود گرفتن',
	Positions_Swap: 'اسواپ',
	Positions_Profit: 'سود/ضرر',
	Position_PartialClose: 'بستن قسمتی',
	Position_ClosePosition: 'بستن موقعیت',
	Position_ModifyPosition: 'اصلاح',
	ModifyPosition_Title: 'اصلاح موقعیت',
	ModifyPosition_Position: 'شماره موقعیت',
	ModifyPosition_PriceCurrent: 'قیمت فعلی',
	ModifyPosition_PriceSL: 'توقف ضرر',
	ModifyPosition_PriceTP: 'سود گرفتن',
	ModifyPosition_Accept: 'پذیرش',
	ModifyPosition_Cancel: 'لغو',
	ModifyOrder_Title: 'اصلاح سفارش',
	ModifyOrder_Order: 'شماره سفارش',
	ModifyOrder_PriceCurrent: 'قیمت فعلی',
	ModifyOrder_PriceOrder: 'قیمت سفارش',
	ModifyOrder_PriceSL: 'توقف ضرر',
	ModifyOrder_PriceTP: 'سود گرفتن',
	ModifyOrder_LastTime: 'تاریخ انقضا',
	ModifyOrder_LastTimeMessage: 'یک تاریخ انقضا برای سفارش خود انتخاب کنید.',
	ModifyOrder_Accept: 'پذیرش',
	ModifyOrder_Cancel: 'لغو',
	ModifyOrder_LastTimeOk: 'باشه',
	ModifyOrder_LastTimeCancel: 'لغو',
	Orders_NoData: 'هیچ سفارش بازی برای نمایش وجود ندارد.',
	Orders_Order: 'بلیط',
	Orders_Lot: 'لات',
	Orders_PriceOrder: 'قیمت سفارش',
	Orders_PriceSL: 'توقف ضرر',
	Orders_PriceTP: 'سود گرفتن',
	Orders_PriceCurrent: 'قیمت فعلی',
	Orders_Reason: 'دلیل',
	Orders_Comment: 'نظر',
	Orders_DeleteOrder: 'حذف سفارش',
	Orders_ModifyOrder: 'اصلاح',
	Orders_Type_0: 'مشتری',
	Orders_Type_1: 'متخصص',
	Orders_Type_2: 'فروشنده',
	Orders_Type_3: 'SL',
	Orders_Type_4: 'TP',
	Orders_Type_5: 'SO',
	Orders_Type_6: 'گرد کردن',
	Orders_Type_7: 'مشتری خارجی',
	Orders_Type_8: 'V Tolerance',
	SymbolProperties_SwapRates: 'نرخ‌های اسواپ',
	SymbolProperties_Sessions: 'جلسات',
	SymbolProperties_SessionsTrade: 'معامله',
	SymbolProperties_ContractSize: 'اندازه قرارداد',
	SymbolProperties_Spread: 'اسپرد',
	SymbolProperties_StopsLevel: 'سطح توقف',
	SymbolProperties_CurrencyMargin: 'مارجین ارز',
	SymbolProperties_VolumeMin: 'حداقل حجم',
	SymbolProperties_VolumeMax: 'حداکثر حجم',
	SymbolProperties_Monday: 'دوشنبه',
	SymbolProperties_Tuesday: 'سه‌شنبه',
	SymbolProperties_Wednesday: 'چهارشنبه',
	SymbolProperties_Thursday: 'پنج‌شنبه',
	SymbolProperties_Friday: 'جمعه',
	SymbolProperties_Saturday: 'شنبه',
	SymbolProperties_Sunday: 'یک‌شنبه',
	History_Button: 'فیلتر',
	History_Positions: 'موقعیت‌های بسته‌شده',
	History_Balance: 'تاریخچه موازنه',
	History_MyTransactions: 'تراکنش‌های من',
	History_DatePicker_StartTitle: 'تاریخ شروع را انتخاب کنید',
	History_DatePicker_EndTitle: 'تاریخ پایان را انتخاب کنید',
	History_DatePicker_Ok: 'تایید',
	History_DatePicker_Cancel: 'لغو',
	History_NoData: 'هیچ تاریخچه‌ای برای نمایش وجود ندارد.',
	HistoryPositions_Symbol: 'نماد',
	HistoryPositions_PositionID: 'موقعیت',
	HistoryPositions_Volume: 'لات',
	HistoryPositions_Price: 'قیمت ابتدایی',
	HistoryPositions_PricePosition: 'قیمت پایانی',
	HistoryPositions_PriceSL: 'توقف زیان',
	HistoryPositions_PriceTP: 'گرفتن سود',
	HistoryPositions_Swap: 'سوآپ',
	HistoryPositions_Profit: 'سود/زیان',
	HistoryPositions_Commission: 'کمیسیون‌ها',
	HistoryPositions_Action_0: 'فروش',
	HistoryPositions_Action_1: 'خرید',
	HistoryPositions_Action_2: 'فروش محدود',
	HistoryPositions_Action_3: 'خرید محدود',
	HistoryPositions_Action_4: 'فروش توقفی',
	HistoryPositions_Action_5: 'خرید توقفی',
	HistoryPositions_Action_6: 'فروش محدود توقف',
	HistoryPositions_Action_7: 'خرید محدود توقف',
	HistoryPositions_Action_8: 'بستن با سفارش',
	HistoryBalance_Time: 'تاریخ',
	HistoryBalance_Ticket: 'معامله',
	HistoryBalance_Profit: 'قیمت',
	HistoryBalance_Type: 'نوع',
	HistoryBalance_Comment: 'توضیحات',
	HistoryBalance_Action_2: 'موازنه',
	HistoryBalance_Action_3: 'اعتبار',
	HistoryBalance_Action_4: 'هزینه‌ها/برداشت‌های اضافی',
	HistoryBalance_Action_5: 'تصحیح',
	HistoryBalance_Action_6: 'بونوس‌ها',
	HistoryBalance_Action_7: 'کمیسیون',
	ChangePassword_CurrentPassword: 'رمز عبور فعلی',
	ChangePassword_NewPassword: 'رمز عبور جدید',
	ChangePassword_ConfirmNewPassword: 'تایید رمز عبور جدید',
	ChangePassword_Button: 'ذخیره',
	ChangePassword_Checker_UpperCase: 'از حروف بزرگ استفاده کنید',
	ChangePassword_Checker_LowerCase: 'از حروف کوچک استفاده کنید',
	ChangePassword_Checker_Number: 'از اعداد استفاده کنید',
	ChangePassword_Checker_SpecialChar: 'از کاراکترهای ویژه استفاده کنید',
	ChangePassword_Checker_Length: 'حداقل باید 6 کاراکتر باشد',
	ChangePassword_Checker_OriginalPassword: 'رمز عبور قدیمی شما باید صحیح باشد',
	ChangePassword_Checker_PasswordsMatch: 'رمزهای عبور جدید باید مطابقت داشته باشند',
	ReferencesAdd_Button: 'افزودن مرجع',
	ReferencesAdd_Error: 'خطا در افزودن مرجع\nلطفاً بعداً دوباره امتحان کنید.',
	FlowMessage_Timeout: 'پس از تراکنش پاسخی دریافت نشد.',
	FlowMessage_Success: 'تراکنش با موفقیت انجام شد.',
	FlowMessage_Error: 'هنگام پردازش تراکنش خطایی رخ داد. لطفاً بعداً دوباره امتحان کنید.',
	FlowMessage_InvalidPrice: 'لطفاً قیمت معتبری وارد کنید.',
	FlowMessage_InfoCardCopied: 'مقدار به کلیپ بورد کپی شد.',
	FlowMessage_UnderConstruction: 'صفحه‌ای که تلاش می‌کنید دسترسی پیدا کنید در حال ساخت است.',
	FlowMessage_InvalidLogin: 'نام کاربری یا رمز عبور اشتباه است.',
	FlowMessage_LoginSuccess: 'ورود با موفقیت انجام شد. خوش آمدید!',
	FlowMessage_LoginError: 'هنگام ورود خطایی رخ داد! لطفاً بعداً دوباره امتحان کنید.',
	FlowMessage_UnfilledFieldWarning: 'لطفاً همه فیلدها را پر کنید.',
	FlowMessage_ModifySuccess: 'تغییرات شما با موفقیت اعمال شد.',
	FlowMessage_PositionClose: 'موقعیت با موفقیت بسته شد.',
	FlowMessage_OrderDelete: 'سفارش با موفقیت حذف شد.',
	FlowMessage_PositionCloseRejected: 'بستن موقعیت با شکست مواجه شد.',
	FlowMessage_OrderDeleteRejected: 'حذف سفارش با شکست مواجه شد.',
	FlowMessage_Login_InvalidInformation: 'اطلاعات ورود ناقص یا نادرست است.',
	FlowMessage_Login_GroupNotFound: 'گروه پیدا نشد.',
	FlowMessage_Login_CUN_Error: 'برای ورود باید آخرین نسخه برنامه را دانلود کنید.',
	FlowMessage_Logout_N: 'شما با موفقیت خارج شده‌اید.',
	FlowMessage_Logout_R: 'شما خارج شده‌اید. دوباره وارد حساب کاربری می‌شوید...',
	FlowMessage_Logout_0: 'شما به دلیل تعمیرات سرور خارج شده‌اید.',
	FlowMessage_Logout_1: 'شما به دلیل از دست دادن اتصال خارج شده‌اید.',
	FlowMessage_Logout_UserDeleted: 'شما به دلیل حذف حساب کاربری خارج شده‌اید.',
	FlowMessage_Logout_DataSourceIsNotReady: 'نشست شما منقضی شده است. لطفاً دوباره وارد شوید.',
	FlowMessage_Logout_LoginFromAnotherDevice: 'از دستگاه دیگری وارد شده‌اید. شما خارج شده‌اید.',
	FlowMessage_Logout_GroupChanged: 'شما به دلیل از دست دادن اتصال خارج شده‌اید. لطفاً دوباره وارد شوید.',
	FlowMessage_Logout_ConnectionFailed: 'شما به دلیل از دست دادن اتصال خارج شده‌اید. لطفاً دوباره وارد شوید.',
	FlowMessage_ChangeInformationSuccess: 'اطلاعات شما با موفقیت به‌روزرسانی شد. \nدر حال انتقال به صفحه ورود.',
	FlowMessage_ChangeInformationError: 'خطایی رخ داده است. رمز عبور شما تغییر نکرد. لطفاً بعداً دوباره امتحان کنید.',
	FlowMessage_CustomerComplaintSuccess: 'شکایت شما ثبت شده است.',
	FlowMessage_InvestorError: 'شما نمی‌توانید در حساب سرمایه‌گذاری تجارت کنید.',
	FlowMessage_SymbolNotFound: 'اطلاعات نماد یافت نشد.',
	FlowMessage_Trade_Waiting: 'در حال پردازش سفارش، لطفاً منتظر بمانید...',
	FlowMessage_Trade_InvalidOrderType: 'فرمت سفارش نامعتبر است.',
	FlowMessage_Trade_InvalidRequestType: 'فرمت درخواست نامعتبر است.',
	FlowMessage_Trade_InvalidVolume: 'حجم نامعتبر است.',
	FlowMessage_Trade_InvalidPrice: 'قیمت نامعتبر است.',
	FlowMessage_Trade_Buy_MarketLimit: 'ارزش محدودیت بازار نمی‌تواند کمتر یا مساوی قیمت خرید باشد.',
	FlowMessage_Trade_Buy_StopLoss: 'ارزش توقف زیان نمی‌تواند بیشتر یا مساوی قیمت خرید باشد.',
	FlowMessage_Trade_Buy_TakeProfit: 'ارزش گرفتن سود نمی‌تواند کمتر یا مساوی قیمت خرید باشد.',
	FlowMessage_Trade_Sell_MarketLimit: 'ارزش محدودیت بازار نمی‌تواند کمتر یا مساوی قیمت فروش باشد.',
	FlowMessage_Trade_Sell_StopLoss: 'ارزش توقف زیان نمی‌تواند کمتر یا مساوی قیمت فروش باشد.',
	FlowMessage_Trade_Sell_TakeProfit: 'ارزش گرفتن سود نمی‌تواند بیشتر یا مساوی قیمت فروش باشد.',
	FlowMessage_Deposit: 'پس از اعلام واریز، مشاور سرمایه‌گذاری خود را مطلع کنید.',
	FlowMessage_Withdraw: 'پس از اعلام برداشت، مشاور سرمایه‌گذاری خود را مطلع کنید.',
	FlowMessage_PhoneError: 'شماره تلفن نامعتبر است!\nشماره تلفن شما باید فقط شامل ارقام باشد.',
	FlowMessage_EmailError: 'آدرس ایمیل نامعتبر است!\nلطفاً یک آدرس ایمیل معتبر وارد کنید.',
	FlowMessage_ReferenceAdded: 'مرجع با موفقیت اضافه شد.',
	FlowMessage_ReferenceAllReadyCollected: 'شما قبلاً بونوس خود را دریافت کرده‌اید!',
	FlowMessage_ReferenceUnderReview: 'بونوس شما هنوز تأیید نشده است!',
	FlowMessage_ReferenceNotEnough: 'شما به تعداد کافی مراجع برای بونوس ندارید!',
	FlowMessage_InvalidComplaint: 'لطفاً یک شکایت معتبر وارد کنید.',
	Retcode_0: '',
	Retcode_10001: 'درخواست در حال ارسال است.',
	Retcode_10002: 'درخواست پذیرفته شد.',
	Retcode_10003: 'درخواست در حال پردازش است.',
	Retcode_10004: 'پیشنهاد در پاسخ به درخواست دریافت شد.',
	Retcode_10005: 'قیمت‌ها به عنوان پاسخ به درخواست ارائه شدند.',
	Retcode_10006: 'درخواست رد شد.',
	Retcode_10007: 'درخواست لغو شد.',
	Retcode_10008: 'یک سفارش به عنوان نتیجه درخواست قرار داده شد.',
	Retcode_10009: 'درخواست تکمیل شد.',
	Retcode_10010: 'درخواست جزئیاً انجام شد.',
	Retcode_10011: 'خطای عمومی درخواست.',
	Retcode_10012: 'زمان اجرای درخواست تمام شد.',
	Retcode_10013: 'درخواست نامعتبر است.',
	Retcode_10014: 'حجم نامعتبر است.',
	Retcode_10015: 'قیمت نامعتبر است.',
	Retcode_10016: 'سطوح توقف یا قیمت نادرست است.',
	Retcode_10017: 'تجارت غیرفعال است.',
	Retcode_10018: 'بازار بسته است.',
	Retcode_10019: 'موجودی کافی نیست.',
	Retcode_10020: 'قیمت تغییر کرده است.',
	Retcode_10021: 'قیمت در دسترس نیست.',
	Retcode_10022: 'مدت زمان سفارش نامعتبر است.',
	Retcode_10023: 'سفارش اصلاح شده است.',
	Retcode_10024: 'تعداد زیادی درخواست تجارت. به عنوان مثال، این خطا هنگام تلاش برای ارسال بیش از ۱۲۸ درخواست تجارت از یک نمونه API مدیر رخ می‌دهد.',
	Retcode_10025: 'درخواست تغییراتی ندارد.',
	Retcode_10026: 'تجارت خودکار در سرور غیرفعال است.',
	Retcode_10027: 'تجارت خودکار در سمت مشتری غیرفعال است.',
	Retcode_10028: 'درخواست توسط دلال مسدود شده است.',
	Retcode_10029: 'اصلاح شکست خورد به دلیل سفارش یا موقعیتی که بسیار نزدیک به بازار است.',
	Retcode_10030: 'حالت پر کردن پشتیبانی نمی‌شود.',
	Retcode_10031: 'هیچ اتصالی وجود ندارد.',
	Retcode_10032: 'تنها برای حساب‌های واقعی مجاز است.',
	Retcode_10033: 'تعداد سفارش محدودیت رسیده است.',
	Retcode_10034: 'محدودیت حجم رسیده است.',
	Retcode_10035: 'نوع سفارش نامعتبر یا ممنوع است.',
	Retcode_10036: 'موقعیت قبلاً بسته شده است. به عنوان مثال، این خطا هنگام تلاش برای تغییر سطوح توقف یک موقعیت رخ می‌دهد.',
	Retcode_10037: 'برای اهداف داخلی استفاده می‌شود.',
	Retcode_10038: 'حجم بستن موقعیت فعلی را بیشتر می‌کند.',
	Retcode_10039: 'قبلاً یک سفارش برای بستن موقعیت وجود دارد. این خطا در حالت hedge اتفاق می‌افتد: هنگام تلاش برای بستن یک موقعیت با یک موقعیت مخالف یا تلاش برای بستن تمام یا بخشی از یک موقعیت موجود، خطا ایجاد می‌شود اگر حجم کلیت سفارشات بسته موجود موقعیت را بیشتر از حجم فعلی موقعیت و سفارش جدید قرار داده شده کنید.',
	Retcode_10040:
		'تعداد موقعیت‌هایی که می‌توان در یک حساب به طور همزمان باز کرد، می‌تواند توسط تنظیمات گروه محدود شود. هنگامی که یک محدودیت رسیده است، سرور Retcode_REQUEST_LIMIT_POSITIONS را هنگام تلاش برای قرار دادن یک سفارش برمی‌گرداند. محدودیت به تفاوت نوع محاسبه موقعیت بستگی دارد: Netting - تعداد موقعیت‌های باز مورد نظر است. هنگامی که یک محدودیت رسیده است، پلتفرم اجازه تنها قرار دادن معاملاتی را می‌دهد که ممکن است تعداد موقعیت‌های باز را افزایش دهند. در واقع، پلتفرم فقط اجازه می‌دهد قرار دادن سفارشات برای نمادهایی را که در حال حاضر موقعیت‌های باز دارند. سفارشات معلق موجود در نظر گرفته نمی‌شوند زیرا با وجود اینکه ممکن است منجر به تغییرات در موقعیت‌های موجود شوند، اما نمی‌توانند تعداد موقعیت‌ها را افزایش دهند. Hedging - سفارشات معلق همراه با موقعیت‌های باز در نظر گرفته می‌شوند، زیرا فعال کردن یک سفارش معلق همیشه منجر به باز کردن یک موقعیت جدید می‌شود. هنگامی که یک محدودیت رسیده است، پلتفرم همچنین قرار دادن معاملات جدید باز برای باز کردن موقعیت‌های جدید را غیرفعال می‌کند و قرار دادن سفارشات معلق.',
	Retcode_10041: 'درخواست رد شد، سفارش لغو شد. این کد هنگام استفاده از عملیات IMTConRoute::ACTION_CANCEL_ORDER در یک قانون مسیردهی برگردانده می‌شود.',
	Retcode_10042: 'درخواست رد شد زیرا برای نماد قانون "فقط موقعیت‌های بلند مجاز" (IMTConSymbol::TRADE_LONGONLY) تنظیم شده است.',
	Retcode_10043: 'درخواست رد شد زیرا برای نماد قانون "فقط موقعیت‌های کوتاه مجاز" (IMTConSymbol::TRADE_SHORTONLY) تنظیم شده است.',
	Retcode_10044: 'درخواست رد شد زیرا برای نماد قانون "فقط بستن موقعیت‌ها مجاز" (IMTConSymbol::TRADE_CLOSEONLY) تنظیم شده است.',
	Retcode_10045: 'بستن موقعیت با قاعده FIFO هماهنگ نیست. این برای گروه‌هایی استفاده می‌شود که از IMTConGroup::TRADEFLAGS_FIFO_CLOSE فعال استفاده می‌شود. با این گزینه، تمام موقعیت‌ها باید به ترتیب بسته شوند: موقعیت قدیمی‌تر اولین بسته می‌شود، سپس موقعیت بعدی، و غیره.',
	Retcode_10046: 'امکان باز کردن یک موقعیت یا قرار دادن سفارش معلق به دلیل ممنوعیت موقعیت‌های حفاظت شده وجود ندارد. این خطا برگردانده می‌شود زمانی که IMTConGroup::TRADEFLAGS_HEDGE_PROHIBIT برای گروه فعال شده و کاربر تلاش می‌کند تا زمانی که قبلاً یک سفارش یا موقعیت مخالف برای همان نماد وجود داشته باشد، معامله انجام دهد.',
};
