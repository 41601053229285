const fields = {
  'open-positions': [
    {
      id: 1,
      label: 'Zarar Durdur',
      key: 'PriceSL',
    },
    {
      id: 2,
      label: 'Kar Al',
      key: 'PriceTP',
    },
  ],
  'open-orders': [
    {
      id: 1,
      label: 'Price',
      key: 'PriceOrder',
    },
    {
      id: 2,
      label: 'Zarar Durdur',
      key: 'PriceSL',
    },
    {
      id: 3,
      label: 'Kar Al',
      key: 'PriceTP',
    },
  ],
};

export {fields};
