export const flagsCurrency: any = {
  AED: require('./flags/flags-high/AED.png'),
  AFN: require('./flags/flags-high/AFN.png'),
  ALL: require('./flags/flags-high/ALL.png'),
  AMD: require('./flags/flags-high/AMD.png'),
  ANG: require('./flags/flags-high/ANG.png'),
  AOA: require('./flags/flags-high/AOA.png'),
  ARS: require('./flags/flags-high/ARS.png'),
  AUD: require('./flags/flags-high/AUD.png'),
  AWG: require('./flags/flags-high/AWG.png'),
  AZN: require('./flags/flags-high/AZN.png'),
  BAM: require('./flags/flags-high/BAM.png'),
  BBD: require('./flags/flags-high/BBD.png'),
  BDT: require('./flags/flags-high/BDT.png'),
  BGN: require('./flags/flags-high/BGN.png'),
  BHD: require('./flags/flags-high/BHD.png'),
  BIF: require('./flags/flags-high/BIF.png'),
  BMD: require('./flags/flags-high/BMD.png'),
  BND: require('./flags/flags-high/BND.png'),
  BOB: require('./flags/flags-high/BOB.png'),
  BRL: require('./flags/flags-high/BRL.png'),
  BTN: require('./flags/flags-high/BTN.png'),
  BWP: require('./flags/flags-high/BWP.png'),
  BYR: require('./flags/flags-high/BYR.png'),
  BZD: require('./flags/flags-high/BZD.png'),
  CAD: require('./flags/flags-high/CAD.png'),
  CHF: require('./flags/flags-high/CHF.png'),
  CLP: require('./flags/flags-high/CLP.png'),
  CNY: require('./flags/flags-high/CNY.png'),
  COP: require('./flags/flags-high/COP.png'),
  CUC: require('./flags/flags-high/CUC.png'),
  CZK: require('./flags/flags-high/CZK.png'),
  DJF: require('./flags/flags-high/DJF.png'),
  DKK: require('./flags/flags-high/DKK.png'),
  DZD: require('./flags/flags-high/DZD.png'),
  EGP: require('./flags/flags-high/EGP.png'),
  ERN: require('./flags/flags-high/ERN.png'),
  ETB: require('./flags/flags-high/ETB.png'),
  EUR: require('./flags/flags-high/EUR.png'),
  FJD: require('./flags/flags-high/FJD.png'),
  GBP: require('./flags/flags-high/GBP.png'),
  GEL: require('./flags/flags-high/GEL.png'),
  GHS: require('./flags/flags-high/GHS.png'),
  GIP: require('./flags/flags-high/GIP.png'),
  GNF: require('./flags/flags-high/GNF.png'),
  GTQ: require('./flags/flags-high/GTQ.png'),
  GYD: require('./flags/flags-high/GYD.png'),
  HNL: require('./flags/flags-high/HNL.png'),
  HRK: require('./flags/flags-high/HRK.png'),
  HTG: require('./flags/flags-high/HTG.png'),
  HUF: require('./flags/flags-high/HUF.png'),
  IDR: require('./flags/flags-high/IDR.png'),
  ILS: require('./flags/flags-high/ILS.png'),
  INR: require('./flags/flags-high/INR.png'),
  IQD: require('./flags/flags-high/IQD.png'),
  IRR: require('./flags/flags-high/IRR.png'),
  ISK: require('./flags/flags-high/ISK.png'),
  JMD: require('./flags/flags-high/JMD.png'),
  JOD: require('./flags/flags-high/JOD.png'),
  JPY: require('./flags/flags-high/JPY.png'),
  KES: require('./flags/flags-high/KES.png'),
  KGS: require('./flags/flags-high/KGS.png'),
  KHR: require('./flags/flags-high/KHR.png'),
  KMF: require('./flags/flags-high/KMF.png'),
  KWD: require('./flags/flags-high/KWD.png'),
  KYD: require('./flags/flags-high/KYD.png'),
  KZT: require('./flags/flags-high/KZT.png'),
  LAK: require('./flags/flags-high/LAK.png'),
  LBP: require('./flags/flags-high/LBP.png'),
  LRD: require('./flags/flags-high/LRD.png'),
  LSL: require('./flags/flags-high/LSL.png'),
  LYD: require('./flags/flags-high/LYD.png'),
  MAD: require('./flags/flags-high/MAD.png'),
  MDL: require('./flags/flags-high/MDL.png'),
  MGA: require('./flags/flags-high/MGA.png'),
  MMK: require('./flags/flags-high/MMK.png'),
  MNT: require('./flags/flags-high/MNT.png'),
  MOP: require('./flags/flags-high/MOP.png'),
  MRO: require('./flags/flags-high/MRO.png'),
  MUR: require('./flags/flags-high/MUR.png'),
  MVR: require('./flags/flags-high/MVR.png'),
  MWK: require('./flags/flags-high/MWK.png'),
  MXN: require('./flags/flags-high/MXN.png'),
  MYR: require('./flags/flags-high/MYR.png'),
  MZN: require('./flags/flags-high/MZN.png'),
  NAD: require('./flags/flags-high/NAD.png'),
  NGN: require('./flags/flags-high/NGN.png'),
  NIO: require('./flags/flags-high/NIO.png'),
  NOK: require('./flags/flags-high/NOK.png'),
  NPR: require('./flags/flags-high/NPR.png'),
  NZD: require('./flags/flags-high/NZD.png'),
  OMR: require('./flags/flags-high/OMR.png'),
  PAB: require('./flags/flags-high/PAB.png'),
  PEN: require('./flags/flags-high/PEN.png'),
  PHP: require('./flags/flags-high/PHP.png'),
  PKR: require('./flags/flags-high/PKR.png'),
  PLN: require('./flags/flags-high/PLN.png'),
  PYG: require('./flags/flags-high/PYG.png'),
  QAR: require('./flags/flags-high/QAR.png'),
  RON: require('./flags/flags-high/RON.png'),
  RSD: require('./flags/flags-high/RSD.png'),
  RUB: require('./flags/flags-high/RUB.png'),
  RWF: require('./flags/flags-high/RWF.png'),
  SCR: require('./flags/flags-high/SCR.png'),
  SDG: require('./flags/flags-high/SDG.png'),
  SEK: require('./flags/flags-high/SEK.png'),
  SHP: require('./flags/flags-high/SHP.png'),
  SOS: require('./flags/flags-high/SOS.png'),
  SRD: require('./flags/flags-high/SRD.png'),
  SSP: require('./flags/flags-high/SSP.png'),
  SYP: require('./flags/flags-high/SYP.png'),
  SZL: require('./flags/flags-high/SZL.png'),
  THB: require('./flags/flags-high/THB.png'),
  TJS: require('./flags/flags-high/TJS.png'),
  TMT: require('./flags/flags-high/TMT.png'),
  TND: require('./flags/flags-high/TND.png'),
  TOP: require('./flags/flags-high/TOP.png'),
  TRY: require('./flags/flags-high/TRY.png'),
  TWD: require('./flags/flags-high/TWD.png'),
  TZS: require('./flags/flags-high/TZS.png'),
  UAH: require('./flags/flags-high/UAH.png'),
  UGX: require('./flags/flags-high/UGX.png'),
  USD: require('./flags/flags-high/USD.png'),
  UYU: require('./flags/flags-high/UYU.png'),
  UZS: require('./flags/flags-high/UZS.png'),
  VEF: require('./flags/flags-high/VEF.png'),
  VND: require('./flags/flags-high/VND.png'),
  VUV: require('./flags/flags-high/VUV.png'),
  WST: require('./flags/flags-high/WST.png'),
  XAF: require('./flags/flags-high/XAF.png'),
  XCD: require('./flags/flags-high/XCD.png'),
  XOF: require('./flags/flags-high/XOF.png'),
  YER: require('./flags/flags-high/YER.png'),
  ZAR: require('./flags/flags-high/ZAR.png'),
  ZMW: require('./flags/flags-high/ZMW.png'),
};

export const flagsCrypto: any = {
  ADA: require('./flags/Crypto/ADA.png'),
  AVAX: require('./flags/Crypto/AVAX.png'),
  BCH: require('./flags/Crypto/BCH.png'),
  BNB: require('./flags/Crypto/BNB.png'),
  BTC: require('./flags/Crypto/BTC.png'),
  BTT: require('./flags/Crypto/BTT.png'),
  CHZ: require('./flags/Crypto/CHZ.png'),
  DOGE: require('./flags/Crypto/DOGE.png'),
  DOT: require('./flags/Crypto/DOT.png'),
  EOS: require('./flags/Crypto/EOS.png'),
  ETH: require('./flags/Crypto/ETH.png'),
  LINK: require('./flags/Crypto/LINK.png'),
  LTC: require('./flags/Crypto/LTC.png'),
  MAN: require('./flags/Crypto/MANA.png'),
  NEO: require('./flags/Crypto/NEO.png'),
  SAN: require('./flags/Crypto/SAND.png'),
  SUSHI: require('./flags/Crypto/SUSHI.png'),
  TRX: require('./flags/Crypto/TRX.png'),
  UNI: require('./flags/Crypto/UNI.png'),
  XLM: require('./flags/Crypto/XLM.png'),
  XMR: require('./flags/Crypto/XMR.png'),
  XRP: require('./flags/Crypto/XRP.png'),
  XTZ: require('./flags/Crypto/XTZ.png'),
  SOL: require('./flags/Crypto/SOL.png'),
  DSH: require('./flags/Crypto/DSH.png'),
}

export const flagsMetal: any = {
  COP: require('./flags/Metal/COP.png'),
  GAU: require('./flags/Metal/GAU.png'),
  XAG: require('./flags/Metal/XAG.png'),
  XAU: require('./flags/Metal/XAU.png'),
  XPD: require('./flags/Metal/XPD.png'),
  XPT: require('./flags/Metal/XPT.png'),
}

export const flagsStock: any = {
}

export const flagsEnergy: any = {
  NATGAS: require('./flags/Energy/NATGAS.png'),
  OIL: require('./flags/Energy/OIL.png'),
  TR_NATGAS: require('./flags/Energy/TR_NATGAS.png'),
}
export const flagsIndice: any = {
	'DE.30': require('./flags/Indice/DE.30.png'),
	'EU.50': require('./flags/Indice/EU.50.png'),
	'FRA.40': require('./flags/Indice/FRA.40.png'),
	IND50: require('./flags/Indice/IND50.png'),
	'SPA.35': require('./flags/Indice/SPA35.png'),
	SUI20: require('./flags/Indice/SUI20.png'),
	'UK.100': require('./flags/Indice/UK.100.png'),
	'US.30': require('./flags/Indice/US.30.png'),
	'US.100': require('./flags/Indice/US.100.png'),
	'US.500': require('./flags/Indice/US.500.png'),
};
