import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useLanguage } from '../../../context/Language/LanguageProvider';
import SymbolIcon from './SymbolIcon';
import PriceFormat from '../../PriceFormat';
import { IColors, IGroupSymbol, IUser, PADDING, RADIUS } from '../../../Global';
import { useTheme } from '../../../context/Theme/ThemeProvider';

interface Props {
  backgroundColor: string;
  textColor: string;
  isFavorited: boolean;
  onFavoritePress: () => void;
  onTradePress: () => void;
  lastTick: {
    Bid: number;
    Ask: number;
    Datetime: number;
  };
  lastUpdate: string;
  groupSymbol: IGroupSymbol;
  user: IUser;
}

const SymbolListItemUI: React.FC<Props> = ({
  backgroundColor,
  textColor,
  isFavorited,
  onFavoritePress,
  onTradePress,
  lastTick,
  lastUpdate,
  groupSymbol,
  user,
}) => {
  const { colors } = useTheme();
  const { words } = useLanguage();
  const styles = Styles(colors);
  
  return (
    <View style={[styles.wrapper, { backgroundColor: backgroundColor }]}>
      <View style={{ display: user?.IsInvestor ? "none" : "flex" }}>
        {!user?.IsInvestor && (
          <TouchableOpacity onPress={onFavoritePress}>
            {!isFavorited ? (
              <AntDesign name="staro" size={24} color={colors.FAVORITE} />
            ) : (
              <AntDesign name="star" size={24} color={colors.FAVORITE} />
            )}
          </TouchableOpacity>
        )}
      </View>
      <View style={{ flex: 1 }}>
        <TouchableOpacity onPress={onTradePress} style={styles.rightSide}>
          <View style={styles.infoWrapper}>
            <SymbolIcon
              Symbol={groupSymbol.Symbol.Symbol}
              CurrencyBase={groupSymbol.Symbol.CurrencyBase}
              CurrencyProfit={groupSymbol.Symbol.CurrencyProfit}
              test={groupSymbol.Symbol}
              Category={groupSymbol.Symbol.Category}
            />
            <View>
              <Text style={[styles.text, { color: textColor }]}>
                {groupSymbol.Symbol.Symbol}
              </Text>
              <Text style={[styles.spreadText, { color: textColor }]}>
                {words["Spread"]}{' '}
                {Math.round(
                  Math.abs(lastTick?.Bid - lastTick?.Ask) *
                    Math.pow(10, groupSymbol.Symbol.Digits)
                )}
              </Text>
              <Text style={[styles.spreadText, { color: textColor }]}>
                {lastUpdate}
              </Text>
            </View>
          </View>
          <View style={styles.pricesWrapper}>
            <View style={styles.salesWrapper}>
              <Text
                style={[
                  styles.text,
                  styles.priceTitleText,
                  { color: textColor },
                ]}
              >
                {words["Sell"]}
              </Text>
              <Text
                style={[styles.text, styles.priceText, { color: colors.SELL }]}
              >
                <PriceFormat
                  value={lastTick?.Bid}
                  digits={groupSymbol?.Symbol?.Digits}
                  showCurrency={false}
                  textColor={colors.SELL}
                />
              </Text>
            </View>
            <View style={styles.buyWrapper}>
              <Text
                style={[
                  styles.text,
                  styles.priceTitleText,
                  { color: textColor },
                ]}
              >
                {words["Buy"]}
              </Text>
              <Text
                style={[styles.text, styles.priceText, { color: colors.BUY }]}
              >
                <PriceFormat
                  value={lastTick?.Ask}
                  digits={groupSymbol?.Symbol?.Digits}
                  showCurrency={false}
                  textColor={colors.BUY}
                />
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SymbolListItemUI;

const Styles = (COLOR: IColors) =>
  StyleSheet.create({
    wrapper: {
      position: "relative",
      flex: 1,
      paddingHorizontal: PADDING.CARDX,
      paddingVertical: PADDING.CARDY,
      borderRadius: RADIUS.CARD,
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginVertical: 5,
      gap: 10,
      shadowColor: COLOR.SHADOW3,
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.1,
      shadowRadius: 2,
    },
    rightSide: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
    },
    closedMarketWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLOR.CLOSED_MARKET,
      elevation: 2,
      zIndex: 2,
    },
    infoWrapper: {
      flexDirection: "row",
      alignItems: "center",
      gap: 12,
    },
    pricesWrapper: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      gap: 20,
    },
    salesWrapper: {
      flex: 1,
      gap: 3,
    },
    buyWrapper: {
      flex: 1,
      gap: 3,
    },
    text: {
      fontWeight: "500",
    },
    spreadText: {
      fontWeight: "500",
      fontSize: 9,
    },
    priceTitleText: {
      fontWeight: "500",
      textAlign: "right",
    },
    priceText: {
      fontWeight: "600",
      textAlign: "right",
    },
  });
