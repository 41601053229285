export const formatNumber = (value: number, digits: number = 2, showCurrency = false) => {
  if (isNaN(Number(value)))
  {
    return showCurrency ? "0 $": "0";
  }
	const priceFormat = new Intl.NumberFormat('de-DE', {
		style: showCurrency ? 'currency' : 'decimal',
		currency: 'USD',
		minimumFractionDigits: digits ? digits : 2,
		maximumFractionDigits: digits ? digits : 2,
		useGrouping: true,
	});
	return priceFormat.format(value).replace(/\./g, ' ').replace(',', '.');
};
