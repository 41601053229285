import { useContext, useState } from "react";
import { Linking, Text, TextInput, View } from "react-native";
import dayjs from "dayjs";
import { FONTSIZE } from "../Global";
import MainButton from "../components/MainButton";
import { AuthContext } from "../context/AuthContext";
import { useTheme } from "../context/Theme/ThemeProvider";
import apiCall from "../utils/apiCall";
import { showFlowMessage } from "../components/FlowMessage";
import { Audio } from "expo-av";
import { useLanguage } from "../context/Language/LanguageProvider";

async function SoundSuccess() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../assets/sounds/soundssuccess.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}
async function SoundError() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../assets/sounds/soundserror.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

const CustomerComplaint = ({ navigation }: any) => {
  const { user } = useContext(AuthContext);
  const [complaintMessage, setComplaintMessage] = useState("");

  const { words } = useLanguage();
  const { colors } = useTheme();

  async function WhatsappMessageSend() {
    if (!complaintMessage) {
      SoundError();
      showFlowMessage({
        message: words["FlowMessage_InvalidComplaint"],
        type: "error",
        duration: 1000,
      });
      return;
    } else if (complaintMessage.length < 10) {
      SoundError();
      showFlowMessage({
        message: words["FlowMessage_InvalidComplaint"],
        type: "error",
        duration: 1000,
      });
      return;
    } else if (complaintMessage.split(" ").length < 5) {
      SoundError();
      showFlowMessage({
        message: words["FlowMessage_InvalidComplaint"],
        type: "error",
        duration: 1000,
      });
      return;
    }

    const type = 3;
    const subject = `Müşteri Şikayet Talebi - Hesap ID: ${user?.ID} - ${user?.Name}`;
    const message = `
Sayın Yatırım Uzmanı,

İlgili Hesap Sahibi: ${user?.Name}
Hesap ID: ${user?.ID}
İşlem Tarihi: ${dayjs().startOf("second").format("DD/MM/YYYY HH:mm")}
		
Yatırımcımız ${user?.Name}, ${dayjs()
      .startOf("second")
      .format("DD/MM/YYYY HH:mm")} tarihinde bir şikayet talebinde bulunmuştur. 

Şikayeti aşağıdaki gibidir:
	${complaintMessage}

		
Lütfen gerekli incelemeleri yaparak müşterimizin şikayetini değerlendiriniz ve en kısa sürede çözüm sağlayınız.
		
Detaylı bilgi için lütfen yatırımcı iletişime geçiniz.
		
Yatırımcı Telefon Numarası: ${user?.Phone}
		


Bu Mesaj WinexMarkets Mobile Tarafından İletilmiştir.`;

    const datas = {
      compalaint: complaintMessage,
    };
    const body = {
      type: type,
      subject: subject,
      message: message,
      data: datas,
    };
    console.log(body);
    apiCall("/send-mail", body, "POST")
      .then((response) => {
        SoundSuccess();
        showFlowMessage({
          message: words["FlowMessage_CustomerComplaintSuccess"],
          type: "success",
          duration: 5000,
        });
        navigation?.navigate("Home");
      })
      .catch((err) => {
        SoundError();
        showFlowMessage({
          message: words["FlowMessage_Error"],
          type: "error",
          duration: 5000,
        });
      });
  }

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        gap: 5,
        paddingHorizontal: 10,
        backgroundColor: colors.SCREEN_BACKGROUND,
      }}
    >
      <View
        style={{
          backgroundColor: colors.CARD_BACKGROUND2,
          borderRadius: 5,
          marginVertical: 5,
        }}
      >
        <Text
          style={{
            textAlign: "justify",
            paddingVertical: 8,
            paddingHorizontal: "5%",
            fontSize: FONTSIZE.m,
            color: colors.TEXT,
          }}
        >
          Sorununuzu aşağıdaki alana yazarak bize iletebilirsiniz. Whatsapp
          üzerinden iletilecektir.
        </Text>
      </View>

      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
          paddingHorizontal: 10,
        }}
      >
        <Text
          style={{
            verticalAlign: "bottom",
            marginTop: "5%",
            color: colors.TEXT,
          }}
        >
          Mesajınız
        </Text>
        <View
          style={{
            width: "100%",
            flexBasis: "50%",
            backgroundColor: colors.CARD_BACKGROUND2,
            borderRadius: 5,
            flexDirection: "row",
            paddingHorizontal: 10,
          }}
        >
          <TextInput
            keyboardType="default"
            style={{
              height: "100%",
              verticalAlign: "top",
              width: "100%",
              color: colors.TEXT,
            }}
            multiline={true}
            autoFocus
            onChangeText={(newText) => setComplaintMessage(newText)}
            value={complaintMessage}
          />
        </View>
        <View style={{ width: "100%" }}>
          <MainButton
            onPress={WhatsappMessageSend}
            text={"Şikayetinizi İletin"}
          />
        </View>
      </View>
    </View>
  );
};

export default CustomerComplaint;
