import {Text} from 'react-native';
import { newDayjsWithFormat } from '../../utils/dateUtil';
import { IColors } from '../../Global';

interface IPositionsFieldProps {
  id: number;
  name: string;
  field: string;
  renderCell?: (data: any, colors:IColors) => JSX.Element;
}

const PositionFields: IPositionsFieldProps[] = [
  {
    id: 0,
    name: 'TimeCreate',
    field: 'TimeCreate',
    renderCell: (data: any, colors: IColors) => {
      return <Text style={{color: colors.TEXT}}>{newDayjsWithFormat(data?.TimeCreate * 1000)}</Text>;
    },
  },
  {
    id: 1,
    name: 'Position',
    field: 'Position',
  },
  {
    id: 2,
    name: 'VolumeCurrent',
    field: 'VolumeCurrent',
    renderCell: (data: any, colors:IColors) => {
      return <Text style={{color: colors.TEXT}}>{Number(data?.Volume / 10000)}</Text>;
    },
  },
  {
    id: 4,
    name: 'PriceCurrent',
    field: 'PriceCurrent',
  },
  {
    id: 5,
    name: 'PriceSL',
    field: 'PriceSL',
  },
  {
    id: 6,
    name: 'PriceTP',
    field: 'PriceTP',
  },
  {
    id: 7,
    field: 'Storage',
    name: 'Swap',
  },
  {
    id: 8,
    field: 'Profit',
    name: 'Profit',
  },
];

interface IOrderFieldProps {
  id: number;
  name: string;
  field: string;
  renderCell?: (data: any, colors:IColors) => JSX.Element;
}

const OrderFields: IOrderFieldProps[] = [
  {
    id: 1,
    name: 'Order',
    field: 'Order',
  },
  {
    id: 2,
    field: 'VolumeCurrent',
    name: 'Lot',
    renderCell: (row: any, colors:IColors) => {
      return (
        <Text style={{color: colors.TEXT}}>
          {Number(row?.VolumeCurrent / 10000)}
        </Text>
      );
    },
  },
  {
    id: 3,
    name: 'PriceOrder',
    field: 'PriceOrder',
  },
  {
    id: 4,
    name: 'PriceSL',
    field: 'PriceSL',
  },
  {
    id: 5,
    name: 'PriceTP',
    field: 'PriceTP',
  },
  {
    id: 6,
    name: 'PriceCurrent',
    field: 'PriceCurrent',
  },
  // {
  //   id: 7,
  //   name: 'Reason',
  //   field: 'Reason',
  // },
  // {
  //   id: 8,
  //   name: 'Comment',
  //   field: 'Comment',
  // },
  // {
  //   id: 9,
  //   name: 'Emir Kapanma Tarihi',
  //   field: 'TimeExpiration',
  // },
];

export type {IPositionsFieldProps, IOrderFieldProps};
export {PositionFields, OrderFields};
