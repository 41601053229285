import React, { useContext, useEffect, useState } from "react";
import { Button, StyleSheet, Switch, Text, View } from "react-native";
import InputCard from "../../components/InputCard";
import InfoCard from "../../components/InfoCard";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/Theme/ThemeProvider";
import MainButton from "../../components/MainButton";
import apiCall from "../../utils/apiCall";
import { SelectList } from "react-native-dropdown-select-list";
import { ILanguage, Languages } from "./constants";
import { FONTSIZE, GAP, IColors, PADDING, RADIUS } from "../../Global";
import { useLanguage } from "../../context/Language/LanguageProvider";
import AsyncStorage from "@react-native-async-storage/async-storage";

const setLanguageStorage = (lang: ILanguage) => {
  try {
    AsyncStorage.setItem("language", JSON.stringify(lang));
  } catch (e) {
    // error reading value
  }
};

const Settings = () => {
  const { darkMode, colors, setScheme } = useTheme();
  const { language, setLanguage, words } = useLanguage();
  const [getNotificition, setGetNotificition] = useState<boolean>(false);

  const style = Styles(colors);

  const [selectedLanguage, setSelectedLanguage] = useState<ILanguage>(
    Languages.find((item) => item.code === language) || Languages[0]
  );

  const changeDarkMode = async () => {
    const dark = !darkMode;
    setScheme(dark);

    try {
      await AsyncStorage.setItem("darkMode", JSON.stringify(dark));
      console.log("DarkModeChanged: ", JSON.stringify(dark));
    } catch (e) {
      console.log("ChangeDarkModeError: \n", e);
    }
  };

  useEffect(() => {
    const lang =
      Languages.find((item) => item.key === selectedLanguage.key) ||
      Languages[0];
    console.log("selectedLanguage: ", lang);
    setLanguageStorage(lang);
    setLanguage(lang.code);
  }, [selectedLanguage]);

  return (
    <View style={style.container}>
      <View style={style.subContainer}>
        <Text style={[style.mainText]}>{words["Settings_DarkMode"]}</Text>
        <Text style={style.seperator}>:</Text>
        <View style={{ flex: 7 }}>
          <Switch
            style={[style.switch]}
            value={darkMode}
            onValueChange={() => {
              console.log("DarkMode: ", darkMode);
              changeDarkMode();
            }}
          />
        </View>
      </View>

      <View style={style.subContainer}>
        <Text style={[style.mainText]}>{words["Settings_Notifications"]}</Text>
        <Text style={style.seperator}>:</Text>
        <View style={{ flex: 7 }}>
        <Switch
            style={[style.switch]}
            disabled={true}
            value={getNotificition}
            onValueChange={() => {
              setGetNotificition(!getNotificition);  
            }}

          />
        </View>
      </View>

      <View style={style.subContainer}>
        <Text style={[style.mainText]}>{words["Settings_Language"]}</Text>
        <Text style={style.seperator}>:</Text>
        <View style={{ flex: 7 }}>
          <SelectList
            data={Languages}
            setSelected={(val: any) => {
              console.log("NOLUYO LAN: ", val);
              setSelectedLanguage(
                Languages.find((item) => item.key === val) || Languages[0]
              );
            }}
            defaultOption={Languages.find((item) => item.code === language)}
            save="key"
            search={false}
            placeholder={words["Settings_SearchLanguage"]}
            boxStyles={{ backgroundColor: colors.CARD_BACKGROUND1, position:'relative' }}
            inputStyles={{
              backgroundColor: colors.CARD_BACKGROUND1,
              color: colors.TEXT,
            }}
            dropdownStyles={{
              backgroundColor: colors.CARD_BACKGROUND1,
              position: "absolute",
              width: "100%",
              top: '100%',
            }}
            dropdownTextStyles={{ color: colors.TEXT }}
          />
        </View>
      </View>
      {/* <InfoCard
          type="basic"
          args={{title: 'Müşteri No', value: user?.Login}}
        />
        <InputCard
          type="password"
          args={{title: 'Güncel Şifre', value: password}}
          response={setPassword}
        /> */}
    </View>
  );
};

export default Settings;

const Styles = (colors: IColors) =>
  StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
      padding: PADDING.SCREEN,
      gap: GAP.XXL,
      backgroundColor: colors.SCREEN_BACKGROUND,
    },
    subContainer: {
      justifyContent: "flex-start",
      flexDirection: "row",
    },
    cardContainer: {
      backgroundColor: colors.CARD_BACKGROUND1,
      paddingVertical: PADDING.XS,
      paddingHorizontal: PADDING.M,
      borderRadius: RADIUS.CARD,
    },
    cardSubContainer: {
      backgroundColor: colors.CARD_BACKGROUND2,
      paddingHorizontal: PADDING.M,
      borderBottomLeftRadius: RADIUS.CARD,
      borderBottomRightRadius: RADIUS.CARD,
    },
    cardText: {
      color: colors.TEXT,
      fontWeight: "bold",
      fontSize: FONTSIZE.l,
    },
    cardSubText: {
      color: colors.TEXT,
      fontSize: FONTSIZE.l,
    },
    mainText: {
      flex: 5,
      color: colors.TEXT,
      fontSize: FONTSIZE.xl,
      alignSelf: "center",
    },
    seperator: {
      flex: 1,
      color: colors.TEXT,
      fontSize: FONTSIZE.xl,
      alignSelf: "center",
    },
    switch: {
      backgroundColor: colors.CARD_BACKGROUND1,
      borderRadius: RADIUS.CARD,
    },
  });
