import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  ScrollView,
  Dimensions,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

// icons
import {
  AntDesign,
  Fontisto,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";

// components
import MenuItem from "../MenuItem/MenuItem";

// globals
import { useTheme } from "../../context/Theme/ThemeProvider";
import MainButton from "../MainButton";
import UserCard from "../UserCard";
import { useLanguage } from "../../context/Language/LanguageProvider";



const CustomSideMenu = ({ navigation }: any) => {
  const { colors } = useTheme();
  const { words } = useLanguage();
  const { logout, user } = useContext(AuthContext);
  const styles = Styles(colors);

  const menuItems = [
    {
      id: 1,
      name: "Deposit",
      value: "DepositSelection",
      icon: <MaterialCommunityIcons name="cash-fast" size={24} color={colors.TEXT} />,
    },
    {
      id: 2,
      name: "Withdraw",
      value: "Withdraw",
      icon: <MaterialCommunityIcons name="cash-fast" size={24} color={colors.TEXT} />,
    },
    {
      id: 3,
      name: "Notifications",
      value: "notifications",
      icon: <Ionicons name="notifications" size={24} color={colors.TEXT} />,
    },
    {
      id: 4,
      name: "BankStatement",
      value: "Profile",
      icon: <MaterialCommunityIcons name="cash-register" size={24} color={colors.TEXT} />,
    },
    {
      id: 5,
      name: "ChangePassword",
      value: "ChangePassword",
      icon: <MaterialCommunityIcons name="onepassword" size={24} color={colors.TEXT} />,
    },
    {
      id: 6,
      name: "Traders",
      value: "Traders",
      icon: <Ionicons name="people" size={24} color={colors.TEXT} />,
    },
    {
      id: 7,
      name: "Robots",
      value: "Robots",
      icon: <MaterialCommunityIcons name="robot-excited-outline" size={24} color={colors.TEXT} />,
    },
    {
      id: 8,
      name: "LiveSupport",
      value: "LiveSupport",
      icon: <MaterialIcons name="live-help" size={24} color={colors.TEXT} />,
    },
    {
      id: 9,
      name: "CustomerComplaint",
      value: "CustomerComplaint",
      icon: <AntDesign name="customerservice" size={24} color={colors.TEXT} />,
    },
    {
      id: 10,
      name: "Settings",
      value: "Settings",
      icon: <MaterialIcons name="settings" size={24} color={colors.TEXT} />,
    },
  ];

  return (
    <View style={[styles.container]}>
      <View
        style={{
          height: 100,
          backgroundColor: colors.HEADER_BACKGROUND,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: 5,
          marginBottom: 10,
          justifyContent: "space-between",
          paddingHorizontal: "2%",
        }}
      >
        <UserCard
          username={`${user?.Name}`}
          number={''}
          loginID={user?.ID || " - "}
          imageSource={require("../../assets/trader1.png")}
        />
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flex: 1 }}
        style={{ width: "100%", height: "100%" }}
      >
        <View style={{ gap: 10 }}>
          {menuItems.map((item) => (
            <MenuItem
              key={item.id}
              name={item.name}
              Icon={item.icon}
              route={item.value}
              navigation={navigation}
              extraProp={{ user }}
              isInvestor={!!user?.IsInvestor}
            />
          ))}
        </View>

        <View style={{ marginBottom: 10, marginTop: "auto" }}>
          <Text
            style={{
              alignSelf: "center",
              fontWeight: "400",
              color: colors.TEXT,
              marginTop: 30,
            }}
          >
            {" "}
            {/* {DeviceInfo.getVersion()}{' '} */}
            v1.0.0
          </Text>

          <MainButton
            onPress={() => logout({})}
            text={words["Exit"]}
            buttonStyle={styles.logoutBtn}
            textStyle={{ color: colors.MAIN_BUTTON_TEXT }}
          />
        </View>
      </ScrollView>
    </View>
  );
};

export default CustomSideMenu;

const Styles = (COLOR: any) =>
  StyleSheet.create({
    container: {
      backgroundColor: COLOR.SCREEN_BACKGROUND,
      height: Dimensions.get("window").height,
      padding: 10,
    },
    logoutBtn: {
      alignItems: "center",
      width: "100%",
      padding: 10,
      marginVertical: 20,
      backgroundColor: COLOR.MAIN_BUTTON_BACKGORUND,
      color: COLOR.MAIN_BUTTON_TEXT,
    },
  });
