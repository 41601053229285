interface ILanguage {
	[key: string]: string;
}

export const Turkmen: ILanguage = {
	Exit: 'Çykmak',
	Login: 'Giriş',
	LoginID: 'ID',
	UserName: 'Ulanyjy ady',
	Password: 'Parol',
	Buy: 'Satyn almak',
	Sell: 'Satmak',
	Profit: 'Hasyl',
	Loss: 'Zyyan',
	Home: 'Baş sahypa',
	Markets: 'Bazarlar',
	Transaction: 'Meniň işlerim',
	Profile: 'Profili',
	Spread: 'Spred',
	Search: 'Gözlemek',
	Name: 'Ady',
	FirstName: 'Ilki ady',
	LastName: 'Soňky ady',
	Email: 'E-poçta',
	Phone: 'Telefon',
	Amount: 'Miktar',
	Button_SubmitDepositRequest: 'Töleg soraw üçin ýollamak',
	Button_SubmitWithdrawRequest: 'Pul çykaryş sorawyny ýolla',
	Button_SubmitYourComplaint: 'Sizeň şikayetinizni ýolla',
	Position: 'Meýilnamasy',
	Order: 'Buyruklar',
	Login_RegisteredAccounts: 'Görnüşke ulanýan hasaplar',
	Login_Servers: 'Serverler',
	Login_NoRegisteredAccounts: 'Ulanan hasaplar tapylmady.',
	Login_ToolTip_1: 'Giriş ekranyny täzeden açar.',
	Login_ToolTip_2: "Ulanyjy adyny we parolyny ýazdyp, 'Giriş' düwmesine basmak ulanýan maglumatlary saýlap, ulanar.",
	Login_ToolTip_3: "'Giriş' düwmesini basyp goýdurarsaňyz, ulanýan maglumatlary saýlamazdan, ulanýarsyňyz.",
	Login_ToolTip_4: 'Hasaplaryňyz bar bolsa, size gerekli hasaplar bilen giriş edip biler.',
	Login_ToolTip_5: 'Hasaplardan birini basyp goýup saklanan hasaplardan çykarsaňyz.',
	Login_ToolTip_6: 'Bu ýerden serveri saýlamaly.',
	Login_ToolTip_7: 'Giriş ederken saýlanan servere has-da eýe bolup bolmayarynyzy kontrol etmek gerekir.',
	SideMenu_Deposit: 'Töleg',
	SideMenu_Withdraw: 'Pul çykaryş',
	SideMenu_Notifications: 'Bildirişler',
	SideMenu_BankStatement: 'Bank aýdymy',
	SideMenu_Transaction: 'Iş tarigi',
	SideMenu_ChangePassword: 'Paroly üýtgetmek',
	SideMenu_ChangePasswordForInvestor: 'Işgär parolyny üýtgetmek',
	SideMenu_Traders: 'Işgärler',
	SideMenu_Robots: 'Robotlar',
	SideMenu_LiveSupport: 'Halkara gadam',
	SideMenu_CustomerComplaint: 'Ulanyjyň şikayeti',
	SideMenu_Settings: 'Bellikler',
	SideMenu_Share: 'Arkadaşlaryňyz bilen paýlaşyň',
	SideMenu_References: 'Referanslar',
	Favorites: 'Ýitirililer',
	Forex: 'Foreks',
	Crypto: 'Kriptovalýuta',
	Indices: 'Indikatorlar',
	Metal: 'Metallar',
	Energy: 'Energiýa',
	Stock: 'Hisse senedleri',
	History: 'Geçmiş',
	Positions: 'Açyk meýilnamalar',
	Orders: 'Açyk buyruklar',
	Home_RecommendedActions: 'Günüň maslahatlary',
	Home_Announcement: 'Gözlemek isleýän birleşigi işaretleýärsiňiz.',
	Home_ProminentInvestors: 'Goşumçe işgärler',
	Router_Login: 'Giriş',
	Router_TransactionDetails: 'Meniň işlerim',
	Router_Trade: 'Işlemek',
	Router_TradingView: 'Goşumçe grafik',
	Router_DepositSelection: 'Töleg',
	Router_DepositWithCash: 'Bank tölegi bilen tölemek',
	Router_Withdraw: 'Bank tölegi bilen pul çykarmak',
	Router_CurrencySelection: 'Bank tölegi bilen tölemek',
	Router_BankTransaction: 'Bank tölegi bilen tölemek',
	Router_DepositWithCrypto: 'Kripto tölegi bilen tölemek',
	Router_ChangePassword: 'Paroly üýtgetmek',
	Router_ChangePasswordForInvestor: 'Işgär parolyny üýtgetmek',
	Router_CustomerComplaint: 'Ulanyjyň şikayeti',
	Router_References: 'Referanslar',
	Router_AddReferences: 'Referans goşmak',
	Router_Settings: 'Bellikler',
	Router_Home: 'Baş sahypa',
	Router_Profile: 'Profili',
	Router_Symbols: 'Bazarlar',
	Router_Transaction: 'Meniň işlerim',
	Balance: 'Balans',
	Equity: 'Ekwiti',
	Credit: 'Kredit',
	Margin: 'Ulanylan marža',
	Margin_Free: 'Boş marža',
	Margin_Level: 'Marža derejesi',
	Share: 'Arkadaşlaryňyz bilen paýlaşyň',
	Floating: 'Açyk hasabçydan hasyl/zyyan',
	UserCard_PhoneError: 'Telefon belgilenmedi!',
	DepositSelection_WithCash: 'Bank tölegi bilen tölemek',
	DepositSelection_WithCrypto: 'Kripto tölegi bilen tölemek',
	DepositSelection_Warning: 'Gerekli töleg usulyny saýlaň.',
	DepositWithCash_Warning: 'Gerekli bank tölegi maglumatlaryny almak üçin, fund etmek isleýän bankany saýlaň we sonundan valýutanı saýlaň.',
	CurrencySelection_Warning: 'Gerekli bank tölegi maglumatlaryny almak üçin, fund etmek isleýän bankany saýlaň we sonundan valýutanı saýlaň.',
	DepositTransaction_BankName: 'Bank ady',
	DepositTransaction_Name: 'Alan ady',
	DepositTransaction_Currency: 'Valýuta',
	DepositTransaction_IBAN: 'IBAN',
	DepositTransaction_Description: 'Düşündiriş',
	DepositWithCrypto_Warning_1: 'Dogry kripto maglumatlary almak üçin, fund etmek isleýän kriptony saýlaň we soňra şebeke birliklerini saýlaň.',
	DepositWithCrypto_Warning_2: 'Şebeke birliksini saýladan soň, maglumat kartyndan ony nusga alyp bilersiňiz.',
	DepositWithCrypto_SelectCrypto: 'Kripto saýla',
	DepositWithCrypto_SelectNetwork: 'Şebeke birliği saýla',
	DepositWithCrypto_SelectNetwork_Warning: 'Iň öň kriptony saýlaň',
	DepositWithCrypto_Message: 'Habar',
	CustomerComplaint_Message: 'Siziň habar',
	CustomerComplaint_Warning: 'Siziň meseleňizi ýazyş bilen aşakdaky alana ýazyp, WhatsApp aragatnasyna iberip berilýär.',
	Settings_Language: 'Dil',
	Settings_SearchLanguage: 'Dil alyş',
	Settings_DarkMode: 'Gara Rejim',
	Settings_Notifications: 'Bildirişler',
	Trade_AdditionalTransactions: 'Goşmaça işler',
	Trade_MarketOrder: 'Bozaryň işi',
	Trade_MarketClosed: 'Bozaryň ýapyk',
	Trade_TakeProfit: 'Hasyl',
	Trade_StopLoss: 'Zyyan',
	Trade_Create: 'Döretmek',
	Trade_BuyOrder: 'Satyn almak bozary',
	Trade_SellOrder: 'Satmak bozary',
	Trade_BuyLimit: 'Göwrut satyn almak bozary',
	Trade_SelllLimit: 'Göwrut satmak bozary',
	Trade_BuyStop: 'Durmaz satyn almak bozary',
	Trade_SellStop: 'Durmaz satmak bozary',
	TradeStatus_0: 'Bozaryň ýapyk',
	TradeStatus_1: 'Hasaplananlaryň yalnyslygy',
	TradeStatus_2: 'Satylýanlaryň yalnyslygy',
	TradeStatus_3: 'Gaýtadan işleýänlaryň yalnyslygy',
	TradeStatus_4: 'Işlemek açyk',
	TradeStatus_5: 'Teklip gözlemek',
	Trade_Type_0: 'Satyn almak',
	Trade_Type_1: 'Satmak',
	Trade_Type_2: 'Göwrut satyn almak',
	Trade_Type_3: 'Göwrut satmak',
	Trade_Type_4: 'Durmaz satyn almak',
	Trade_Type_5: 'Durmaz satmak',
	Trade_Type_6: 'Durmaz satyn almak we durmaz satmak',
	Trade_Type_7: 'Durmaz satmak we durmaz satyn almak',
	Trade_Type_8: 'Buyruklaryň ýapylanmasy bilen goşmaça',
	Positions_NoData: 'Görkezmek üçin açyk meýilnama ýok.',
	Positions_TimeCreate: 'Güni',
	Positions_Position: 'Meýilnama',
	Positions_VolumeCurrent: 'Lot',
	Positions_PriceCurrent: 'Häzirki bahasy',
	Positions_PriceSL: 'Zyyan çakyna',
	Positions_PriceTP: 'Hasyl çakyna',
	Positions_Swap: 'Swap',
	Positions_Profit: 'Hasyl/Zyyan',
	Position_PartialClose: 'Käri meýilnama ýapmak',
	Position_ClosePosition: 'Meýilnamany ýapmak',
	Position_ModifyPosition: 'Üýtgetmek',
	ModifyPosition_Title: 'Meýilnamany üýtgetmek',
	ModifyPosition_Position: 'Meýilnama No',
	ModifyPosition_PriceCurrent: 'Häzirki bahasy',
	ModifyPosition_PriceSL: 'Zyyan çakyna',
	ModifyPosition_PriceTP: 'Hasyl çakyna',
	ModifyPosition_Accept: 'Kabul etmek',
	ModifyPosition_Cancel: 'Ipketmek',
	ModifyOrder_Title: 'Buyruky üýtgetmek',
	ModifyOrder_Order: 'Buyruk No',
	ModifyOrder_PriceCurrent: 'Häzirki bahasy',
	ModifyOrder_PriceOrder: 'Buyruk baha',
	ModifyOrder_PriceSL: 'Zyyan çakyna',
	ModifyOrder_PriceTP: 'Hasyl çakyna',
	ModifyOrder_LastTime: 'Saýlama wagtynyň synnawa tarapy',
	ModifyOrder_LastTimeMessage: 'Buyruk üçin saýlama wagdyny saýlaň.',
	ModifyOrder_Accept: 'Kabul etmek',
	ModifyOrder_Cancel: 'Ipketmek',
	ModifyOrder_LastTimeOk: 'ÝA',
	ModifyOrder_LastTimeCancel: 'Ipketmek',
	Orders_NoData: 'Görkezmek üçin açyk buyruk ýok.',
	Orders_Order: 'Tiket',
	Orders_Lot: 'Lot',
	Orders_PriceOrder: 'Buyruk baha',
	Orders_PriceSL: 'Zyyan çakyna',
	Orders_PriceTP: 'Hasyl çakyna',
	Orders_PriceCurrent: 'Häzirki bahasy',
	Orders_Reason: 'Sebäp',
	Orders_Comment: 'Teswir',
	Orders_DeleteOrder: 'Buyruky pozmak',
	Orders_ModifyOrder: 'Üýtgetmek',
	Orders_Type_0: 'Müşderi',
	Orders_Type_1: 'Işgär',
	Orders_Type_2: 'Satyjy',
	Orders_Type_3: 'Zyyan çakyna',
	Orders_Type_4: 'Hasyl çakyna',
	Orders_Type_5: 'Durmaz satyn almak',
	Orders_Type_6: 'Tutulypdyryş',
	Orders_Type_7: 'Girizme müşderisi',
	Orders_Type_8: 'V toleransiýasy',
	SymbolProperties_SwapRates: 'Swap oranlary',
	SymbolProperties_Sessions: 'Seansiýalar',
	SymbolProperties_SessionsTrade: 'Işleýän',
	SymbolProperties_ContractSize: 'Kontrakt görnüşi',
	SymbolProperties_Spread: 'Aralyk',
	SymbolProperties_StopsLevel: 'Aralyklary ýaly',
	SymbolProperties_CurrencyMargin: 'Walýuta marji',
	SymbolProperties_VolumeMin: 'Minimum hajym',
	SymbolProperties_VolumeMax: 'Maximum hajym',
	SymbolProperties_Monday: 'Duşenbe',
	SymbolProperties_Tuesday: 'Salyşar',
	SymbolProperties_Wednesday: 'Ýakşenbe',
	SymbolProperties_Thursday: 'Dördüşenbe',
	SymbolProperties_Friday: 'Päňjşenbe',
	SymbolProperties_Saturday: 'Şenbe',
	SymbolProperties_Sunday: 'Ýekşenbe',
	History_Button: 'Süz',
	History_Positions: 'Ýapyk Meýiller',
	History_Balance: 'Bakiýa Taryhy',
	History_MyTransactions: 'Meniň Arassalamalar',
	History_DatePicker_StartTitle: 'Başlaýan Gün Saýla',
	History_DatePicker_EndTitle: 'Tamamlanan Gün Saýla',
	History_DatePicker_Ok: 'Ýa',
	History_DatePicker_Cancel: 'Ipketmek',
	History_NoData: 'Gösterjek taryh ýok.',
	HistoryPositions_Symbol: 'Nyşan',
	HistoryPositions_PositionID: 'Meýilnama',
	HistoryPositions_Volume: 'Lot',
	HistoryPositions_Price: 'Açylyş Bahasy',
	HistoryPositions_PricePosition: 'Ýapylyş Bahasy',
	HistoryPositions_PriceSL: 'Duryp Gör',
	HistoryPositions_PriceTP: 'Hasyl Gör',
	HistoryPositions_Swap: 'Swap',
	HistoryPositions_Profit: 'Hasyl/Zyyan',
	HistoryPositions_Commission: 'Komissiýa',
	HistoryPositions_Action_0: 'Satmak',
	HistoryPositions_Action_1: 'Almak',
	HistoryPositions_Action_2: 'Göwrut satmak',
	HistoryPositions_Action_3: 'Göwrut almak',
	HistoryPositions_Action_4: 'Durnamaly satmak',
	HistoryPositions_Action_5: 'Durnamaly almak',
	HistoryPositions_Action_6: 'Durnamaly satmak durmak',
	HistoryPositions_Action_7: 'Durnamaly almak durmak',
	HistoryPositions_Action_8: 'Buyruk tarapyndan ýapylyş',
	HistoryBalance_Time: 'Gün',
	HistoryBalance_Ticket: 'Düýbi',
	HistoryBalance_Profit: 'Bahasy',
	HistoryBalance_Type: 'Görnüşi',
	HistoryBalance_Comment: 'Teswir',
	HistoryBalance_Action_2: 'Bakiýa',
	HistoryBalance_Action_3: 'Kredit',
	HistoryBalance_Action_4: 'Gowşurma/Çykys',
	HistoryBalance_Action_5: 'Düzeltmek',
	HistoryBalance_Action_6: 'Bonuslar',
	HistoryBalance_Action_7: 'Komissiýa',
	ChangePassword_CurrentPassword: 'Häzirki Parol',
	ChangePassword_NewPassword: 'Täze Parol',
	ChangePassword_ConfirmNewPassword: 'Täze Paroly Döretmek',
	ChangePassword_Button: 'Sakla',
	ChangePassword_Checker_UpperCase: 'Iň gowrak harplar ulan',
	ChangePassword_Checker_LowerCase: 'Kiçi harplar ulan',
	ChangePassword_Checker_Number: 'Sanlar ulan',
	ChangePassword_Checker_SpecialChar: 'Mahsus nyşanlar ulan',
	ChangePassword_Checker_Length: 'Iň az 6 nyşan bolmaly',
	ChangePassword_Checker_OriginalPassword: 'Eski açar sözüňiz duzakdyr',
	ChangePassword_Checker_PasswordsMatch: 'Täze açar sözler birbada gabat gelmeli',
	ReferencesAdd_Button: 'Rezerw goş',
	ReferencesAdd_Error: 'Rezerw goşmakda hata\nIçeri girizmek täzeden synanyşmagyňyzy haýyş edýäris.',
	FlowMessage_Timeout: 'Arassalamadan soň jogap alnyşyklary ýok.',
	FlowMessage_Success: 'Arassalamalar üstünlikli ýerine ýetirildi.',
	FlowMessage_Error: 'Arassalamany işlemäge gaty zat çykdy. Içeri girizmegi gaýtadan synan.',
	FlowMessage_InvalidPrice: 'Lütfen haýsy bahany giriziň.',
	FlowMessage_InfoCardCopied: 'Bahasy kopiýalanyp goşuldy.',
	FlowMessage_UnderConstruction: 'Girmäge çalyşýan sahypa gurnalanokda.',
	FlowMessage_InvalidLogin: 'Nädogry ulanyjy adyny ýa-da paroly.',
	FlowMessage_LoginSuccess: 'Ulgamga üstünlikli giriş edildi. Hoş geldiňiz!',
	FlowMessage_LoginError: 'Girişde hata bar. Gaýtadan synan.',
	FlowMessage_UnfilledFieldWarning: 'Ähli meýdany dolduryň.',
	FlowMessage_ModifySuccess: 'Täzeleniş muvaffaqyýetli ýerine ýetirildi.',
	FlowMessage_PositionClose: 'Meýilnama üstünlikli ýapylmady.',
	FlowMessage_OrderDelete: 'Buyruklar üstünlikli pozmazaldy.',
	FlowMessage_PositionCloseRejected: 'Meýilnama ýapylmady.',
	FlowMessage_OrderDeleteRejected: 'Buyruklar pozmazaldy.',
	FlowMessage_Login_InvalidInformation: 'Ýeterli ýa-da nädogry ulanyjy maglumatlary.',
	FlowMessage_Login_GroupNotFound: 'Topar tapylmady.',
	FlowMessage_Login_CUN_Error: 'Giriş edip bilmek üçin täzelenen ulgamy ýüklemelidir.',
	FlowMessage_Logout_N: 'Siz üstünlikli çykdym.',
	FlowMessage_Logout_R: 'Siz çykdyňyz. Gaýtadan giriş...',
	FlowMessage_Logout_0: 'Siz serwer saýlanjysy sebäpli çykdyňyz.',
	FlowMessage_Logout_1: 'Siz baglanyşyk gutardy. Siz çykdyňyz.',
	FlowMessage_Logout_UserDeleted: 'Ulanyjy pozmazaldy.',
	FlowMessage_Logout_DataSourceIsNotReady: 'Sizin sessiýaňyz gutarýar. Gaýtadan giriş ediň.',
	FlowMessage_Logout_LoginFromAnotherDevice: 'Başga ulgatdan giriş edildi. Siz çykdyňyz.',
	FlowMessage_Logout_GroupChanged: 'Siz baglanyşyk gutardy. Gaýtadan giriş ediň.',
	FlowMessage_Logout_ConnectionFailed: 'Baglanyşyk gutardy. Gaýtadan giriş ediň.',
	FlowMessage_ChangeInformationSuccess: 'Siziň maglumatlaryňyz täzelendi. \nGiriş ekranyna yönlendirilýär.',
	FlowMessage_ChangeInformationError: 'Ýalňyşlyk ýüze çykdy. Şifrenizi üýtgedip bilmän.',
	FlowMessage_CustomerComplaintSuccess: 'Iňize goşuşyk etdiňiz.',
	FlowMessage_InvestorError: 'Investor hasabynda ulanmaga rugsat etmän.',
	FlowMessage_SymbolNotFound: 'Nyşan maglumaty tapylmady.',
	FlowMessage_Trade_Waiting: 'Buyruk işlenilýär, gözleýäň...',
	FlowMessage_Trade_InvalidOrderType: 'Geçersiz buyruk formaty.',
	FlowMessage_Trade_InvalidRequestType: 'Geçersiz talyp formaty.',
	FlowMessage_Trade_InvalidVolume: 'Geçersiz hajm.',
	FlowMessage_Trade_InvalidPrice: 'Geçersiz baha.',
	FlowMessage_Trade_Buy_MarketLimit: 'Bozor çenli syyahat limiti bahasy satyn almaga yada bozor çenli syyahat limiti bahasy satyn almaga den çeşmeýär.',
	FlowMessage_Trade_Buy_StopLoss: 'Satyn alma bahasynyň durma geçiş bahasy yada satyn alma bahasynyň eýe bolmagyndan kiçijik bolmaly.',
	FlowMessage_Trade_Buy_TakeProfit: 'Satyn alma bahasynyň hasyl geçiş bahasy yada satyn alma bahasynyň eýe bolmagyndan kiçijik bolmaly.',
	FlowMessage_Trade_Sell_MarketLimit: 'Bozor çenli satyn alan syyahat limiti bahasy satyn almaga yada bozor çenli satyn alan syyahat limiti bahasy satyn almaga den çeşmeýär.',
	FlowMessage_Trade_Sell_StopLoss: 'Satyn alan bahasynyň durma geçiş bahasy yada satyn alan bahasynyň eýe bolmagyndan kiçijik bolmaly.',
	FlowMessage_Trade_Sell_TakeProfit: 'Satyn alan bahasynyň hasyl geçiş bahasy yada satyn alan bahasynyň eýe bolmagyndan kiçijik bolmaly.',
	FlowMessage_Deposit: 'Deposit bildirişi ýerine ýetirildikden soň, hyzmatdaşa habar bermek.',
	FlowMessage_Withdraw: 'Aýyrmak bildirişi ýerine ýetirildikden soň, hyzmatdaşa habar bermek.',
	FlowMessage_PhoneError: 'Nädogry telefonyň belgisi!\nTelefonyň belgisi faqat belgilerden ybarat bolmalydır.',
	FlowMessage_EmailError: 'Nädogry e-mail belgisi!\nLütfen geçerli bir e-posta adresi giriniz.',
	FlowMessage_ReferenceAdded: 'Referensiýa üstünlikli goşuldy.',
	FlowMessage_ReferenceAllReadyCollected: 'Siz bonusunyzy hasaplamadyňyz!',
	FlowMessage_ReferenceUnderReview: 'Bonusunyz gutarylmady.',
	FlowMessage_ReferenceNotEnough: 'Siz bonus üçin gerekli referensiýalara eýýämok!',
	FlowMessage_InvalidComplaint: 'Lütfen geçerli bir şikayet giriniz.',
	Retcode_0: '',
	Retcode_10001: 'Sorag ýola goýulan.',
	Retcode_10002: 'Sorag kabul edildi.',
	Retcode_10003: 'Sorag işlenýär.',
	Retcode_10004: 'Soraga jogap berilende teklip däl.',
	Retcode_10005: 'Soraga jogap berilende bahalar.',
	Retcode_10006: 'Sorag redakte.',
	Retcode_10007: 'Sorag yza çekildi.',
	Retcode_10008: 'Sorag netijesinde order girizildi.',
	Retcode_10009: 'Sorag tamamlandy.',
	Retcode_10010: 'Sorag bäş yzygider.',
	Retcode_10011: 'Umumy sorag ýalňyşlyk.',
	Retcode_10012: 'Sorag möhleti dowam edýär.',
	Retcode_10013: 'Geçersiz sorag.',
	Retcode_10014: 'Geçersiz hajm.',
	Retcode_10015: 'Geçersiz baha.',
	Retcode_10016: 'Yalňyş durma derejeleri ýa-da baha.',
	Retcode_10017: 'Ticarýet ýatyrylyş.',
	Retcode_10018: 'Bozor gaýtadan ýapylýar.',
	Retcode_10019: 'Azyk pul.',
	Retcode_10020: 'Baha üýtgedi.',
	Retcode_10021: 'Baha elýeterli däl.',
	Retcode_10022: 'Geçersiz order çalt süresi.',
	Retcode_10023: 'Order üýtgedildi.',
	Retcode_10024: 'Ticarýet soraglaryň hasaby 128den gowrak bolmaly. Mysal üçin bu ýalňyşlyk Administrator API serwerden 128den gowrak ticarýet soraglary göndermekde syn bolup biler.',
	Retcode_10025: 'Soragda üýtgeşiklik ýok.',
	Retcode_10026: 'Serwerda awtomatic tajawul ýatyrylypdyr.',
	Retcode_10027: 'Müşderi tarapy awtomatic tajawul ýatyrylypdyr.',
	Retcode_10028: 'Sorag dilere taýýarlanyň tarapyndan ýasaklandy.',
	Retcode_10029: 'Orderiň ýa-da gatnaşygyň dolandygy ýeterlik köpçülikde üýtgedildi.',
	Retcode_10030: 'Doldurmagyň görnüşi doly bölümi.',
	Retcode_10031: 'Bağlanyşyga ýetirilmedi.',
	Retcode_10032: 'Yalňyşlyk bilen häzirki hasabyňam esasy.',
	Retcode_10033: 'Order sanynyň hasary ýetirildi.',
	Retcode_10034: 'Hajm hasary ýetirildi.',
	Retcode_10035: 'Geçersiz ýa-da yassan order görnüşi.',
	Retcode_10036: 'Pozisiýa eýe ýetirildi. Mysal üçin, bu ýalňyşlyk pozisiýanyň durma derejelerini üýtgedende görkezilýär.',
	Retcode_10037: 'Içerki maksatlar üçin ulanylýar.',
	Retcode_10038: 'Ýapyk pozisiýanyň käbirliği indiki pozisiýanyň hasabyna diňe şahamçalaryň hasaby ösýär.',
	Retcode_10039: 'Pozisiýa aýyrmagyň soragy bar. Mysal üçin, bu ýalňyşlyk jenelleme modunda görkezilýär: adam bu amala aýyrmak islese, ol özara tärs däl ýa-da meňzeş pozisiýa üçin bar pozisiýanyny aýyrmak islese.',
	Retcode_10040: 'Hesapda bir wagtda eşdälikli aýyrmaklar bozulup biler. Sanki bir çykys bozulan wagty esas alýarys: iň täze çykyş birinji aýyrmakdyr, soňraki biri we beýle-de.',
	Retcode_10041: 'Sorag redakte, order yza çekildi. Bu koda IMTConRoute::ACTION_CANCEL_ORDER işlemi uygalandygynda alynyr.',
	Retcode_10042: 'Sorag "Yalňyz uzyn pozisiýalar berilýär" düzümi üçin redakte. (IMTConSymbol::TRADE_LONGONLY).',
	Retcode_10043: 'Sorag "Yalňyz kysga pozisiýalar berilýär" düzümi üçin redakte. (IMTConSymbol::TRADE_SHORTONLY).',
	Retcode_10044: 'Sorag "Yalňyz pozisiýany aýyrmak mümkin" düzümi üçin redakte. (IMTConSymbol::TRADE_CLOSEONLY).',
	Retcode_10045:
		'Pozisiýa aýyrmagy FIFO ruly bilen ytmalmady. Bu IMTConGroup::TRADEFLAGS_FIFO_CLOSE opsiýasy ýeterlik çynlyklandyryldyga gutarylan guruplarda ulanylýar. Bu opsiýasy hasaba alynan jenelleme tüýsini: Netting - açyk pozisiýalar sanyna eýe bolan. Hanzadaş barlagynda hasaba alynyp, platforma açyk pozisiýalaryň sanyny aýlamakdan beýle işlemegi goýberýär. Şu hükmüň eýýämok, sebäpli şu sahypa jenelleme hili esasynda çykys berilip bolan sanlar görkezilmeýär. Beýlekiler goral çykyslar göz öňünde tutulmady sebäbi, şeýle-de çünkü olar, bazardaky mevcut pozisiýanyň girizilýän görnüşlerinin hasabyna üýtgetmäge möhlet bermäge möhlet berýär. Hedging - beýlekiler goral çykyslar hem açyk pozisiýalaryň doly döretmegi we açyk pozisiýalaryň birleşik goşulan we çykarylan zatlaryna esasylandyrylýar, sebäbi esasynda çykys beriljek bolsa, onda şu işlem täze pozisiýa döretmek bilen dowam edýär.',
	Retcode_10046: 'Pozisiýa döretmek ýa-da gatnaşykly sargyt düzmek beýle zatlaryň dowam etmäýär. Bu ýalňyşlyk IMTConGroup::TRADEFLAGS_HEDGE_PROHIBIT belgisiniň gurup üçin çalşyrylandyryldyga we ulanyjy aýyryşyk ýa-da ulanyjy öndüridir, bazardaň şu meýili üçin berleneniň öndüridir ýa-da eýýämok goşulan şu meýil, eýýämok däldir bolsa.',
};
