import { Image, StyleSheet, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { getCountriesFromSymbolName } from '../../../utils/countriesFromSymbol';
import { flagsCrypto, flagsCurrency, flagsEnergy, flagsIndice, flagsMetal } from '../../../assets/images';

interface Props {
	CurrencyBase: string;
	CurrencyProfit: string;
	Category: string;
	Symbol: string;
	test?: any;
}

const SymbolIcon: React.FC<Props> = ({ Symbol, CurrencyBase, CurrencyProfit, test, Category }) => {
	if (Category === 'crypto') {
		return (
			<View>
				<View style={styles.forexWrapper}>
					<Image
						style={[styles.icon, styles.cryptoIconTop]}
						source={flagsCrypto[CurrencyBase] ?? flagsCrypto.BWP}
					/>
					<Image
						style={[styles.icon, styles.forexIconBottom]}
						source={flagsCurrency[CurrencyProfit] ?? flagsCurrency.BWP}
					/>
				</View>
			</View>
		);
	} else if (Category === 'metal') {
		return (
			<View>
				<View style={styles.forexWrapper}>
					<Image
						style={[styles.icon, styles.cryptoIconTop]}
						source={flagsMetal[Symbol.slice(0, 3)] ?? flagsCurrency.BWP}
					/>
					<Image
						style={[styles.icon, styles.forexIconBottom]}
						source={flagsCurrency[CurrencyProfit] ?? flagsCurrency.BWP}
					/>
				</View>
			</View>
		);
	} else if (Category === 'energy') {
    let clearedSymbol = "";
    if (Symbol.indexOf('OIL') > -1) {
      clearedSymbol = 'OIL';
    }
    else if (Symbol.indexOf('NATGAS') > -1) {
      clearedSymbol = 'NATGAS';
    }
    
		return (
			<View>
				<View style={styles.forexWrapper}>
					<Image
						style={[styles.icon, styles.cryptoIconTop]}
						source={flagsEnergy[clearedSymbol] ?? flagsCurrency.BWP}
					/>
					<Image
						style={[styles.icon, styles.forexIconBottom]}
						source={flagsCurrency[CurrencyProfit] ?? flagsCurrency.BWP}
					/>
				</View>
			</View>
		);
	} else if (Category === 'indice') {
		let clearedSymbol = '';
		if (Symbol.indexOf('DE.30') > -1) {
			clearedSymbol = 'DE.30';
		} else if (Symbol.indexOf('EU.50') > -1) {
			clearedSymbol = 'EU.50';
		} else if (Symbol.indexOf('FRA.40') > -1) {
			clearedSymbol = 'FRA.40';
		} else if (Symbol.indexOf('IND50') > -1) {
			clearedSymbol = 'IND50';
		} else if (Symbol.indexOf('SPA.35') > -1) {
			clearedSymbol = 'SPA.35';
		} else if (Symbol.indexOf('SUI20') > -1) {
			clearedSymbol = 'SUI20';
		} else if (Symbol.indexOf('UK.100') > -1) {
			clearedSymbol = 'UK.100';
		} else if (Symbol.indexOf('US.30') > -1) {
			clearedSymbol = 'US.30';
		} else if (Symbol.indexOf('US.100') > -1) {
			clearedSymbol = 'US.100';
		} else if (Symbol.indexOf('US.500') > -1) {
			clearedSymbol = 'US.500';
		}

		return (
			<View>
				<View style={[styles.forexWrapper, { justifyContent: 'center' }]}>
					<Image
						style={[styles.icon, styles.oneIcon]}
						source={flagsIndice[clearedSymbol] ?? flagsCurrency.BWP}
					/>
				</View>
			</View>
		);
	} else if (Category === 'stocks') {
		return (
			<View>
				<View style={[styles.forexWrapper, { justifyContent: 'center' }]}>
					<Image
						style={[styles.icon, styles.oneIcon]}
						source={flagsCurrency[Symbol] ?? flagsCurrency.BWP}
					/>
				</View>
			</View>
		);
	} else {
		return (
			<View>
				<View style={styles.forexWrapper}>
					<Image
						style={[styles.icon, styles.forexIconTop]}
						source={flagsCurrency[CurrencyBase] ?? flagsCurrency.BWP}
					/>
					<Image
						style={[styles.icon, styles.forexIconBottom]}
						source={flagsCurrency[CurrencyProfit] ?? flagsCurrency.BWP}
					/>
				</View>
			</View>
		);
	}
};

export default SymbolIcon;

{
	/* 
  
  <View style={styles.forexWrapper}>
        <Text style={styles.forexIconTop}>EU</Text>
        <Text style={styles.forexIconBottom}>US</Text>
      </View>

  
  <View style={styles.forexWrapper}>
        <Image
          style={[styles.icon, styles.forexIconTop]}
          source={{uri: `http://localhost:4001/images/${symbols[0]}`}}
        />
        <Image
          style={[styles.icon, styles.forexIconBottom]}
          source={{uri: `http://localhost:4001/images/${symbols[1]}`}}
        />
      </View> */
}

const styles = StyleSheet.create({
	forexWrapper: {
		position: 'relative',
		width: 40,
		height: 40,
	},
	forexIconTop: {
		position: 'absolute',
		top: 2,
		left: 1,
		zIndex: 0,
	},
	cryptoIconTop: {
		position: 'absolute',
		top: 2,
		left: 1,
		zIndex: 2,
	},
	forexIconBottom: {
		position: 'absolute',
		bottom: 2,
		right: 1,
		zIndex: 1,
	},
	// forexIconBottom: {
	//   position: 'absolute',
	//   top: '33%',
	//   left: '33%',
	// },
	icon: {
		width: 28,
		height: 28,
		padding: 10,
	},
	oneIcon: {
		alignSelf: 'center',
	},
});
