import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  Dimensions,
  Pressable,
} from "react-native";
import { useState } from "react";

// components
import { useTheme } from "../../context/Theme/ThemeProvider";
import { IColors, FONTSIZE } from "../../Global";
import InputCard from "../../components/InputCard";
import apiCall from "../../utils/apiCall";
import { showFlowMessage } from "../../components/FlowMessage";
import { useLanguage } from "../../context/Language/LanguageProvider";

const AddReferences = (props: any) => {
  const { colors } = useTheme();
  const { words } = useLanguage();
  const styles = Styles(colors);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

  const resetPage = () => {
    props?.navigation.goBack();
  };

  const submitHandle = () => {
    if (!firstName || !lastName || !phone || !email) {
      showFlowMessage({
        message: words["FlowMessage_UnfilledFieldWarning"],
        type: "error",
      });
      return;
    }
    if (isNaN(Number(phone))) {
      showFlowMessage({
        message: words["FlowMessage_PhoneError"],
        type: "error",
      });
      return;
    }
    if (phone.length < 10 || phone.length > 12) {
      showFlowMessage({
        message: words["FlowMessage_PhoneError"],
        type: "error",
      });
      return;
    }

    const test = emailRegex.test(email);
    if (!test) {
      showFlowMessage({
        message: words["FlowMessage_EmailError"],
        type: "error",
      });
      return;
    }

    apiCall(
      "/add-reference",
      {
        firstname: firstName,
        lastname: lastName,
        phone: phone,
        email: email,
      },
      "POST"
    )
      .then((response) => {
        console.log(response, "favLog");
        showFlowMessage({
          message: words["FlowMessage_ReferenceAdded"],
          type: "success",
        });

        resetPage();
      })
      .catch((err) => {
        console.log(err);
        showFlowMessage({
          message: words["ReferencesAdd_Error"],
          type: "warning",
        });
      });
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
      <View style={{ gap: 10, flexDirection: "column" }}>
        <InputCard
          type="basic"
          args={{ title: "İsim", value: "" }}
          response={setFirstName}
        />
        <InputCard
          type="basic"
          args={{ title: "Soyisim", value: "" }}
          response={setLastName}
        />
        <InputCard
          type="phone"
          args={{ title: "Telefon", value: "" }}
          response={setPhone}
        />
        <InputCard
          type="email"
          args={{ title: "E-Mail", value: "" }}
          response={setEmail}
        />
      </View>
      <View>
        <Pressable
          style={{
            backgroundColor: colors.ACCEPT,
            padding: 10,
            borderRadius: 5,
            marginTop: 20,
          }}
          onPress={submitHandle}
        >
          <Text
            style={{
              color: colors.ACCEPT_TEXT,
              textAlign: "center",
              fontSize: FONTSIZE.xl,
              fontWeight: "700",
              letterSpacing: 1.3,
            }}
          >
            Referans Ekle
          </Text>
        </Pressable>
      </View>
    </ScrollView>
  );
};

export default AddReferences;

const Styles = (COLOR: IColors) =>
  StyleSheet.create({
    container: {
      padding: 10,
      backgroundColor: COLOR.SCREEN_BACKGROUND,
    },
  });
