export const darkColors = {
	SCREEN_BACKGROUND: '#262d33',
	HEADER_BACKGROUND: '#262d33',
	HEADER_TEXT: '#FFFFFF',
	HEADER_ICON: '#FFFFFF',
	HEADER_BORDER: '#FFFFFF',

	CONTENT_BACKGROUND: '#262d33',
	CONTENT_TEXT: '#FFFFFF',
	CONTENT_ICON: '#FFFFFF',
	CONTENT_BORDER: '#FFFFFF',

	BOTTOM_BACKGROUND: 'transparent',
	BOTTOM_BACKGROUND_FOCUSED: 'transparent',
	BOTTOM_TEXT: '#FFFFFF',
	BOTTOM_TEXT_FOCUSED: '#006DD2',
	BOTTOM_ICON: '#FFFFFF',
	BOTTOM_ICON_FOCUSED: '#006DD2',

	TEXT: '#FFFFFF',
	TEXT2: '#FFFFFF',
	TEXT_OPPOSITE: '#000000',
	TEXT_OPPOSITE2: '#000000',

	PROFIT: '#1A8FE3',
	LOSS: '#D11149',
	NATURAL: '#CACACA',

	MAIN_BUTTON_BACKGORUND: '#006DD2',
	MAIN_BUTTON_TEXT: '#FFFFFF',

	CARD_BACKGROUND1: '#3f4c58',
	CARD_TEXT1: '#FFFFFF',
	CARD_BACKGROUND2: '#28333d',
	CARD_TEXT2: '#FFFFFF',
	CARD_LOWER_OPACITY: 'rgba(158, 150, 150, .5)',
	CARD_DETAIL_BACKGROUND: '#555555',
	CARD_DETAIL_TEXT: '#FFFFFF',

	INPUT_BACKGROUND: '#3a4a52',
	INPUT_TEXT: '#FFFFFF',

	BUY: '#1A8FE3',
	SELL: '#D11149',

	TAKE_PROFIT: '#1A8FE3',
	TAKE_PROFIT_TEXT: '#FFFFFF',
	STOP_LOSS: '#D11149',
	STOP_LOSS_TEXT: '#FFFFFF',
	STOP_OUT: '#F5A623',
	STOP_OUT_TEXT: '#000000',

	BUY_ORDER: '#1A8FE3',
	BUY_ORDER_TEXT: '#FFFFFF',
	SELL_ORDER: '#D11149',
	SELL_ORDER_TEXT: '#FFFFFF',
	BUY_LIMIT: '#1A8FE3',
	BUY_LIMIT_TEXT: '#FFFFFF',
	SELL_LIMIT: '#D11149',
	SELL_LIMIT_TEXT: '#FFFFFF',
	BUY_STOP: '#1A8FE3',
	BUY_STOP_TEXT: '#FFFFFF',
	SELL_STOP: '#D11149',
	SELL_STOP_TEXT: '#FFFFFF',

	BALANCE_BALANCE: '#007AFF',
	BALANCE_BALANCE_TEXT: '#FFFFFF',
	BALANCE_CREDIT: '#FFCC00',
	BALANCE_CREDIT_TEXT: '#000000',
	BALANCE_CORRECTION: '#F5A623',
	BALANCE_CORRECTION_TEXT: '#000000',
	BALANCE_BONUS: '#1A8FE3',
	BALANCE_BONUS_TEXT: '#FFFFFF',
	BALANCE_DEFAULT: '#313131',
	BALANCE_DEFAULT_TEXT: '#FFFFFF',

	SHADOW1: '#000000',
	SHADOW2: '#FFFFFF',
	SHADOW3: '#171717',

	CLOSED_MARKET: 'rgba(33,33,33,0.9)',
	CLOSED_MARKET_TEXT: '#D11149',

	TRADE_MODE_CLOSED: '#D11149',
	TRADE_MODE_CLOSED_TEXT: '#FFFFFF',
	TRADE_MODE_ONLY_BUY: '#FFCC00',
	TRADE_MODE_ONLY_SELL_TEXT: '#FFFFFF',
	TRADE_MODE_ONLY_SELL: '#FFCC00',
	TRADE_MODE_ONLY_BUY_TEXT: '#FFFFFF',
	TRADE_MODE_BUY_SELL: '#FFFFFF',
	TRADE_MODE_BUY_SELL_TEXT: '#000000',
	TRADE_MODE_ONLY_CLOSE: '#D11149',
	TRADE_MODE_ONLY_CLOSE_TEXT: '#FFFFFF',

	ACCEPT: '#1A8FE3',
	ACCEPT_TEXT: '#FFFFFF',
	REJECT: '#D11149',
	REJECT_TEXT: '#FFFFFF',
	THEME: 'dark',

	ACTIVITY_INDICATOR: '#D11149',

	RED: '#D11149',
	ORANGE: '#F5A623',
	GREEN: '#1A8FE3',
	BLUE: '#007AFF',
	YELLOW: '#FFCC00',
	WHITE: '#FFFFFF',
	BLACK: '#000000',
	GRAY: '#313131',
	LIGHT_GRAY: '#F2F2F2',

	LAMB: '#FFF',

	BONUS_AVAILABLE: '#1A8FE3',
	BONUS_AVAILABLE_TEXT: '#FFFFFF',
	BONUS_UNAVAILABLE: '#D11149',
	BONUS_UNAVAILABLE_TEXT: '#FFFFFF',
	BONUS_COLLECTED: '#30d5c8',
	BONUS_COLLECTED_TEXT: '#FFFFFF',
	BONUS_PENDING: '#FFCC00',
	BONUS_PENDING_TEXT: '#000000',

	INVESTOR_BACKGROUND: '#ec942c',
	INVESTOR_TEXT: '#000',

	INFO: '#007AFF',
	INFO_TEXT: '#FFFFFF',
	WARNING: '#FF8C00',
	WARNING_TEXT: '#000000',
	ERROR: '#D11149',
	ERROR_TEXT: '#FFFFFF',
	SUCCESS: '#1A8FE3',
	SUCCESS_TEXT: '#FFFFFF',

	FAVORITE: '#006DD2',
};

export const lightColors = {
	SCREEN_BACKGROUND: '#F5F5F5',
	HEADER_BACKGROUND: '#FFFFFF',
	HEADER_TEXT: '#000000',
	HEADER_ICON: '#000000',
	HEADER_BORDER: '#000000',

	CONTENT_BACKGROUND: '#FFFFFF',
	CONTENT_TEXT: '#000000',
	CONTENT_ICON: '#000000',
	CONTENT_BORDER: '#000000',

	BOTTOM_BACKGROUND: 'transparent',
	BOTTOM_BACKGROUND_FOCUSED: 'transparent',
	BOTTOM_TEXT: '#000000',
	BOTTOM_TEXT_FOCUSED: '#006DD2',
	BOTTOM_ICON: '#000000',
	BOTTOM_ICON_FOCUSED: '#006DD2',

	TEXT: '#000000',
	TEXT2: '#000000',
	TEXT_OPPOSITE: '#FFFFFF',
	TEXT_OPPOSITE2: '#FFFFFF',

	PROFIT: '#1A8FE3',
	LOSS: '#D11149',
	NATURAL: '#CACACA',

	MAIN_BUTTON_BACKGORUND: '#006DD2',
	MAIN_BUTTON_TEXT: '#FFFFFF',

	CARD_BACKGROUND1: '#d2e3f2',
	CARD_TEXT1: '#000000',
	CARD_BACKGROUND2: '#a4c7e6',
	CARD_TEXT2: '#000000',
	CARD_LOWER_OPACITY: 'rgba(158, 150, 150, .5)',
	CARD_DETAIL_BACKGROUND: '#CCCCCC',
	CARD_DETAIL_TEXT: '#000000',

	INPUT_BACKGROUND: '#FFFFFF',
	INPUT_TEXT: '#000000',

	BUY: '#1A8FE3',
	SELL: '#D11149',

	TAKE_PROFIT: '#1A8FE3',
	TAKE_PROFIT_TEXT: '#FFFFFF',
	STOP_LOSS: '#D11149',
	STOP_LOSS_TEXT: '#FFFFFF',
	STOP_OUT: '#F5A623',
	STOP_OUT_TEXT: '#000000',

	BUY_ORDER: '#1A8FE3',
	BUY_ORDER_TEXT: '#FFFFFF',
	SELL_ORDER: '#D11149',
	SELL_ORDER_TEXT: '#FFFFFF',
	BUY_LIMIT: '#1A8FE3',
	BUY_LIMIT_TEXT: '#FFFFFF',
	SELL_LIMIT: '#D11149',
	SELL_LIMIT_TEXT: '#FFFFFF',
	BUY_STOP: '#1A8FE3',
	BUY_STOP_TEXT: '#FFFFFF',
	SELL_STOP: '#D11149',
	SELL_STOP_TEXT: '#FFFFFF',

	BALANCE_BALANCE: '#007AFF',
	BALANCE_BALANCE_TEXT: '#FFFFFF',
	BALANCE_CREDIT: '#FFCC00',
	BALANCE_CREDIT_TEXT: '#000000',
	BALANCE_CORRECTION: '#F5A623',
	BALANCE_CORRECTION_TEXT: '#000000',
	BALANCE_BONUS: '#1A8FE3',
	BALANCE_BONUS_TEXT: '#FFFFFF',
	BALANCE_DEFAULT: '#313131',
	BALANCE_DEFAULT_TEXT: '#FFFFFF',

	SHADOW1: '#000000',
	SHADOW2: '#FFFFFF',
	SHADOW3: '#171717',

	CLOSED_MARKET: 'rgba(255,255,255,0.9)',
	CLOSED_MARKET_TEXT: '#D11149',

	TRADE_MODE_CLOSED: '#D11149',
	TRADE_MODE_CLOSED_TEXT: '#FFFFFF',
	TRADE_MODE_ONLY_BUY: '#FFCC00',
	TRADE_MODE_ONLY_SELL_TEXT: '#FFFFFF',
	TRADE_MODE_ONLY_SELL: '#FFCC00',
	TRADE_MODE_ONLY_BUY_TEXT: '#FFFFFF',
	TRADE_MODE_BUY_SELL: '#FFFFFF',
	TRADE_MODE_BUY_SELL_TEXT: '#000000',
	TRADE_MODE_ONLY_CLOSE: '#D11149',
	TRADE_MODE_ONLY_CLOSE_TEXT: '#FFFFFF',

	ACCEPT: '#1A8FE3',
	ACCEPT_TEXT: '#FFFFFF',
	REJECT: '#D11149',
	REJECT_TEXT: '#FFFFFF',
	THEME: 'light',

	ACTIVITY_INDICATOR: '#D11149',

	RED: '#D11149',
	ORANGE: '#F5A623',
	GREEN: '#1A8FE3',
	BLUE: '#007AFF',
	YELLOW: '#FFCC00',
	WHITE: '#FFFFFF',
	BLACK: '#000000',
	GRAY: '#313131',
	LIGHT_GRAY: '#F2F2F2',

	LAMB: '#FFCC00',

	BONUS_AVAILABLE: '#1A8FE3',
	BONUS_AVAILABLE_TEXT: '#FFFFFF',
	BONUS_UNAVAILABLE: '#D11149',
	BONUS_UNAVAILABLE_TEXT: '#FFFFFF',
	BONUS_COLLECTED: '#30d5c8',
	BONUS_COLLECTED_TEXT: '#FFFFFF',
	BONUS_PENDING: '#FFCC00',
	BONUS_PENDING_TEXT: '#000000',

	INVESTOR_BACKGROUND: '#ec942c',
	INVESTOR_TEXT: '#000',

	INFO: '#007AFF',
	INFO_TEXT: '#FFFFFF',
	WARNING: '#FFA500',
	WARNING_TEXT: '#000000',
	ERROR: '#D11149',
	ERROR_TEXT: '#FFFFFF',
	SUCCESS: '#1A8FE3',
	SUCCESS_TEXT: '#FFFFFF',

	FAVORITE: '#006DD2',
};
