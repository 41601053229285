import React from 'react';
import {View, Text, StyleSheet} from 'react-native';


interface ReferenceCardProps {
  Name: string;
  Surname: string;
  Phone: string;
  Email: string;
  isApprovedType: number;
  Reason: string;
}

import {IColors} from '../../Global';
import {useTheme} from '../../context/Theme/ThemeProvider';
import { AntDesign, Fontisto, MaterialIcons } from '@expo/vector-icons';

const ReferenceCardProps = ({Name, Surname, Email, Phone, isApprovedType = 0, Reason= "İnceleniyor"}: ReferenceCardProps) => {
  const {colors} = useTheme();
  const styles = Styles(colors);

  const checkBox = () => {
    switch (isApprovedType) {
      case 0:
        return <Fontisto name='checkbox-active' size={30} color={colors.PROFIT} />;
      case 1:
        return <AntDesign name='clockcircleo' size={30} color={colors.TEXT}/>;
      case 2:
        return <MaterialIcons name='report-problem' size={30} color={colors.LOSS} />;
      default:
        return <AntDesign name='clockcircleo' size={30} color={colors.LOSS} />;
    }
  };
  return (
    <View style={styles.card}>
      <View style={{flex: 5, flexDirection: 'column', alignItems: 'flex-start', gap:2}}>
        <View>
          <Text style={styles.text}>
            {Name} {Surname}
          </Text>
        </View>
        <View>
        <Text style={styles.text}>{Phone} | {Email}</Text>
        </View>
        <View>
        <Text style={[styles.text, isApprovedType==0?{color:colors.PROFIT}:{color:colors.TEXT}]}>Sonuç: {Reason}</Text>
        </View>
      </View>
      <View style={{flex: 1, alignItems: 'flex-end'}}>{checkBox()}</View>
    </View>
  );
};

const Styles = (COLOR: IColors) =>
  StyleSheet.create({
    card: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: 10,
      backgroundColor: COLOR.CARD_BACKGROUND1,
      borderRadius: 5,
    },
    text: {
      color: COLOR.TEXT,
      fontSize: 12,
    },
  });

export default ReferenceCardProps;
