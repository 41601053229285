import * as Clipboard from 'expo-clipboard';
import React from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from "react-native";
import { FONTSIZE, IColors } from "../../Global";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { showFlowMessage } from "../FlowMessage";
import { useLanguage } from "../../context/Language/LanguageProvider";

interface ArgsProp {
	title?: string;
	titleStyle?: StyleProp<TextStyle> | undefined;
	value?: string;
	valueStyle?: StyleProp<TextStyle> | undefined;
	route?: string;
	params?: any;
}
interface Prop {
  type:
    | "basic"
    | "route"
    | "basicWithCopy"
    | "basicWithCopyXL"
    | "oneWithCustomStyle";
  args?: ArgsProp;
  titleStyle?: StyleProp<TextStyle> | undefined;
  valueStyle?: StyleProp<TextStyle> | undefined;
  navigation?: any;
}

const InfoCard: React.FC<Prop> = ({
  type,
  args,
  titleStyle,
  valueStyle,
  navigation,
}) => {
  const { colors } = useTheme();
  const { words } = useLanguage();

  const style = Style(colors);

  async function saveToClipboard(key: string, value: string) {
    await Clipboard.setStringAsync(value);
    showFlowMessage({
      message: key + ` ${words["FlowMessage_InfoCardCopied"]} `,
      type: "success",
    });
  }

  if (type == "route") {
    return (
      <TouchableOpacity
        onPress={() => navigation.navigate(args?.route, args?.params)}
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          },
        ]}
      >
        <Text style={[style.Key, titleStyle]}>{args?.title}</Text>
        <Text style={[style.Value, valueStyle]}>{args?.value}</Text>
      </TouchableOpacity>
    );
  } else if (type == "oneWithCustomStyle") {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          },
        ]}
      >
        <Text style={args?.valueStyle}>{args?.value}</Text>
      </View>
    );
  }
  if (type == "basicWithCopy") {
    return (
      <TouchableOpacity
        onPress={() => {
          saveToClipboard(args?.title || 'Null', args?.value || 'Null');
        }}
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          },
        ]}
      >
        <Text style={[style.Key, titleStyle]}>{args?.title}</Text>
        <Text style={[style.Value, valueStyle]}>{args?.value}</Text>
      </TouchableOpacity>
    );
  } else if (type == "basicWithCopyXL") {
    return (
      <View style={[style.BgColors, { flexDirection: "row" }]}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          }}
        >
          <Text style={[style.Key, titleStyle]}>{args?.title}</Text>
          <Text style={[style.Value, valueStyle]}>{args?.value}</Text>
        </View>
        <View></View>
      </View>
    );
  } else {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          },
        ]}
      >
        <Text style={[style.Key, titleStyle]}>{args?.title}</Text>
        <Text style={[style.Value, valueStyle]}>{args?.value}</Text>
      </View>
    );
  }
};

export default InfoCard;

const Style = (COLOR: IColors) =>
  StyleSheet.create({
    BgColors: {
      backgroundColor: COLOR.CARD_BACKGROUND1,
      borderRadius: 5,
      paddingHorizontal: 10,
      paddingVertical: 5,
      width: "100%",
      minHeight: 40,
      gap: 5,
    },
    Key: {
      color: COLOR.TEXT,
      fontSize: FONTSIZE.l,
      textAlign: "justify",
      flex: 3,
    },
    Value: {
      color: COLOR.TEXT,
      fontSize: FONTSIZE.l,
      fontWeight: "600",
      textAlign: "right",
      flex: 5
    },
  });
