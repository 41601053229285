/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 */

import React from "react";
import { SafeAreaView, StatusBar } from "react-native";
import FlowMessage from "./components/FlowMessage";
import Router from "./Router";
import { LanguageProvider } from "./context/Language/LanguageProvider";
import { ThemeProvider } from "./context/Theme/ThemeProvider";
import { AuthProvider } from "./context/AuthContext";

function App(): JSX.Element {
  return (
    <LanguageProvider>
      <AuthProvider>
        <SafeAreaView style={{ flex: 1, marginTop: StatusBar.currentHeight }}>
          <ThemeProvider>
            <Router />
            <FlowMessage />
          </ThemeProvider>
        </SafeAreaView>
      </AuthProvider>
    </LanguageProvider>
  );
}

export default App;
