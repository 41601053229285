import {Text, View, StyleSheet} from 'react-native';
import {dateStr} from '../utils/dateUtil';

import {
  closedColumns,
  dealColumns,
  balanceColumns,
  orderColumns,
} from './Transaction/constants';

const getColumns = (type: string) => {
  let columns;
  console.log(type);
  switch (type) {
    case 'get-deals':
      columns = dealColumns;
      break;
    case 'Positions':
      columns = closedColumns;
      break;
    case 'Orders':
      columns = orderColumns;
      break;
    case 'Balance':
      columns = balanceColumns;
      break;
    default:
      columns = closedColumns;
      break;
  }
  return columns;
};

const TransactionDetails = (props: any) => {
  const transaction = props.route.params.selectedTransaction;
  const selectedColumn = getColumns(props.route.params.selectedReportType);

  const renderRow = (item: any, nameKeyPair: any) => {
    if (nameKeyPair.key === 'Action') {
      const date = dateStr(item['Time']);
      if (item[nameKeyPair.key] === '0') {
        return (
          <>
            <Text style={styles.fieldBuy}>{'Buy'}</Text>
            <Text style={styles.transactionValue}>{date}</Text>
          </>
        );
      } else if (item[nameKeyPair.key] === '1') {
        return (
          <>
            <Text style={styles.fieldSell}>{'Sell'}</Text>
            <Text style={styles.transactionValue}>{date}</Text>
          </>
        );
      }
    } else if (nameKeyPair.key !== 'Time') {
      return (
        <>
          <Text style={styles.transactionField}>{nameKeyPair.name}</Text>
          <Text style={styles.transactionValue}>{item[nameKeyPair.key]}</Text>
        </>
      );
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.profitName}>Kar/Zarar</Text>
        <View style={styles.profit}>
          {transaction.Profit > 0 ? (
            <Text style={styles.profitAmountGreen}>{transaction.Profit}</Text>
          ) : (
            <Text style={styles.profitAmountRed}>{transaction.Profit}</Text>
          )}
          <Text style={styles.profitSymbol}>{transaction.Symbol}</Text>
        </View>
      </View>
      <View style={styles.body}>
        {selectedColumn.map((item:any) => (
          <View style={styles.row} key={item.id}>
            {renderRow(transaction, item)}
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  //header
  header: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
  },
  profit: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: 5,
  },
  profitName: {
    fontSize: 14,
    color: '#000000',
  },
  profitAmountGreen: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#39B23E',
  },
  profitAmountRed: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#E30613',
  },
  profitSymbol: {
    fontSize: 14,
    color: '#000000',
  },
  //body
  body: {
    backgroundColor: '#FAFAFA',
    width: '100%',
    padding: 10,
    gap: 5,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  transactionField: {
    fontSize: 14,
    color: '#313131',
  },
  transactionValue: {
    fontSize: 14,
    color: '#313131',
  },
  fieldBuy: {
    fontSize: 14,
    color: '#39B23E',
  },
  fieldSell: {
    fontSize: 14,
    color: '#E30613',
  },
});

export default TransactionDetails;
