import React, { FC } from 'react';
import { Text, View } from 'react-native';
import { ISymbol } from '../../Global';
import { useTheme } from '../../context/Theme/ThemeProvider';

interface IField {
	id: number;
	name: string;
	field: keyof ISymbol;
}

const fields:Array<IField> = [
	{
        id: 1,
		name: 'Pazartesi',
		field: 'SwapRateMonday',
	},
	{
        id: 2,
		name: 'Salı',
		field: 'SwapRateTuesday',
	},
	{
        id: 3,
		name: 'Çarşamba',
		field: 'SwapRateWednesday',
	},
	{
        id: 4,
		name: 'Perşembe',
		field: 'SwapRateThursday',
	},
	{
        id: 5,
		name: 'Cuma',
		field: 'SwapRateFriday',
	},
	{
		id: 6,
		name: 'Cumartesi',
		field: 'SwapRateSaturday',
	},
	{
		id: 7,
		name: 'Pazar',
		field: 'SwapRateSunday',
	},
];

interface Props {
    data: any;
}
const SwapRates: FC<Props> = ({ data }:Props) => {
	const { colors } = useTheme();
	return (
		<View style={{marginBottom:10}}>
			<View>
				<Text style={{textAlign:'center', color:colors.TEXT, fontWeight:'bold', marginTop:7}}>Swap Oranları</Text>
			</View>
			<View>
				{fields.map((item: IField) => {
					return (
						<View style={{flexDirection:'row', justifyContent:'space-between'}} key={item.id} >
							<Text style={{color:colors.TEXT}}>{item.name}</Text>
							<Text style={{color:colors.TEXT}}>{data[item.field].toFixed(data.Digits | 2)}</Text>
						</View>
					);
				})}
			</View>
		</View>
	);
};

export default SwapRates;
