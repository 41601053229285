import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { Linking, Text, TextInput, View } from "react-native";
import { SelectList } from "react-native-dropdown-select-list";
import { FONTSIZE } from "../../Global";
import InfoCard from "../../components/InfoCard";
import MainButton from "../../components/MainButton";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { showFlowMessage } from "../../components/FlowMessage";
import { Audio } from "expo-av";
import { useLanguage } from "../../context/Language/LanguageProvider";
import apiCall from "../../utils/apiCall";

async function SoundSuccess() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../../assets/sounds/soundssuccess.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}
async function SoundError() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../../assets/sounds/soundserror.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

const DepositWithCrypto = (props: any) => {
  const Crypto = props.route.params.Crypto;
  const { words } = useLanguage();
  const { colors } = useTheme();
  const { user } = useContext(AuthContext);

  const [selectedCoin, setSelectedCoin] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState();

  const [networkData, setNetworkData] = useState<Array<any>>([]);
  const coinData = Crypto.map((item: any) => {
    return {
      key: item.id,
      value: item.title,
      depositNumber: item.depositPhone,
    };
  });
  console.log(coinData);

  useEffect(() => {
    if (selectedCoin) {
      const tempNetworkData = Crypto.find(
        (item: any) => item.id == selectedCoin
      )?.currencies.map((item: any) => {
        return { value: item.currency, key: item.network };
      });
      setNetworkData(tempNetworkData || []);
    }
  }, [selectedCoin]);

  const [price, setPrice] = useState("0");

  console.log("networkData", networkData);
  function WhatsappMessageSend() {
    if (!price || isNaN(Number(price))) {
      SoundError();
      showFlowMessage({
        message: words["FlowMessage_InvalidPrice"],
        type: "error",
        duration: 1000,
      });
      return;
    } else if (price == "0") {
      SoundError();
      showFlowMessage({
        message: words["FlowMessage_InvalidPrice"],
        type: "error",
        duration: 1000,
      });
      return;
    }

    const type = 2;
    const subject = `Para Yatırma Talebi - Hesap ID: ${user?.ID} - ${user?.Name}`;
    const message = `
Sayın Yatırım Uzmanı,

İlgili Hesap Sahibi: ${user?.Name}
Hesap ID: ${user?.ID ?? user?.ID}
İşlem Tarihi: ${dayjs().startOf("second").format("DD/MM/YYYY HH:mm")}

Yatırımcımız ${user?.Name}, kripto üzerinden ${dayjs()
      .startOf("second")
      .format("DD/MM/YYYY HH:mm")} tarihinde ${Number(
      price
    )} ${selectedCoin} miktarında para yatırma talebinde bulunmuştur. Kripto para detayları aşağıdaki gibidir:

* Kripto Para Birimi: ${selectedCoin}
* Ağ: ${networkData.find((item: any) => item.key == selectedNetwork)?.value}
* Ağ Adresi: ${
      networkData.find((item: any) => item.key == selectedNetwork)?.key
    }
* Miktar: ${price} birim
		
Lütfen gerekli işlemleri gerçekleştirip talebin değerlendirilmesini sağlayınız.
		
Detaylı bilgi için lütfen yatırımcı iletişime geçiniz.
		
Yatırımcı Telefon Numarası: ${user?.Phone}
		


Bu Mesaj WinexMarkets Mobile Tarafından İletilmiştir.`;

    const datas = {
      price: Number(price),
      coin: selectedCoin,
      network: networkData.find((item: any) => item.key == selectedNetwork)?.value,
      networkAdress: networkData.find(
        (item: any) => item.key == selectedNetwork
      )?.key,
    };
    const body = {
      type: type,
      subject: subject,
      message: message,
      data: datas,
    };
    apiCall("/send-mail", body, "POST")
      .then((response) => {
        SoundSuccess();
        showFlowMessage({
          message: words["FlowMessage_Deposit"],
          type: "success",
          duration: 5000,
        });
        props?.navigation?.navigate("Home");
      })
      .catch((err) => {
        SoundError();
        showFlowMessage({
          message: words["FlowMessage_Error"],
          type: "error",
          duration: 5000,
        });
      });
  }

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        gap: 5,
        paddingHorizontal: 10,
        backgroundColor: colors.SCREEN_BACKGROUND,
      }}
    >
      <View
        style={{
          backgroundColor: colors.CARD_BACKGROUND1,
          borderRadius: 5,
          marginVertical: 5,
        }}
      >
        <Text
          style={{
            textAlign: "justify",
            paddingVertical: 8,
            paddingHorizontal: "5%",
            fontSize: FONTSIZE.m,
            color: colors.TEXT,
          }}
        >
          Size uygun kriptop paranın bilgilerini almak için lütfen fonlama
          yapmak istediğiniz kripto parayı, daha sonra ise ağ birimini seçin.
        </Text>
        <Text
          style={{
            textAlign: "justify",
            paddingVertical: 8,
            paddingHorizontal: "5%",
            fontSize: FONTSIZE.m,
            color: colors.TEXT,
          }}
        >
          Ağ birimi seçildikten sonra bilgilendirme kartından
          kopyalayabilirsiniz.
        </Text>
      </View>

      <View style={{ gap: 14, marginTop: "2%" }}>
        <SelectList
          setSelected={(val: any) => setSelectedCoin(val)}
          data={coinData}
          save="key"
          search={false}
          placeholder="Kripto Seçiniz"
          boxStyles={{ backgroundColor: colors.CARD_BACKGROUND1 }}
          inputStyles={{
            backgroundColor: colors.CARD_BACKGROUND1,
            color: colors.TEXT,
          }}
          dropdownStyles={{ backgroundColor: colors.CARD_BACKGROUND1 }}
          dropdownTextStyles={{ color: colors.TEXT }}
        />
        <SelectList
          setSelected={(val: any) => setSelectedNetwork(val)}
          data={networkData}
          save="key"
          search={false}
          placeholder="Ağ Seçiniz"
          notFoundText="Önce Kripto Seçiniz"
          boxStyles={{ backgroundColor: colors.CARD_BACKGROUND1 }}
          inputStyles={{
            backgroundColor: colors.CARD_BACKGROUND1,
            color: colors.TEXT,
          }}
          dropdownStyles={{ backgroundColor: colors.CARD_BACKGROUND1 }}
          dropdownTextStyles={{ color: colors.TEXT }}
        />
      </View>
      <View
        style={{
          display: selectedNetwork ? "flex" : "none",
          marginTop: "5%",
          gap: 10,
        }}
      >
        <InfoCard
          type="basic"
          args={{
            title: "Mesaj",
            value:
              "AÇIKLAMA ALANINA SADECE LOGIN ID YAZILACAKTIR. (BASKA BIR BILGI YAZILMASI HALINDE PARA IADE EDILECEKTIR.)",
          }}
        />
        <InfoCard
          titleStyle={{ display: "none" }}
          valueStyle={{ flex: 1 }}
          type="basicWithCopy"
          args={{
            title: "",
            value: networkData.find((item: any) => item.key == selectedNetwork)
              ?.key,
          }}
        />
        <View
          style={{
            width: "100%",
            height: 2,
            borderRadius: 10,
            backgroundColor: colors.CARD_BACKGROUND1,
          }}
        ></View>
        <View
          style={{
            width: "100%",
            backgroundColor: colors.CARD_BACKGROUND2,
            borderRadius: 5,
            flexDirection: "row",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Text style={{ flexBasis: "25%", color: colors.TEXT }}>Miktar</Text>
          <TextInput
            keyboardType="numeric"
            style={{ flexBasis: "60%", textAlign: "right", color: colors.TEXT }}
            onChangeText={(newText) => setPrice(newText)}
            defaultValue={price}
          />
          <Text
            style={{ flexBasis: "15%", textAlign: "right", color: colors.TEXT }}
          >
            {selectedCoin}
          </Text>
        </View>
        <View style={{ width: "100%" }}>
          <MainButton
            onPress={WhatsappMessageSend}
            text={"Yatırım Talebi Gönder"}
          />
        </View>
      </View>
    </View>
  );
};

export default DepositWithCrypto;
