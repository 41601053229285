import React from 'react';
import { Dimensions, Text, View } from 'react-native';
import { useTheme } from '../../context/Theme/ThemeProvider';
import { PADDING } from '../../Global';

export const InvestorWarningBox = () => {
	const {colors} = useTheme();

	return (
		<View style={{alignItems:'center', paddingVertical:PADDING.XS, backgroundColor:colors.INVESTOR_BACKGROUND, width:Dimensions.get('screen').width, alignSelf:'center'}}>
			<Text style={{letterSpacing:2, fontWeight:'700', color:colors.INVESTOR_TEXT}}>İzleyici Modundasınız</Text>
		</View>
	);
};
