import PriceFormat from '../PriceFormat';
import { IColors } from '../../Global';
import { Text } from 'react-native';
import { newDayjsWithFormat } from '../../utils/dateUtil';

export interface IBalanceFieldProps {
	id: number;
	name: string;
	field: string;
	renderCell?: (data: any, colors: IColors) => JSX.Element;
}
export const BalanceColumnsFields: IBalanceFieldProps[] = [
	// {id: 3, name: 'İşlem Tipi', field: 'Action'},
	{
		id: 1,
		name: 'Tarih',
		field: 'Time',
		renderCell: (data: any, colors: IColors) => {
			return <Text style={{ color: colors.TEXT, textAlign: 'right' }}>{newDayjsWithFormat(data?.Time * 1000)}</Text>;
		},
	},
	{ id: 2, name: 'Anlaşma', field: 'Ticket' },
	// {id: 4, name: 'Yön', field: 'Entry'},
	{
		id: 3,
		name: 'Fiyat',
		field: 'Profit',
		renderCell: (data: any, colors: IColors) => {
			return (
				<PriceFormat
					style={{ color: data?.Profit > 0 ? colors.PROFIT : colors.LOSS, textAlign: 'right' }}
					digits={data?.Digits}
					value={data?.Profit}
				/>
			);
		},
	},
	{
		id: 4,
		name: 'Type',
		field: 'Type',
	},
	{ id: 5, name: 'Yorum', field: 'Comment' },
];
