import {
  View,
  Text,
  StyleSheet,
  Image,
  ImageSourcePropType,
} from "react-native";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { IColors } from "../../Global";

// prop interface username, number, imageUrl

interface UserCardProps {
  username: string;
  loginID: string;
  number: string;
  imageSource: string;
  isProile?: boolean;
}
const UserCard = (props: UserCardProps) => {
  const { colors } = useTheme();
  const styles = Styles(colors);

  if (props.isProile) {
    return (
      <View style={styles.user}>
        <Image
          style={styles.avatar}
          source={props.imageSource as ImageSourcePropType}
        />
        <View style={styles.infoContainer}>
          <View style={styles.row}>
            <Text style={styles.name}>{props.username}</Text>
            <Text style={styles.login}>{props.loginID}</Text> 
          </View>

          <View style={styles.row}>
            <Text style={styles.phoneNumber}>{props.number}</Text>
          </View>
        </View>
      </View>
    );
  } else {
    return (
      <View style={styles.user}>
        <Image
          style={styles.avatar}
          source={props.imageSource as ImageSourcePropType}
        />
        <View style={styles.infoContainer}>
          <View style={styles.row}>
            <Text style={styles.name}>{props.username}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.phoneNumber}>{props.loginID}</Text>
          </View>
        </View>
      </View>
    );
  }
};

const Styles = (COLOR: IColors) =>
  StyleSheet.create({
    // user info section
    user: {
      flexDirection: "row",
      backgroundColor: COLOR.CARD_BACKGROUND1,
      gap: 10,
      padding: 10,
      borderRadius: 5,
      shadowOffset: { width: -2, height: 4 },
      shadowColor: COLOR.CARD_BACKGROUND2,
      shadowOpacity: 0.08,
      flex: 1,
      overflow: "hidden",
    },
    avatar: {
      width: 50,
      height: 50,
      borderRadius: 25,
      marginRight: 10,
    },
    infoContainer: {
      flex: 1,
      flexDirection: "column",
    },
    name: {
      flex: 1,
      fontWeight: "bold",
      color: COLOR.TEXT,
      alignSelf: "flex-start",
    },
    login: {
      fontSize: 14,
      fontWeight: "bold",
      color: COLOR.TEXT,
    },
    phoneNumber: {
      flex: 1,
      color: COLOR.TEXT2,
      alignSelf: "flex-end",
    },
    row: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingRight: "5%",
    },
  });

export default UserCard;
