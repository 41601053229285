import {View, Text, StyleSheet, StyleProp, TextStyle} from 'react-native';
import React from 'react';
// import SpinningNumbers from '@birdwingo/react-native-spinning-numbers';
import {FONTSIZE, IColors} from '../../Global';
import {useTheme} from '../../context/Theme/ThemeProvider';

interface IPriceFormat {
  value: number;
  digits: number;
  showCurrency?: boolean;
  textColor?: string;
  style?: StyleProp<TextStyle>;
}

const PriceFormat: React.FC<IPriceFormat> = ({value, digits, showCurrency = true, textColor, style}) => {
  const {colors} = useTheme();
  const styles = Styles(colors);

  const priceFormat = new Intl.NumberFormat('de-DE', {
    style: showCurrency ? 'currency' : 'decimal',
    currency: 'USD',
    minimumFractionDigits: digits?digits:2,
    maximumFractionDigits: digits?digits:5,
    useGrouping: true,
  });
  const formattedPrice = priceFormat.format(value).replace(/\./g, ' ').replace(',', '.');

  return <Text style={[styles.price, style, textColor ? {color: textColor} : null]}>{formattedPrice}</Text>;
};

export default PriceFormat;

const Styles = (colors: IColors) =>
  StyleSheet.create({
    price: {
      textAlign: 'right',
      fontSize: FONTSIZE.l,
      color: colors.TEXT,
      // fontFamily: ''
    },
  });
