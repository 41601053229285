import { useContext, useEffect, useRef } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { AntDesign, Entypo } from "@expo/vector-icons";

import LoginScreen from "./screens/Login";

import Home from "./screens/Home";
import Symbols from "./screens/Symbols";
import Transaction from "./screens/Transaction";
import Profile from "./screens/Profile";

import TradeScreen from "./screens/Trade";
import SideMenu from "./components/SideMenu";
import TransactionDetails from "./screens/TransactionDetails";
import WithdrawScreen from "./screens/Bank/Withdraw";
import CurrencySelectionScreen from "./screens/Bank/CurrencySelection";
import BankTransactionScreen from "./screens/Bank/Transaction";
import ChangePasswordScreen from "./screens/ChangePassword";
import SettingsScreen from "./screens/Settings/Settings";
import DepositSelectionScreen from "./screens/Bank/DepositSelection";
import DepositWithCashScreen from "./screens/Bank/DepositWithCash";
import DepositWithCryptoScreen from "./screens/Bank/DepositWithCrypto";
import CustomerComplaintScreen from "./screens/CustomerComplaint";
import TradingView from "./screens/TradingView";

import ReferenceScreen from "./screens/References/index";
import AddReferenceScreen from "./screens/References/AddReference";

import { AuthContext } from "./context/AuthContext";
import CustomHeader from "./components/CustomHeader";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AppState, Button, Pressable, View } from "react-native";
import { useTheme } from "./context/Theme/ThemeProvider";
import { useLanguage } from "./context/Language/LanguageProvider";
import { BottomTabs } from "./components/Router/BottomTabs";

const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();


const getUser = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem("user");
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

const Router = () => {
  const { colors } = useTheme();
  const { words } = useLanguage();
  const { isLoggedIn, user } = useContext(AuthContext);

  const appState = useRef(AppState.currentState);

  // useEffect(() => {
  //   console.log("AppState.currentState: ",AppState.currentState);

  // }, [AppState.currentState]);

  // useEffect(() => {
  //   const subscription = AppState.addEventListener('change', nextAppState => {
  //     if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
  //       getUser().then(data => {
  //         if (data) {
  //           // console.log('router DATA :', {login:data?.Login, pass:data?.Password});
  //           // console.log('router DATA :', data);
  //           checkLogin(data?.Login, data?.Password, true);
  //         }
  //       });
  //     }

  //     appState.current = nextAppState;
  //     // console.log('AppState', appState.current);
  //   });

  //   return () => {
  //     subscription.remove();
  //   };
  // }, []);

  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        {!isLoggedIn ? (
          <>
            {/* <Stack.Screen name="VersionErr" component={VersionErrScreen} /> */}
            <Stack.Screen
              name="Login"
              component={LoginScreen}
              options={{
                headerLeft: () => null,
                orientation: "portrait",
              }}
            />
          </>
        ) : (
          <>
            <Stack.Screen
              name="HomeScreen"
              component={MyDrawer}
              options={{
                title: "WinexMarkets",
              }}
            />
            <Stack.Screen
              name="TransactionDetails"
              options={{
                headerShown: true,
                title: words["Router_TransactionDetails"],
              }}
              component={TransactionDetails}
            />
            <Stack.Screen
              name="Trade"
              component={TradeScreen}
              options={{
                headerShown: true,
                title: words["Router_Trade"],
                header: Header,
              }}
            />
            <Stack.Screen
              name="TradingView"
              component={TradingView}
              options={({ route }) => ({
                headerShown: true,
                title: `${
                  (route?.params as { symbolName: string })?.symbolName
                } Detaylı Grafiği`,
                //orientation: 'landscape',
                orientation: "all",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              })}
            />
            <Stack.Screen
              name="DepositSelection"
              component={DepositSelectionScreen}
              options={{
                headerShown: true,
                headerBackTitleVisible: false,
                title: words["Router_DepositSelection"],
                headerTitleAlign: "center",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              }}
            />
            <Stack.Screen
              name="DepositWithCash"
              component={DepositWithCashScreen}
              options={{
                headerShown: true,
                headerBackTitleVisible: false,
                title: words["Router_DepositWithCash"],
                headerTitleAlign: "center",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              }}
            />
            <Stack.Screen
              name="Withdraw"
              component={WithdrawScreen}
              options={{
                headerShown: true,
                headerBackTitleVisible: false,
                title: words['Router_Withdraw'],
                headerTitleAlign: "center",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              }}
            />
            <Stack.Screen
              name="CurrencySelection"
              component={CurrencySelectionScreen}
              options={{
                headerShown: true,
                headerBackTitleVisible: false,
                title: words['Router_CurrencySelection'],
                headerTitleAlign: "center",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              }}
            />
            <Stack.Screen
              name="BankTransaction"
              component={BankTransactionScreen}
              options={{
                headerShown: true,
                headerBackTitleVisible: false,
                title: words['Router_BankTransaction'],
                headerTitleAlign: "center",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              }}
            />
            <Stack.Screen
              name="DepositWithCrypto"
              component={DepositWithCryptoScreen}
              options={{
                headerShown: true,
                headerBackTitleVisible: false,
                title: words['Router_DepositWithCrypto'],
                headerTitleAlign: "center",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              }}
            />
            {/* <Stack.Screen
              name="PersonalInformation"
              component={PersonalInformationScreen}
              options={{
                headerShown: true,
                headerBackTitleVisible:false,
                title: 'Kişisel Bilgilerim',
                headerTitleAlign: 'center',
                headerTitleStyle: {color: colors.TEXT},
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              }}
            /> */}
            <Stack.Screen
              name="ChangePassword"
              component={ChangePasswordScreen}
              options={{
                headerShown: true,
                headerBackTitleVisible: false,
                title: words['Router_ChangePassword'],
                headerTitleAlign: "center",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              }}
            />
            <Stack.Screen
              name="CustomerComplaint"
              component={CustomerComplaintScreen}
              options={{
                headerShown: true,
                headerBackTitleVisible: false,
                title: words['Router_CustomerComplaint'],
                headerTitleAlign: "center",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              }}
            />

            <Stack.Screen
              name="References"
              component={ReferenceScreen}
              options={({ navigation }) => ({
                headerShown: true,
                title: words['Router_References'],
                headerTitleAlign: "center",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerBackTitleVisible: false,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
                headerRight: () => (
                  <Pressable
                    onPress={() => navigation.push("AddReferences")}
                    style={{ paddingHorizontal: 5 }}
                  >
                    <Entypo
                      name="add-to-list"
                      size={32}
                      color={colors.HEADER_ICON}
                    />
                  </Pressable>
                ),
              })}
            />
            <Stack.Screen
              name="AddReferences"
              component={AddReferenceScreen}
              options={{
                headerShown: true,
                title: words['Router_AddReferences'],
                headerBackTitleVisible: false,
                headerTitleAlign: "center",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              }}
            />
            <Stack.Screen
              name="Settings"
              component={SettingsScreen}
              options={{
                headerShown: true,
                title: words["Router_Settings"],
                headerBackTitleVisible: false,
                headerTitleAlign: "center",
                headerTitleStyle: { color: colors.TEXT },
                headerTintColor: colors.TEXT,
                headerStyle: {
                  backgroundColor: colors.HEADER_BACKGROUND,
                },
              }}
            />
          </>
        )}
      </Stack.Navigator>
      {/* <FlashMessage position='top' /> */}
    </NavigationContainer>
  );
};

function MyDrawer() {
  return (
    <Drawer.Navigator
      drawerContent={(props) => <SideMenu {...props} />}
      screenOptions={{
        headerShown: false,
        drawerStyle: {
          backgroundColor: "transparent",
          width: "65%",
        },
        headerTitle: "",
      }}
    >
      <Drawer.Screen name="Feed" component={BottomTabs} />
    </Drawer.Navigator>
  );
}

interface IHeader {
  navigation: any;
  route: {
    params: {
      groupSymbol: {
        Symbol: {
          Symbol: string;
        };
      };
      isInvestor: boolean;
    };
  };
}

const Header = (_props: any) => {
  console.log("Header Props: ", _props);
  const props = _props as IHeader;
  const { colors } = useTheme();
  return (
    <CustomHeader
      {...props}
      extraButtons={[
        {
          icon: (
            <AntDesign name="barchart" size={24} color={colors.HEADER_ICON} />
          ),
          onPress: () =>
            props.navigation.push("TradingView", {
              symbolName: props.route.params.groupSymbol.Symbol.Symbol,
            }),
          symbol: props.route.params.groupSymbol.Symbol.Symbol,
        },
      ]}
    />
  );
};

export default Router;
