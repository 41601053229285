import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useFocusEffect } from "@react-navigation/native";
import { AuthContext } from "../../../context/AuthContext";
import apiCall from "../../../utils/apiCall";
import { newDayjsWithTodayFormat, now } from "../../../utils/dateUtil";
import socket from "../../../config/socket";
import SymbolListItemUI from "./SymbolListItemUI";
import { IGroupSymbol } from "../../../Global";

interface Props {
  groupSymbol: IGroupSymbol;
  backgroundColor: string;
  textColor: string;
  navigation: any;
}

const SymbolListItemLogic: React.FC<Props> = ({
  groupSymbol,
  backgroundColor,
  textColor,
  navigation,
}) => {
  const { user, setFavorite } = useContext(AuthContext);
  if (!user) {
    return;
  }
  const [isFavorited, setIsFavorited] = useState(false);
  const [lastTick, setLastTick] = useState({
    Bid: groupSymbol?.Symbol?.Tick?.Bid,
    Ask: groupSymbol?.Symbol?.Tick?.Ask,
    Datetime: groupSymbol?.Symbol?.Tick?.Datetime,
  });

  const socketClosed = useRef(false);
  const lastUpdate = newDayjsWithTodayFormat(lastTick?.Datetime * 1000);

  useFocusEffect(
    useCallback(() => {
      if (!groupSymbol.Symbol.Symbol) {
        console.log("Symbol not set!");
        return;
      }
      socket.on(groupSymbol.Symbol.Symbol, (data: any) => {
        console.log("SymbolListItem" + groupSymbol.Symbol.Symbol + ": ", data);
        groupSymbol.Symbol.Tick.Datetime = data.Datetime;
        groupSymbol.Symbol.Tick.Bid = data.Bid;
        groupSymbol.Symbol.Tick.Ask = data.Ask;
        setLastTick({ ...data });
      });

      return () => {
        if (!socketClosed.current) {
          socket.off(groupSymbol.Symbol.Symbol);
        }
      };
    }, [groupSymbol?.Symbol?.Symbol])
  );

  useFocusEffect(
    useCallback(() => {
      if (!user || !user.FavoriteSymbols) {
        return;
      }
      const isFavorited =
        user.FavoriteSymbols.indexOf(groupSymbol.Symbol.Symbol) > -1;
      setIsFavorited(isFavorited);
    }, [user, user?.FavoriteSymbols?.length])
  );

  const pushPopFavorite = () => {
    if (user?.IsInvestor) {
      return;
    }

    apiCall(
      "/set-favorite",
      {
        symbol: groupSymbol.Symbol.Symbol,
        isFavorited: !isFavorited,
      },
      "POST"
    )
      .then((response) => {
        setFavorite(groupSymbol.Symbol.Symbol, response.isFavorited);
        setIsFavorited(response.isFavorited);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigateToTrade = () => {
    socketClosed.current = true;
    socket.off(groupSymbol.Symbol.Symbol);
    navigation.navigate("Trade", {
      groupSymbol,
    });
  };

  return (
    <SymbolListItemUI
      backgroundColor={backgroundColor}
      textColor={textColor}
      isFavorited={isFavorited}
      onFavoritePress={pushPopFavorite}
      onTradePress={navigateToTrade}
      lastTick={lastTick}
      lastUpdate={lastUpdate}
      groupSymbol={groupSymbol}
      user={user}
    />
  );
};

export default SymbolListItemLogic;
