
export const getActionType = (Action: number) => {

  
  let actionName = '';

  switch (Action) {
    case 0:
      actionName = 'Alım';
      break;
    case 1:
      actionName = 'Satım';
      break;
    case 2:
      actionName = 'Limit Alım';
      break;
    case 3:
      actionName = 'Limit Satım';
      break;
    case 4:
      actionName = 'Alış Stop';
      break;
    case 5:
      actionName = 'Satış Stop';
      break;
    case 6:
      actionName = 'Alış Stop Limit';
      break;
    case 7:
      actionName = 'Satış Stop Limit';
      break;
    case 8:
      actionName = 'Siparişe Göre Kapat';
      break;
    default:
      actionName = '';

      break;
  }
  return actionName;
};

export const getActionColor = (Action: number, COLOR:any) => {
  let actionColor = '';

  switch (Action) {
    case 0:
      actionColor = COLOR.BUY;
      break;
    case 1:
      actionColor = COLOR.SELL;
      break;
    case 2:
      actionColor = COLOR.BUY;
      break;
    case 3:
      actionColor = COLOR.SELL;
      break;
    case 4:
      actionColor = COLOR.BUY;
      break;
    case 5:
      actionColor = COLOR.SELL;
      break;
    case 6:
      actionColor = COLOR.BUY;
      break;
    case 7:
      actionColor = COLOR.SELL;
      break;
    default:
      actionColor = COLOR.NATURAL;
      break;
  }

  return actionColor;
};


export const getActionTypeWithName = (Action: string) => {
  let actionName = '';

  switch (Action) {
    case 'buy-order':
      actionName = 'Trade_BuyOrder';
      break;
    case 'sell-order':
      actionName = 'Trade_SellOrder';
      break;
    case 'buy-limit':
      actionName = 'Trade_BuyLimit';
      break;
    case 'sell-limit':
      actionName = 'Trade_SelllLimit';
      break;
    case 'buy-stop':
      actionName = 'Trade_BuyStop';
      break;
    case 'sell-stop':
      actionName = 'Trade_SellStop';
      break;
    default:
      actionName = 'Trade_Create';

      break;
  }

  return actionName;
};