interface ILanguage {
	[key: string]: string;
}

export const Turkish: ILanguage = {
	// General ==>
	Exit: 'Çıkış Yap',
	Login: 'Giriş Yap',
	LoginID: 'ID',
	UserName: 'Kullanıcı Adı',
	Password: 'Şifre',
	Buy: 'Al',
	Sell: 'Sat',
	Profit: 'Kâr',
	Loss: 'Zarar',
	Home: 'Ana Sayfa',
	Markets: 'Piyasalar',
	Transaction: 'İşlemlerim',
	Profile: 'Profil',
	Spread: 'Spread',
	Search: 'Ara',
	Name: 'İsim',
	FirstName: 'İsim',
	LastName: 'Soyisim',
	Email: 'Email',
	Phone: 'Telefon',
	Amount: 'Miktar',
	Button_SubmitDepositRequest: 'Yatırım Talebi Gönder',
	Button_SubmitWithdrawRequest: 'Para Çekim Talebi Gönder',
	Button_SubmitYourComplaint: 'Şikayetini İletin',
	Position: 'Pozisyon',
	Order: 'Emir',
	// <== General
	// Login ==>
	Login_RegisteredAccounts: 'Kayıtlı Hesaplar',
	Login_Servers: 'Sunucular',
	Login_NoRegisteredAccounts: 'Kayıtlı hesabınız bulunmamaktadır.',
	Login_ToolTip_1: 'Giriş ekranı yenilendi.',
	Login_ToolTip_2: `Kullanıcı adı ve şifreyi girdikten sonra 'Giriş Yap' butonuna bastığınız zaman giriş yaparsınız ve giriş bilgilerinizi kaydederiz.`,
	Login_ToolTip_3: `Eğer 'Giriş Yap' butonuna basılı tutarsanız Giriş bilgilerinizi KAYDETMEDEN giriş yaparsınız.`,
	Login_ToolTip_4: `Kayıtlı kullanıcılarınız olduğunda istediğiniz kullanıcıyla giriş yapabilirsiniz.`,
	Login_ToolTip_5: `Eğer kayıtlı kullanıcıya basılı tutarsanız basılı tuttuğunuz hesabı kaydedilenlerden kaldırırsınız.`,
	Login_ToolTip_6: `Buradan bir sunucu seçmeniz gerekmektedir.`,
	Login_ToolTip_7: `Giriş yaparken özellikle seçili sunucuya dikkat etmeniz gerekmektedir.`,
	// <== Login
	// Side Menu ==>
	SideMenu_Deposit: 'Para Yatır',
	SideMenu_Withdraw: 'Para Çek',
	SideMenu_Notifications: 'Bildirimler',
	SideMenu_BankStatement: 'Hesap Özeti',
	SideMenu_Transaction: 'İşlem Geçmişi',
	SideMenu_ChangePassword: 'Şifre Değiştir',
	SideMenu_ChangePasswordForInvestor: 'İzleyici Şifresi Değiştir',
	SideMenu_Traders: 'Yatırımcılar',
	SideMenu_Robots: 'Robotlar',
	SideMenu_LiveSupport: 'Canlı Destek',
	SideMenu_CustomerComplaint: 'Müşteri Şikayet',
	SideMenu_Settings: 'Ayarlar',
	SideMenu_Share: 'Arkadaşlarınla Paylaş',
	SideMenu_References: 'Referanslar',
	// <== Side Menu
	// Tabs ==>
	Favorites: 'Favoriler',
	Forex: 'Forex',
	Crypto: 'Kripto',
	Indices: 'Endeksler',
	Metal: 'Metaller',
	Energy: 'Enerji',
	Stock: 'Hisseler',
	History: 'Geçmiş',
	Positions: 'Açık Pozisyonlar',
	Orders: 'Açık Emirler',
	// <== Tabs
	// Home Screen ==>
	Home_RecommendedActions: 'Günün Önerilen İşlemleri',
	Home_Announcement: 'Takip etmek istediğiniz pariteyi yıldızlayabilirsiniz.',
	Home_ProminentInvestors: 'Öne Çıkan Yatırımcılar',
	// <== Home Screen
	// Router ==>
	Router_Login: 'Giriş',
	Router_TransactionDetails: 'İşlemlerim',
	Router_Trade: 'Trade',
	Router_TradingView: 'Detaylı Grafiği',
	Router_DepositSelection: 'Para Yatır',
	Router_DepositWithCash: 'Havale İle Para Yatır',
	Router_Withdraw: 'Havale ile Para Çek',
	Router_CurrencySelection: 'Havale İle Para Yatır',
	Router_BankTransaction: 'Havale İle Para Yatır',
	Router_DepositWithCrypto: 'Kripto Para İle Para Yatır',
	Router_ChangePassword: 'Şifre Değiştir',
	Router_ChangePasswordForInvestor: 'İzleyici Şifresi Değiştir',
	Router_CustomerComplaint: 'Müşteri Şikayet',
	Router_References: 'Referanslar',
	Router_AddReferences: 'Referans Ekle',
	Router_Settings: 'Ayarlar',
	Router_Home: 'Anasayfa',
	Router_Profile: 'Profil',
	Router_Symbols: 'Piyasalar',
	Router_Transaction: 'İşlemlerim',
	// <== Router
	// Profile ==>
	Balance: 'Bakiye',
	Equity: 'Varlık',
	Credit: 'Kredi',
	Margin: 'Kullanılan Teminat',
	Margin_Free: 'Kalan Teminat',
	Margin_Level: 'Teminat Seviyesi',
	Share: 'Arkadaşlarınla Paylaş',
	Floating: 'Açık Pozisyon Kar/Zarar',
	UserCard_PhoneError: 'Telefon kayıtlı değil!',
	// <== Profile
	// Deposit Selection ==>
	DepositSelection_WithCash: 'Havale İle Para Yatır',
	DepositSelection_WithCrypto: 'Kripto Para İle Para Yatır',
	DepositSelection_Warning: 'Size uygun para yatırma yöntemini seçiniz.',
	// <== Deposit Selection
	// Deposit With Cash ==>
	DepositWithCash_Warning: 'Size uygun banka havalesi bilgilerini almak için lütfen fonlama yapmak istediğiniz bankayı, daha sonra ise döviz birimini seçin.',
	// <== Deposit With Cash
	// Currency Selection ==>
	CurrencySelection_Warning: 'Size uygun banka havalesi bilgilerini almak için lütfen fonlama yapmak istediğiniz bankayı, daha sonra ise döviz birimini seçin.',
	// <== Currency Selection
	// Deposit_Trasaction ==>
	DepositTransaction_BankName: 'Banka Adı',
	DepositTransaction_Name: 'Alıcı Adı',
	DepositTransaction_Currency: 'Döviz Birimi',
	DepositTransaction_IBAN: 'IBAN',
	DepositTransaction_Description: 'Açıklama',
	// <== Deposit_Trasaction
	// Deposit_With_Crypto ==>
	DepositWithCrypto_Warning_1: 'Size uygun kriptop paranın bilgilerini almak için lütfen fonlama yapmak istediğiniz kripto parayı, daha sonra ise ağ birimini seçin.',
	DepositWithCrypto_Warning_2: 'Ağ birimi seçildikten sonra bilgilendirme kartından kopyalayabilirsiniz.',
	DepositWithCrypto_SelectCrypto: 'Kripto Seçiniz',
	DepositWithCrypto_SelectNetwork: 'Ağ Birimi Seçiniz',
	DepositWithCrypto_SelectNetwork_Warning: 'Önce Bir Kripto Seçiniz',
	DepositWithCrypto_Message: 'Mesaj',
	// <== Deposit_With_Crypto
	// Customer Complaint ==>
	CustomerComplaint_Message: 'Mesajınız',
	CustomerComplaint_Warning: 'Sorununuzu aşağıdaki alana yazarak bize iletebilirsiniz. Whatsapp üzerinden iletilecektir.',
	// <== Customer Complaint
	// Settings ==>
	Settings_Language: 'Dil',
	Settings_SearchLanguage: 'Dil Arayın...',
	Settings_DarkMode: 'Karanlık Mod',
	Settings_Notifications: 'Bildirimler',
	// <== Settings
	// Trade ==>
	Trade_AdditionalTransactions: 'Ek Pozisyonlar',
	Trade_MarketOrder: 'Market Limit',
	Trade_MarketClosed: 'Market Kapalı',
	Trade_TakeProfit: 'Kar Al',
	Trade_StopLoss: 'Zarar Durdur',
	Trade_Create: 'Oluştur',
	Trade_BuyOrder: 'Alış Emri Oluştur',
	Trade_SellOrder: 'Satış Emri Oluştur',
	Trade_BuyLimit: 'Limit Alış Oluştur',
	Trade_SelllLimit: 'Limit Satış Oluştur',
	Trade_BuyStop: 'Alış Stop Oluştur',
	Trade_SellStop: 'Satış Stop Oluştur',
	TradeStatus_0: 'Market Kapalı',
	TradeStatus_1: 'Sadece Alış İşlemi Yapılabilir',
	TradeStatus_2: 'Sadece Satış İşlemi Yapılabilir',
	TradeStatus_3: 'Yalnızca Kapatma İşlemi Yapılabilir',
	TradeStatus_4: 'İşleme Açık',
	TradeStatus_5: 'Kotasyon Bekleniyor',
	Trade_Type_0: 'Alım',
	Trade_Type_1: 'Satım',
	Trade_Type_2: 'Limit Alım',
	Trade_Type_3: 'Limit Satım',
	Trade_Type_4: 'Alış Stop',
	Trade_Type_5: 'Satış Stop',
	Trade_Type_6: 'Alış Stop Limit',
	Trade_Type_7: 'Satış Stop Limit',
	Trade_Type_8: 'Siparişe Göre Kapat',
	// <== Trade
	// MyTransactions Positions ==>
	Positions_TimeCreate: 'Tarih',
	Positions_Position: 'Pozisyon',
	Positions_VolumeCurrent: 'Lot',
	Positions_PriceCurrent: 'Mevcut Fiyat',
	Positions_PriceSL: 'Zarar Durdur',
	Positions_PriceTP: 'Kar Al',
	Positions_Swap: 'Swap',
	Positions_Profit: 'Kar/Zarar',
	Position_PartialClose: 'Parçalı Kapat',
	Position_ClosePosition: 'Kapat',
	Position_ModifyPosition: 'Düzelt',
	// <== MyTransactions Positions
	// MyTransactions PositionsModify ==>
	ModifyPosition_Title: 'Pozisyon Düzelt',
	ModifyPosition_Position: 'Pozisyon No',
	ModifyPosition_PriceCurrent: 'Güncel Fiyat',
	ModifyPosition_PriceSL: 'Zarar Durdur',
	ModifyPosition_PriceTP: 'Kar Al',
	ModifyPosition_Accept: 'Onayla',
	ModifyPosition_Cancel: 'Vazgeç',
	// <== MyTransactions PositionsModify
	// MyTransactions OrderModify ==>
	ModifyOrder_Title: 'Emri Düzelt',
	ModifyOrder_Order: 'Emir No',
	ModifyOrder_PriceCurrent: 'Güncel Fiyat',
	ModifyOrder_PriceOrder: 'Sipariş Fiyatı',
	ModifyOrder_PriceSL: 'Zarar Durdur',
	ModifyOrder_PriceTP: 'Kar Al',
	ModifyOrder_LastTime: 'Sona Erme Tarihi',
	ModifyOrder_LastTimeMessage: 'Emir kapanış tarihi seçiniz.',
	ModifyOrder_Accept: 'Onayla',
	ModifyOrder_Cancel: 'Vazgeç',
	ModifyOrder_LastTimeOk: 'Tamam',
	ModifyOrder_LastTimeCancel: 'İptal',
	// <== MyTransactions OrderModify
	// MyTransactions Orders ==>
	Orders_Order: 'Emir No',
	Orders_Lot: 'Lot',
	Orders_PriceOrder: 'Fiyat',
	Orders_PriceSL: 'Zarar Durdur',
	Orders_PriceTP: 'Kar Al',
	Orders_PriceCurrent: 'Mevcut Fiyat',
	Orders_Reason: 'Sebep',
	Orders_Comment: 'Yorum',
	Orders_DeleteOrder: 'Emri Sil',
	Orders_ModifyOrder: 'Düzenle',
	Orders_Type_0: 'Customer',
	Orders_Type_1: 'Expert',
	Orders_Type_2: 'Seller',
	Orders_Type_3: 'SL',
	Orders_Type_4: 'TP',
	Orders_Type_5: 'SO',
	Orders_Type_6: 'Rounding',
	Orders_Type_7: 'External Customer',
	Orders_Type_8: 'V Tolerance',
	// <== MyTransactions Orders
	// Symbol Properties ==>
	SymbolProperties_SwapRates: 'Swap Oranları',
	SymbolProperties_Sessions: 'Sessions',
	SymbolProperties_SessionsTrade: 'Trade',
	SymbolProperties_ContractSize: 'Kontrat Büyüklüğü',
	SymbolProperties_Spread: 'Spread',
	SymbolProperties_StopsLevel: 'Stops Level',
	SymbolProperties_CurrencyMargin: 'Marjin Para Birimi',
	SymbolProperties_VolumeMin: 'Minimum Hacim',
	SymbolProperties_VolumeMax: 'Maximum Hacim',
	SymbolProperties_Monday: 'Pazartesi',
	SymbolProperties_Tuesday: 'Salı',
	SymbolProperties_Wednesday: 'Çarşamba',
	SymbolProperties_Thursday: 'Perşembe',
	SymbolProperties_Friday: 'Cuma',
	SymbolProperties_Saturday: 'Cumartesi',
	SymbolProperties_Sunday: 'Pazar',
	// <== Symbol Properties
	// History ==>
	History_Button: 'Filtrele',
	History_Positions: 'Kapanan Pozisyonlar',
	History_Balance: 'Bakiye Geçmişi',
	History_MyTransactions: 'İşlemlerim',
	History_DatePicker_StartTitle: 'Başlangıç Tarihi Seçiniz',
	History_DatePicker_EndTitle: 'Bitiş Tarihi Seçiniz',
	History_DatePicker_Ok: 'Tamam',
	History_DatePicker_Cancel: 'İptal',
	History_NoData: 'Gösterilecek geçmiş yok.',
	// <== History
	// History Positions ==>
	HistoryPositions_Symbol: 'Sembol',
	// HistoryPositions_Deal: 'Anlaşma',
	HistoryPositions_PositionID: 'Pozisyon',
	HistoryPositions_Volume: 'Lot',
	HistoryPositions_Price: 'Açılış Fiyatı',
	HistoryPositions_PricePosition: 'Kapanış Fiyatı',
	HistoryPositions_PriceSL: 'Zarar Durdur',
	HistoryPositions_PriceTP: 'Kar Al',
	HistoryPositions_Swap: 'Swap',
	HistoryPositions_Profit: 'Kar/Zarar',
	HistoryPositions_Commission: 'Masraflar',
	HistoryPositions_Action_0: 'Satım',
	HistoryPositions_Action_1: 'Alım',
	HistoryPositions_Action_2: 'Limit Satım',
	HistoryPositions_Action_3: 'Limit Alım',
	HistoryPositions_Action_4: 'Satış Stop',
	HistoryPositions_Action_5: 'Alış Stop',
	HistoryPositions_Action_6: 'Satış Stop Limit',
	HistoryPositions_Action_7: 'Alış Stop Limit',
	HistoryPositions_Action_8: 'Siparişe Göre Kapat',
	// <== History Positions
	// History Balance ==>
	HistoryBalance_Time: 'Tarih',
	HistoryBalance_Ticket: 'Anlaşma',
	HistoryBalance_Profit: 'Fiyat',
	HistoryBalance_Type: 'Tip',
	HistoryBalance_Comment: 'Yorum',
	HistoryBalance_Action_2: 'Balance',
	HistoryBalance_Action_3: 'Credit',
	HistoryBalance_Action_4: 'Additional charges/withdrawals',
	HistoryBalance_Action_5: 'Correcting',
	HistoryBalance_Action_6: 'Bonuses',
	HistoryBalance_Action_7: 'Commission',
	// <== History Balance
	// Personal Information ==>
	// <== Personal Information
	// Change Password ==>
	ChangePassword_CurrentPassword: 'Mevcut Şifre',
	ChangePassword_NewPassword: 'Yeni Şifre',
	ChangePassword_ConfirmNewPassword: 'Yeni Şifre Tekrar',
	ChangePassword_Button: 'Kaydet',
	ChangePassword_Checker_UpperCase: 'Büyük Harf Kullanın',
	ChangePassword_Checker_LowerCase: 'Küçük Harf Kullanın',
	ChangePassword_Checker_Number: 'Rakam Kullanın',
	ChangePassword_Checker_SpecialChar: 'Özel Karakter Kullanın',
	ChangePassword_Checker_Length:'En az 6 karakter olmalı',
	ChangePassword_Checker_OriginalPassword: 'Eski Şifreniz Doğru Olmalı',
	ChangePassword_Checker_PasswordsMatch: 'Yeni Şifreler Eşleşmeli',
	// <== Change Password
	// References add ==>
	ReferencesAdd_Button: 'Referans Ekle',
	ReferencesAdd_Error: 'Referans Ekleme İşlemi Başarısız Oldu!\nLütfen Daha Sonra Tekrar Deneyiniz.',
	// <== References add
	// FlowMessage ==>
	FlowMessage_Timeout: 'İşlem sonrası cevap alınamadı.',
	FlowMessage_Success: 'İşlem başarıyla gerçekleşti.',
	FlowMessage_Error: 'İşlem yapılırken bir sorun oluştu. Daha sonra tekrar deneyin.',
	FlowMessage_InvalidPrice: 'Lütfen geçerli bir fiyat giriniz.',
	FlowMessage_InfoCardCopied: 'Değeri Koypalandı',
	FlowMessage_UnderConstruction: 'Geçiş yapmak istediğiniz sayfa yapım aşamasındadır.',
	FlowMessage_InvalidLogin: 'Kullanıcı adı veya şifre hatalı.',
	FlowMessage_LoginSuccess: 'Giriş yapıldı. Hoşgeldiniz',
	FlowMessage_LoginError: 'Giriş yapılırken bir sorun oluştu! Lütfen daha sonra tekrar deneyiniz.',
	FlowMessage_UnfilledFieldWarning: 'Lütfen tüm alanları doldurunuz.',
	FlowMessage_ModifySuccess: 'Düzenleme işleminiz başarıyla gerçekleşmiştir.',
	FlowMessage_PositionClose: 'Pozisyon başarıyla kapatıldı.',
	FlowMessage_OrderDelete: 'Emir başarıyla silindi.',
	FlowMessage_PositionCloseRejected: 'Pozisyon kapatma başarısız.',
	FlowMessage_OrderDeleteRejected: 'Emir silme başarısız.',
	FlowMessage_Login_InvalidInformation: 'Eksik yada hatalı giriş Bilgisi.',
	FlowMessage_Login_GroupNotFound: 'Grup bulunamadı.',
	FlowMessage_Login_CUN_Error: 'Giriş yapabilmek için güncel uygulamayı indirmeniz gerekmektedir.',
	FlowMessage_Logout_N: 'Başarıyla çıkış yaptınız.',
	FlowMessage_Logout_R: 'Çıkış yaptınız. Yeniden giriş yapılıyor...',
	FlowMessage_Logout_0: 'Sunucu üzerindeki bir bakım işlemi sebebebiyle çıkış yaptırıldınız.',
	FlowMessage_Logout_1: 'Bağlantınız koptuğu için çıkış yaptırıldınız.',
	FlowMessage_Logout_UserDeleted: 'Kullanıcı silindiği için çıkış yaptırıldınız.',
	FlowMessage_Logout_DataSourceIsNotReady: 'Oturumunuz sona erdi. Lütfen tekrar giriş yapın.',
	FlowMessage_Logout_LoginFromAnotherDevice: 'Başka bir cihazdan giriş yapıldı. Çıkış yaptırıldınız.',
	FlowMessage_Logout_GroupChanged: 'Bağlantınız koptuğu için çıkış yaptırıldınız. Tekrar giriş yapınız.',
	FlowMessage_Logout_ConnectionFailed: 'Bağlantınız koptuğu için çıkış yaptırıldınız. Tekrar giriş yapınız.',
	FlowMessage_ChangeInformationSuccess: 'Bilgileriniz Güncellendi. \nGiriş Ekranına yönlendiriliyorsunuz.',
	FlowMessage_ChangeInformationError: 'Bir Sorun Meydana Geldi. Şifreniz değiştirilemedi. Daha Sonra Tekrar Deneyiniz.',
	FlowMessage_CustomerComplaintSuccess: 'Şikayetiniz İletildi.',
	FlowMessage_InvestorError: 'Investor hesabında işlem yapamazsınız.',
	FlowMessage_SymbolNotFound: 'Sembol Bilgisi Alınamadı',
	FlowMessage_Trade_Waiting: 'Emir işleniyor lütfen bekleyiniz...',
	FlowMessage_Trade_InvalidOrderType: 'Geçersiz emir formatı.',
	FlowMessage_Trade_InvalidRequestType: 'Geçersiz istek formatı.',
	FlowMessage_Trade_InvalidVolume: 'Geçersiz hacim.',
	FlowMessage_Trade_InvalidPrice: 'Geçersiz fiyatı.',
	FlowMessage_Trade_Buy_MarketLimit: 'Market Limit değeri alış fiyatından küçük veya eşit olamaz',
	FlowMessage_Trade_Buy_StopLoss: 'Zarar Durdur değeri alış fiyatından büyük veya eşit olamaz',
	FlowMessage_Trade_Buy_TakeProfit: 'Kar Al değeri alış fiyatından küçük veya eşit olamaz',
	FlowMessage_Trade_Sell_MarketLimit: 'Market Limit değeri satış fiyatından küçük veya eşit olamaz',
	FlowMessage_Trade_Sell_StopLoss: 'Zarar Durdur değeri satış fiyatından küçük veya eşit olamaz',
	FlowMessage_Trade_Sell_TakeProfit: 'Kar Al değeri satış fiyatından büyük veya eşit olamaz',
	FlowMessage_Deposit: 'Para yatırma bildirimini yaptıktan sonra Yatırım uzmanınıza bilgi veriniz.',
	FlowMessage_Withdraw: 'Para çekme bildirimini yaptıktan sonra Yatırım uzmanınıza bilgi veriniz.',
	FlowMessage_PhoneError: 'Telefon numarası hatalı!\nTelefon numaranız sadece rakamlardan oluşmalıdır.',
	FlowMessage_EmailError: 'Email adresi hatalı!\nLütfen geçerli bir email adresi giriniz.',
	FlowMessage_ReferenceAdded: 'Referans başarıyla eklendi.',
	FlowMessage_ReferenceAllReadyCollected: 'Bonusunuzu halihazırda aldınız!',
	FlowMessage_ReferenceUnderReview: 'Bonusunuz henüz onaylanmadı!',
	FlowMessage_ReferenceNotEnough: 'Bonus için yeterli referansınız bulunmamaktadır!',
	FlowMessage_InvalidComplaint: 'Lütfen geçerli bir şikayet giriniz.',
	// <== FlowMessage
	// Retcodes ==>
	Retcode_0: '',
	Retcode_10001: 'Talep yolda.',
	Retcode_10002: 'Talep kabul edildi.',
	Retcode_10003: 'Talep işleniyor.',
	Retcode_10004: 'Talebe yanıt olarak teklif yeniden alındı.',
	Retcode_10005: 'Talebe yanıt olarak fiyatlar verildi.',
	Retcode_10006: 'Talep reddedildi.',
	Retcode_10007: 'Talep iptal edildi.',
	Retcode_10008: 'Talep sonucunda bir sipariş verildi.',
	Retcode_10009: 'Talep tamamlandı.',
	Retcode_10010: 'Talep kısmen yerine getirildi.',
	Retcode_10011: 'Talep genel hatası.',
	Retcode_10012: 'Talep zaman aşımına uğradı.',
	Retcode_10013: 'Geçersiz talep.',
	Retcode_10014: 'Geçersiz hacim.',
	Retcode_10015: 'Geçersiz fiyat.',
	Retcode_10016: 'Hatalı stop seviyeleri veya fiyatı.',
	Retcode_10017: 'Ticaret devre dışı.',
	Retcode_10018: 'Piyasa kapalı.',
	Retcode_10019: 'Yeterli para yok.',
	Retcode_10020: 'Fiyat değişti.',
	Retcode_10021: 'Fiyat yok.',
	Retcode_10022: 'Geçersiz emir süresi.',
	Retcode_10023: 'Emir değiştirildi.',
	Retcode_10024: "Çok fazla ticaret talebi. Örneğin, bir Yönetici API örneğinden 128'den fazla ticaret talebi göndermeye çalışıldığında bu hata dönebilir.",
	Retcode_10025: 'Talep değişiklik içermiyor.',
	Retcode_10026: 'Sunucuda otomatik ticaret devre dışı.',
	Retcode_10027: 'İstemci tarafında otomatik ticaret devre dışı.',
	Retcode_10028: 'Bayi tarafından engellenen talep.',
	Retcode_10029: 'Sipariş veya pozisyon piyasa ile yakın olduğu için değiştirme başarısız oldu.',
	Retcode_10030: 'Doldurma modu desteklenmiyor.',
	Retcode_10031: 'Bağlantı yok.',
	Retcode_10032: 'Yalnızca gerçek hesaplar için izin verilir.',
	Retcode_10033: 'Sipariş sayısı sınırına ulaşıldı.',
	Retcode_10034: 'Hacim sınırına ulaşıldı.',
	Retcode_10035: 'Geçersiz veya yasaklanmış emir türü.',
	Retcode_10036: 'Pozisyon zaten kapatıldı. Örneğin, bir pozisyonun stop seviyelerini değiştirmeye çalışırken bu hata ortaya çıkar.',
	Retcode_10037: 'Dahili amaçlar için kullanılır.',
	Retcode_10038: 'Kapatılacak hacim mevcut pozisyonun mevcut hacmini aşıyor.',
	Retcode_10039: 'Pozisyonu kapatmak için zaten bir emir mevcut. Hata, hedge modunda oluşur: Zaten bir pozisyonu kapatmak için karşıt bir pozisyon ile kapatmaya çalışırken veya mevcut bir pozisyonu kapatmanın tamamını veya bir kısmını kapatmaya çalışırken, pozisyonun mevcut hacmini aşan mevcut kapatma emirlerinin toplam hacmi ve yeni yerleştirilen emir.',
	Retcode_10040:
		'Bir hesapta eş zamanlı olarak açık bulunabilecek pozisyon sayısı, bir grup ayarlarıyla sınırlanabilir. Bir sınırına ulaşıldığında, bir sipariş yerleştirilmeye çalışıldığında sunucRetcode_EQUEST_LIMIT_POSITIONSdöner. Sınırlama, pozisyon hesaplama türüne bağlı olarak farklı şekilde işler: Netting - açık pozisyon sayısı dikkate alınır. Bir sınırına ulaşıldığında, platform, açık pozisyon sayısını artırabilecek işlemlerin yerleştirilmesini devre dışı bırakır. Aslında, platform yalnızca zaten açık pozisyonları olan semboller için sipariş yerleştirmeye izin verir. Mevcut bekleyen emirler, mevcut pozisyonlarda değişikliklere yol açabileceği halde, pozisyon sayısını artıramayacağından dikkate alınmaz. Hedging - bekleyen emirler, açık pozisyonlarla birlikte dikkate alınır, çünkü bekleyen emirin etkinleştirilmesi her zaman yeni bir pozisyon açılmasına yol açar. Bir sınırına ulaşıldığında, platform hem yeni pozisyonlar açmak için yeni piyasa emirlerini hem de bekleyen emirleri yerleştirmeyi devre dışı bırakır.',
	Retcode_10041: 'Talep reddedildi, emir iptal edildi. Bu kod, biryönlendirme kuralındaki IMTConRoute::ACTION_CANCEL_ORDER eylemi uygulandığında döndürülür.',
	Retcode_10042: 'İstek reddedildi, çünkü "Sadece uzun pozisyonlar izinli" kuralı sembol için ayarlandı (IMTConSymbol::TRADE_LONGONLY).',
	Retcode_10043: 'İstek reddedildi, çünkü "Sadece kısa pozisyonlar izinli" kuralı sembol için ayarlandı (IMTConSymbol::TRADE_SHORTONLY).',
	Retcode_10044: 'İstek reddedildi, çünkü "Yalnızca pozisyon kapatma izinli" kuralı sembol için ayarlandı (IMTConSymbol::TRADE_CLOSEONLY).',
	Retcode_10045: 'Pozisyon kapatma FIFO kuralına uygun değil. Bu, IMTConGroup::TRADEFLAGS_FIFO_CLOSE seçeneği etkinleştirilmiş gruplar için kullanılır. Bu seçenekle, tüm pozisyonlar sırasıyla kapatılmalıdır: en eski pozisyon önce kapatılır, ardından bir sonraki, vb.',
	Retcode_10046: 'Bir pozisyon açmak veya bekleyen bir emir yerleştirmek mümkün değil çünkü hedge pozisyonları yasaklandı. Hata IMTConGroup::TRADEFLAGS_HEDGE_PROHIBIT bayrağı grubu için etkinleştirildiğinde ve kullanıcının aynı sembol için zaten ters bir siparişi veya pozisyonu olduğunda bir kullanıcı bir işlem gerçekleştirmeye çalıştığında geri döner.',
	// <== Retcodes
};