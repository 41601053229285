import React, {useContext, useEffect, useState} from 'react';
import {Dimensions, Modal, SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import {FONTSIZE, IColors, IGroupSymbol, ISymbol} from '../../Global';
import InformationTable from './InformationTable';
import Sessions from './Session';
import SwapRates from './SwapRates';

import { AntDesign } from '@expo/vector-icons';
import {AuthContext} from '../../context/AuthContext';
import {useTheme} from '../../context/Theme/ThemeProvider';

interface Props {
  symbol: string;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const InfoModal: React.FC<Props> = ({isVisible, setIsVisible, symbol}) => {
  const {group} = useContext(AuthContext);
  const [data, setData] = useState<ISymbol>();
  const {colors} = useTheme();
  const styles = Styles(colors);

  useEffect(() => {
    if (symbol) {
      setData(group?.Symbols.find((item: IGroupSymbol) => item.Symbol.Symbol == symbol)?.Symbol);
    }
  }, [symbol]);

  return (
    <Modal
      visible={isVisible}
      transparent>
      <SafeAreaView style={{flex: 1}}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.popup}
          horizontal={false}>
          <Text style={styles.title}>{symbol}</Text>
          <Text style={styles.desc}>{data?.Description}</Text>
          {data ? (
            <>
              <InformationTable data={data} />
              {/* <MarginRate data={data}/> */}
              <SwapRates data={data} />
              <Sessions data={data} />
            </>
          ) : (
            <View>
              <Text style={{color: colors.TEXT}}>Yükleniyor...</Text>
            </View>
          )}
        </ScrollView>
        <TouchableOpacity
          style={{position: 'absolute', right: '8%', top: '11%'}}
          onPress={() => {
            setIsVisible(false);
          }}>
          <AntDesign name='closecircleo'
            size={30}
            color={colors.TEXT}
          />
        </TouchableOpacity>
        {/* <Text onPress={()=>{setIsVisible(false)}} style={{position:'absolute',textAlign:'center', backgroundColor:COLOR.DARK, color:COLOR.LIGHT, borderRadius:25, borderWidth:1, width:27, height:27, right:'8%', top:'11%',textShadowColor:COLOR.LIGHT2, textShadowRadius:9, fontSize:FONTSIZE.xxl}}>X</Text> */}
      </SafeAreaView>
    </Modal>
  );
};

export default InfoModal;

const Styles = (COLOR: IColors) =>
  StyleSheet.create({
    popup: {
      width: Dimensions.get('window').width * 0.9,
      //maxHeight: Dimensions.get('window').height * 0.9,
      position: 'absolute',
      top: '10%',
      bottom: '8%',
      alignSelf: 'center',
      backgroundColor: COLOR.CARD_BACKGROUND2,
      padding: 10,
      borderRadius: 10,
      shadowColor: 'black',
      shadowOpacity: 0.2,
      shadowRadius: 10,
      gap: 14,
      borderColor: COLOR.TEXT,
      borderWidth: 1,
    },
    infoItemWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      color: COLOR.TEXT,
      fontWeight: 'bold',
      fontSize: FONTSIZE.xl,
      textAlign: 'center',
      marginTop: 15,
    },
    desc: {
      color: COLOR.TEXT,
      fontWeight: 'bold',
      fontSize: FONTSIZE.l,
      textAlign: 'center',
      marginBottom: 20,
    },
    group: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 5,
    },
    key: {
      color: COLOR.TEXT,
      fontSize: FONTSIZE.l,
      fontWeight: 'bold',
    },
    value: {
      color: COLOR.TEXT,
      fontSize: FONTSIZE.l,
      fontWeight: '500',
    },
  });
