import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React, { useContext, useState } from "react";

import InfoModal from "../InfoModal";

interface IExtraButton {
  icon: JSX.Element;
  onPress: () => void;
}

import { useTheme } from "../../context/Theme/ThemeProvider";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AntDesign, Entypo, Feather, MaterialIcons } from "@expo/vector-icons";
import { AuthContext } from "../../context/AuthContext";

const CustomHeader = (props: any) => {
  const { navigation, extraButtons = [] } = props;
  const { darkMode, colors, setScheme } = useTheme();
  const styles = Styles(colors);
  const { logout } = useContext(AuthContext);
  const changeDarkMode = async () => {
    const dark = !darkMode;
    setScheme(dark);

    try {
      await AsyncStorage.setItem("darkMode", JSON.stringify(dark));
      console.log("DarkModeChanged: ", JSON.stringify(dark));
    } catch (e) {
      console.log("ChangeDarkModeError: \n", e);
    }
  };

  const [isVisible, setIsVisible] = useState(false);
  return (
    <View style={styles.wrapper}>
      {navigation.openDrawer ? (
        <TouchableOpacity
          style={styles.hamburgerWrapper}
          onPress={() => {
            navigation.openDrawer();
          }}
        >
          <Entypo name="menu" size={24} color={colors.HEADER_ICON} />
        </TouchableOpacity>
      ) : (
        props.back.title && (
          <AntDesign
            name="arrowleft"
            size={24}
            color={colors.HEADER_ICON}
            onPress={() => {
              navigation.goBack();
            }}
          />
        )
      )}
      <View>
        <Text style={styles.brandName}>
          {props.options.title || props.route.name}
        </Text>
      </View>
      <View style={styles.extraButtons}>
        {extraButtons.map((item: IExtraButton, index: number) => (
          <TouchableOpacity
            key={index}
            style={styles.infoWrapper}
            onPress={() => item.onPress()}
          >
            {item.icon}
          </TouchableOpacity>
        ))}
        {props.extraButtons && (
          <TouchableOpacity
            style={styles.infoWrapper}
            onPress={() => setIsVisible(true)}
          >
            <Feather name="info" size={24} color={colors.HEADER_ICON} />
            <InfoModal
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              symbol={props.extraButtons[0].symbol}
            />
          </TouchableOpacity>
        )}
        {navigation.openDrawer && (
          <TouchableOpacity
            style={styles.hamburgerWrapper}
            onPress={() => {
              changeDarkMode();
            }}
          >
            {darkMode ? (
              <Feather name="moon" size={24} color={colors.LAMB} />
            ) : (
              <Feather name="sun" size={24} color={colors.LAMB} />
            )}
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default CustomHeader;

const Styles = (COLOR: any) =>
  StyleSheet.create({
    wrapper: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: COLOR.HEADER_BACKGROUND,
      padding: 10,
    },
    hamburgerWrapper: {
      padding: 4,
      color: COLOR.HEADER_ICON,
    },
    brandName: {
      fontSize: 18,
      fontWeight: "bold",
      color: COLOR.TEXT,
      textAlign: "center",
    },
    infoWrapper: {
      padding: 4,
    },
    extraButtons: {
      flexDirection: "row",
      gap: 5,
    },
  });
