import {useContext, useEffect, useState} from 'react';
import {Dimensions, Pressable, ScrollView, StyleSheet, Text, View} from 'react-native';

// components
import ReferenceCard from '../../components/ReferenceCard';
import {AuthContext} from '../../context/AuthContext';
import {useTheme} from '../../context/Theme/ThemeProvider';
import apiCall from '../../utils/apiCall';
import {IColors} from '../../Global';

import { Feather } from '@expo/vector-icons';
import { showFlowMessage } from '../../components/FlowMessage';
import { useLanguage } from '../../context/Language/LanguageProvider';

interface IReference {
  Name: string;
  Surname: string;
  Phone: string;
  Email: string;
  isApprovedType: number;
  Reason: string;
}

// 0 => Onaylandı
// 1 => Onaylanmadı
// 2 => Reddedildi

const References = (props: any) => {
  const {colors} = useTheme();
  const {words} = useLanguage();
  const {user} = useContext(AuthContext);
  const styles = Styles(colors);

  const [referenceDatas, setReferenceDatas] = useState<Array<IReference>>([] as Array<IReference>);
  const [bonusCollected, setBonusCollected] = useState<boolean>(false);

  // const [data, setData] = useState<Array<IReference>>([
  //   {Name: 'Ömer', Surname: 'Baba', Phone: '905395739494', Email: 'nomail@nomail.com', isApprovedType: 0, Reason: 'Onaylandı'},
  //   {Name: 'Nimet', Surname: 'NimetSoyad', Phone: '+905616165509', Email: 'Nimet@nomail.com.', isApprovedType: 2, Reason: 'Telefon No Yanlış'},
  //   {Name: 'Hakan', Surname: 'HakanSoyad', Phone: '+905616125134', Email: 'Hakan@nomail.com.', isApprovedType: 1, Reason: 'İnceleniyor'},
  //   {Name: 'Mücahit', Surname: 'MücahitSoyad', Phone: '+905161800420', Email: 'Mücahit@nomail.com.', isApprovedType: 2, Reason: 'Telefon No Yanlış'},
  //   {Name: 'Esma', Surname: 'EsmaSoyad', Phone: '+905102917534', Email: 'Esma@nomail.com.', isApprovedType: 1, Reason: 'İnceleniyor'},
  //   {Name: 'Burhanettin', Surname: 'BurhanettinSoyad', Phone: '+905616171236', Email: 'Burhanettin@nomail.com.', isApprovedType: 1, Reason: 'İnceleniyor'},
  //   {Name: 'Nadiye', Surname: 'NadiyeSoyad', Phone: '+905169387169', Email: 'Nadiye@nomail.com.', isApprovedType: 1, Reason: 'İnceleniyor'},
  //   {Name: 'Oğuzhan', Surname: 'OğuzhanSoyad', Phone: '+905616104372', Email: 'Oğuzhan@nomail.com.', isApprovedType: 1, Reason: 'İnceleniyor'},
  //   {Name: 'Attila', Surname: 'AttilaSoyad', Phone: '+905616157962', Email: 'Attila@nomail.com.', isApprovedType: 2, Reason: 'Telefon No Yanlış'},
  //   {Name: 'Tülay', Surname: 'TülaySoyad', Phone: '+905247746557', Email: 'Tülay@nomail.com.', isApprovedType: 0, Reason: 'Onaylandı'},
  //   {Name: 'Erdem', Surname: 'ErdemSoyad', Phone: '+905616130783', Email: 'Erdem@nomail.com.', isApprovedType: 2, Reason: 'Telefon No Yanlış'},
  // ]);

  let approvedCount: number = 0;

  useEffect(() => {
    if (!user) {
      return;
    }
    apiCall(
      `/get-references`,
      {
        login: user?.ID,
      },
      'POST',
    )
      .then(data => {
        if (!data || data?.error) {
          setReferenceDatas([]);
        } else {
          setReferenceDatas(data as Array<IReference>);
        }
      })
      .catch(cppError => {
        console.log(cppError, ': CPP ERROR');
        setReferenceDatas([]);
      });
  }, [user]);

  useEffect(() => {
    approvedCount = 0;
    referenceDatas.map((item: IReference) => {
      if (item.isApprovedType == 0) approvedCount++;
    });
  }, [referenceDatas]);

  const getBonus = () => {};

  const bonusError = (type: number) => {
		if (type == 0) {
			showFlowMessage({
				message: words['FlowMessage_ReferenceAllReadyCollected'],
				type: 'success',
			});
		} else if (type == 1) {
			showFlowMessage({
				message: words['FlowMessage_ReferenceUnderReview'],
				type: 'warning',
			});
		} else if (type == 2) {
			showFlowMessage({
				message: words['FlowMessage_ReferenceNotEnough'],
				type: 'warning',
			});
		}
	};

  const getBonusButton = () => {
    if (bonusCollected) {
      return (
        <Pressable
          style={[styles.bonus, styles.bonusAvailable]}
          onPress={() => {
            bonusError(0);
          }}>
          <Feather name='award'
            style={styles.bonusAvailable}
            size={50}
          />
        </Pressable>
      );
    } else if (referenceDatas.length >= 5 && approvedCount < 5) {
      return (
        <Pressable
          style={[styles.bonus, styles.bonusPending]}
          onPress={() => {
            bonusError(1);
          }}>
          <Feather name='award'
            style={styles.bonusPending}
            size={50}
          />
        </Pressable>
      );
    } else if (approvedCount >= 5) {
      return (
        <Pressable
          style={[styles.bonus, styles.bonusAvailable]}
          onPress={() => {
            getBonus();
          }}>
          <Feather name='award'
            style={styles.bonusAvailable}
            size={50}
          />
        </Pressable>
      );
    } else {
      return (
        <Pressable
          style={[styles.bonus, styles.bonusUnavailable]}
          onPress={() => {
            bonusError(2);
          }}>
          <Feather name='award'
            style={styles.bonusUnavailable}
            size={50}
          />
        </Pressable>
      );
    }
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={[styles.container, {backgroundColor: colors.SCREEN_BACKGROUND}]}>
      {/*wallet cards*/}
      <View style={styles.cardsContainer}>
        {referenceDatas &&
          referenceDatas.map((item: IReference) => (
            <ReferenceCard
              key={item.Phone}
              Name={item.Name}
              Surname={item.Surname}
              Phone={item.Phone}
              Email={item.Email}
              isApprovedType={item.isApprovedType}
              Reason={item.Reason}
            />
          ))}
      </View>
      {getBonusButton()}
    </ScrollView>
  );
};

export default References;

const Styles = (colors: IColors) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: 10,
      flexDirection: 'column',
    },
    //card
    cardsContainer: {
      flexDirection: 'row',
      gap: 10,
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginVertical: 10,
    },
    //menu
    menuContainer: {
      flex: 1,
      marginTop: 10,
      gap: 10,
    },
    bonus: {
      position: 'absolute',
      top: Dimensions.get('screen').height / 1.6,
      alignSelf: 'center',
      shadowColor: colors.SHADOW3,
      shadowOpacity: 0.9,
      shadowRadius: 5,
      shadowOffset: {width: 0, height: 0},
      borderRadius: 100,
      padding: 20,

      alignItems: 'center',
    },

    bonusAccepted: {
      backgroundColor: colors.BONUS_COLLECTED,
      fill: colors.BONUS_COLLECTED_TEXT,
    },
    bonusAvailable: {
      backgroundColor: colors.BONUS_AVAILABLE,
      fill: colors.BONUS_AVAILABLE_TEXT,
    },
    bonusUnavailable: {
      backgroundColor: colors.BONUS_UNAVAILABLE,
      fill: colors.BONUS_UNAVAILABLE_TEXT,
    },
    bonusPending: {
      backgroundColor: colors.BONUS_PENDING,
      fill: colors.BONUS_PENDING_TEXT,
    },
  });
