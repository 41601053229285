export const getReasonColor = (Reason: number, COLOR:any) => {
  let reasonColor = '';

  switch (Reason) {
    case 3:
      reasonColor = COLOR.RED;
      break;
    case 4:
      reasonColor = COLOR.GREEN;
      break;
    case 5:
      reasonColor = COLOR.ORANGE;
      break;
    default:
      reasonColor = COLOR.GRAY;

      break;
  }

  return reasonColor;
};