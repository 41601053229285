interface IIcons {
	[key: string]: any;
}
const Icons:IIcons = {
	bank: require('../../assets/banks/bank.png'),
	crypto: require('../../assets/banks/crypto.png'),
	ziraat: require('../../assets/banks/ziraat.png'),
	yapi_kredi: require('../../assets/banks/yapi_kredi.png'),
	garantibbva: require('../../assets/banks/garantibbva.png'),
	akbank: require('../../assets/banks/akbank.png'),
	kuveyt_turk: require('../../assets/banks/kuveyt_turk.png'),
	halkbank: require('../../assets/banks/halkbank.png'),
	vakif: require('../../assets/banks/vakif.png'),
	isbank: require('../../assets/banks/isbank.png'),
	teb: require('../../assets/banks/teb.png'),
	ing: require('../../assets/banks/ing.png'),
	sekerbank: require('../../assets/banks/sekerbank.png'),
	denizbank: require('../../assets/banks/denizbank.png'),
	qnb_finansbank: require('../../assets/banks/qnb_finansbank.png'),
	hsbc: require('../../assets/banks/hsbc.png'),
	alternatifbank: require('../../assets/banks/alternatifbank.png'),
	anadolubank: require('../../assets/banks/anadolubank.png'),
	fibabanka: require('../../assets/banks/fibabanka.png'),
	turkishbank: require('../../assets/banks/turkishbank.png'),
	albaraka: require('../../assets/banks/albaraka.png'),
	albarakaturk: require('../../assets/banks/albaraka.png'),
	aktifbank: require('../../assets/banks/aktifbank.png'),
	citi: require('../../assets/banks/citi.png'),
	odea: require('../../assets/banks/odea.png'),
};
export { Icons };