import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { NoVisitorsAllowed } from "../../config/visitor";
import { showFlowMessage } from "../FlowMessage";
import { useLanguage } from "../../context/Language/LanguageProvider";
import * as Sharing from "expo-sharing";
import { RADIUS } from "../../Global";
interface MenuItemProps {
  route: string;
  name: string;
  Icon: any;
  isInvestor: boolean;
  navigation?: any;
  extraProp?: any;
  id?: number;
}

const routes = [
  "DepositSelection",
  "Withdraw",
  "Profile",
  "Transaction",
  "CustomerComplaint",
  "References",
  "ChangePassword",
  "Settings",
];

const index: React.FC<MenuItemProps> = ({
  route,
  name,
  isInvestor,
  Icon,
  extraProp,
  navigation,
}: MenuItemProps) => {
  if (isInvestor && NoVisitorsAllowed.includes(route)) {
    return <View style={{ display: "none" }}></View>;
  }
  const { colors } = useTheme();
  const { words } = useLanguage();
  const styles = Styles(colors);

  async function Share() {
    try {
      await Sharing.shareAsync("https://www.winexterminal.com/");
    } catch (error) {
      console.log("Share Error Log: ", error);
    }
  }
  const handlePress = async () => {
    try {
      if (routes.includes(route)) {
        navigation.navigate(route, extraProp);
      } else if ((route = "Share")) {
        const isSharingAvailable = await Sharing.isAvailableAsync();
        if (!isSharingAvailable) {
          throw new Error("Sharing is not available on this device");
        }
        Share();
      } else {
        showFlowMessage({
          message: words["FlowMessage_UnderConstruction"],
          type: "warning",
          duration: 1500,
        });
      }
    } catch (err) {
      showFlowMessage({
        message: words["FlowMessage_UnderConstruction"],
        type: "warning",
        duration: 1500,
      });
      console.log(err);
    }
    // }
  };

  return (
    <TouchableOpacity style={styles.menuItem} onPress={handlePress}>
      {Icon}
      <Text style={styles.menuText}>{words[`SideMenu_${name}`]}</Text>
    </TouchableOpacity>
  );
};

export default index;

const Styles = (COLOR: any) =>
  StyleSheet.create({
    menuItem: {
      flexDirection: "row",
      alignItems: "center",
      gap: 10,
      paddingHorizontal: 10,
      paddingVertical: 9,
      borderRadius: RADIUS.CARD,

      backgroundColor: COLOR.CARD_BACKGROUND1,
      color: COLOR.TEXT,
    },
    menuText: {
      fontSize: 16,
      color: COLOR.TEXT,
    },
  });
