import React, { FC } from 'react';
import { Text, View } from 'react-native';
import dayjs from 'dayjs';
import { useTheme } from '../../context/Theme/ThemeProvider';
import { newDayjs } from '../../utils/dateUtil';

interface Props {
	data: any;
}

const Sessions: FC<Props> = ({ data }: Props) => {
	const sessions = data.Sessions;
	const { colors } = useTheme();

	return (
		<View style={{ marginBottom: 20 }}>
			<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 7 }}>
				<Text style={{ flex: 2, fontWeight: 'bold', color: colors.TEXT, textAlign: 'left' }}>Session Start</Text>
				<Text style={{ flex: 2, fontWeight: 'bold', color: colors.TEXT, textAlign: 'right', marginBottom: 5 }}>Session End</Text>
			</View>
			<View>
				{sessions.map((sessionItem: any, index: number) => {
					return (
						<View
							style={{ flexDirection: 'row', justifyContent: 'space-between' }}
							key={index}
						>
							<Text style={{ flex: 2, color: colors.TEXT, textAlign: 'left' }}>{newDayjs(sessionItem.Open * 1000).format('dddd, HH:mm')}</Text>
							<Text style={{ flex: 2, color: colors.TEXT, textAlign: 'right' }}>{newDayjs(sessionItem.Close * 1000).format('dddd, kk:mm')}</Text>
						</View>
					);
				})}
			</View>
		</View>
	);
};

export default Sessions;
