interface ILanguage {
	[key: string]: string;
}

export const Russian: ILanguage = {
	Exit: 'Выход',
	Login: 'Войти',
	LoginID: 'ID',
	UserName: 'Имя пользователя',
	Password: 'Пароль',
	Buy: 'Купить',
	Sell: 'Продать',
	Profit: 'Прибыль',
	Loss: 'Убыток',
	Home: 'Главная',
	Markets: 'Рынки',
	Transaction: 'Мои сделки',
	Profile: 'Профиль',
	Spread: 'Спред',
	Search: 'Поиск',
	Name: 'Имя',
	FirstName: 'Имя',
	LastName: 'Фамилия',
	Email: 'Электронная почта',
	Phone: 'Телефон',
	Amount: 'Сумма',
	Button_SubmitDepositRequest: 'Отправить запрос на пополнение',
	Button_SubmitWithdrawRequest: 'Отправить запрос на вывод средств',
	Button_SubmitYourComplaint: 'Подать жалобу',
	Position: 'Позиция',
	Order: 'Ордер',
	Login_RegisteredAccounts: 'Зарегистрированные аккаунты',
	Login_Servers: 'Серверы',
	Login_NoRegisteredAccounts: 'Зарегистрированные аккаунты не найдены.',
	Login_ToolTip_1: 'Экран входа был обновлен.',
	Login_ToolTip_2: 'После ввода имени пользователя и пароля нажатие кнопки "Войти" входит в систему и сохраняет информацию о входе.',
	Login_ToolTip_3: 'Если вы нажмете и удерживаете кнопку "Войти", вы войдете без сохранения информации о входе.',
	Login_ToolTip_4: 'Когда у вас есть зарегистрированные пользователи, вы можете войти с любым из них.',
	Login_ToolTip_5: 'Если вы нажмете и удержите зарегистрированного пользователя, вы удалите нажатый аккаунт из сохраненных.',
	Login_ToolTip_6: 'Вы должны выбрать сервер отсюда.',
	Login_ToolTip_7: 'Особенно внимательно относитесь к выбранному серверу при входе в систему.',
	SideMenu_Deposit: 'Пополнение',
	SideMenu_Withdraw: 'Вывод средств',
	SideMenu_Notifications: 'Уведомления',
	SideMenu_BankStatement: 'Банковский отчет',
	SideMenu_Transaction: 'История транзакций',
	SideMenu_ChangePassword: 'Изменить пароль',
	SideMenu_ChangePasswordForInvestor: 'Изменить пароль для инвестора',
	SideMenu_Traders: 'Трейдеры',
	SideMenu_Robots: 'Роботы',
	SideMenu_LiveSupport: 'Онлайн поддержка',
	SideMenu_CustomerComplaint: 'Жалоба клиента',
	SideMenu_Settings: 'Настройки',
	SideMenu_Share: 'Поделиться с друзьями',
	SideMenu_References: 'Рефералы',
	Favorites: 'Избранное',
	Forex: 'Форекс',
	Crypto: 'Крипто',
	Indices: 'Индексы',
	Metal: 'Металлы',
	Energy: 'Энергия',
	Stock: 'Акции',
	History: 'История',
	Positions: 'Открытые позиции',
	Orders: 'Открытые ордера',
	Home_RecommendedActions: 'Рекомендуемые действия дня',
	Home_Announcement: 'Вы можете отметить пару, за которой хотите следить.',
	Home_ProminentInvestors: 'Известные инвесторы',
	Router_Login: 'Вход',
	Router_TransactionDetails: 'Мои сделки',
	Router_Trade: 'Торговля',
	Router_TradingView: 'Детальный график',
	Router_DepositSelection: 'Пополнение',
	Router_DepositWithCash: 'Пополнение через банковский перевод',
	Router_Withdraw: 'Вывод средств через банковский перевод',
	Router_CurrencySelection: 'Пополнение через банковский перевод',
	Router_BankTransaction: 'Пополнение через банковский перевод',
	Router_DepositWithCrypto: 'Пополнение с криптовалютой',
	Router_ChangePassword: 'Изменить пароль',
	Router_ChangePasswordForInvestor: 'Изменить пароль для инвестора',
	Router_CustomerComplaint: 'Жалоба клиента',
	Router_References: 'Рефералы',
	Router_AddReferences: 'Добавить рефералы',
	Router_Settings: 'Настройки',
	Router_Home: 'Главная',
	Router_Profile: 'Профиль',
	Router_Symbols: 'Рынки',
	Router_Transaction: 'Мои сделки',
	Balance: 'Баланс',
	Equity: 'Эквити',
	Credit: 'Кредит',
	Margin: 'Использованный маржа',
	Margin_Free: 'Свободная маржа',
	Margin_Level: 'Уровень маржи',
	Share: 'Поделиться с друзьями',
	Floating: 'Плавающая прибыль/убыток',
	UserCard_PhoneError: 'Номер телефона не зарегистрирован!',
	DepositSelection_WithCash: 'Пополнение через банковский перевод',
	DepositSelection_WithCrypto: 'Пополнение с криптовалютой',
	DepositSelection_Warning: 'Пожалуйста, выберите соответствующий метод пополнения.',
	DepositWithCash_Warning: 'Для получения соответствующей информации о банковском переводе, выберите банк, в который хотите внести средства, а затем выберите валюту.',
	CurrencySelection_Warning: 'Для получения соответствующей информации о банковском переводе, выберите банк, в который хотите внести средства, а затем выберите валюту.',
	DepositTransaction_BankName: 'Название банка',
	DepositTransaction_Name: 'Имя получателя',
	DepositTransaction_Currency: 'Валюта',
	DepositTransaction_IBAN: 'IBAN',
	DepositTransaction_Description: 'Описание',
	DepositWithCrypto_Warning_1: 'Для получения соответствующей информации о криптовалюте, выберите криптовалюту, которую хотите пополнить, а затем выберите сеть.',
	DepositWithCrypto_Warning_2: 'После выбора сетевой единицы вы можете скопировать ее из информационной карточки.',
	DepositWithCrypto_SelectCrypto: 'Выберите криптовалюту',
	DepositWithCrypto_SelectNetwork: 'Выберите сетевую единицу',
	DepositWithCrypto_SelectNetwork_Warning: 'Сначала выберите криптовалюту',
	DepositWithCrypto_Message: 'Сообщение',
	CustomerComplaint_Message: 'Ваше сообщение',
	CustomerComplaint_Warning: 'Вы можете передать свою проблему, написав ее в поле ниже. Она будет передана через WhatsApp.',
	Settings_Language: 'Язык',
	Settings_SearchLanguage: 'Поиск языка...',
	Settings_DarkMode: 'Темный режим',
	Settings_Notifications: 'Уведомления',
	Trade_AdditionalTransactions: 'Дополнительные транзакции',
	Trade_MarketOrder: 'Рыночный ордер',
	Trade_MarketClosed: 'Рынок закрыт',
	Trade_TakeProfit: 'Тейк-профит',
	Trade_StopLoss: 'Стоп-лосс',
	Trade_Create: 'Создать',
	Trade_BuyOrder: 'Создать ордер на покупку',
	Trade_SellOrder: 'Создать ордер на продажу',
	Trade_BuyLimit: 'Создать лимитный ордер на покупку',
	Trade_SelllLimit: 'Создать лимитный ордер на продажу',
	Trade_BuyStop: 'Создать стоп-ордер на покупку',
	Trade_SellStop: 'Создать стоп-ордер на продажу',
	TradeStatus_0: 'Рынок закрыт',
	TradeStatus_1: 'Разрешается только покупка',
	TradeStatus_2: 'Разрешается только продажа',
	TradeStatus_3: 'Разрешается только закрытие',
	TradeStatus_4: 'Торговля открыта',
	TradeStatus_5: 'Ожидание котировки',
	Trade_Type_0: 'Покупка',
	Trade_Type_1: 'Продажа',
	Trade_Type_2: 'Лимитная покупка',
	Trade_Type_3: 'Лимитная продажа',
	Trade_Type_4: 'Стоп-покупка',
	Trade_Type_5: 'Стоп-продажа',
	Trade_Type_6: 'Стоп-лимитная покупка',
	Trade_Type_7: 'Стоп-лимитная продажа',
	Trade_Type_8: 'Закрытие ордером',
	Positions_NoData: 'Нет открытых позиций для отображения.',
	Positions_TimeCreate: 'Дата',
	Positions_Position: 'Позиция',
	Positions_VolumeCurrent: 'Лот',
	Positions_PriceCurrent: 'Текущая цена',
	Positions_PriceSL: 'Стоп-лосс',
	Positions_PriceTP: 'Тейк-профит',
	Positions_Swap: 'Своп',
	Positions_Profit: 'Прибыль/убыток',
	Position_PartialClose: 'Частичное закрытие',
	Position_ClosePosition: 'Закрыть позицию',
	Position_ModifyPosition: 'Изменить',
	ModifyPosition_Title: 'Изменить позицию',
	ModifyPosition_Position: 'Номер позиции',
	ModifyPosition_PriceCurrent: 'Текущая цена',
	ModifyPosition_PriceSL: 'Стоп-лосс',
	ModifyPosition_PriceTP: 'Тейк-профит',
	ModifyPosition_Accept: 'Принять',
	ModifyPosition_Cancel: 'Отменить',
	ModifyOrder_Title: 'Изменить ордер',
	ModifyOrder_Order: 'Номер ордера',
	ModifyOrder_PriceCurrent: 'Текущая цена',
	ModifyOrder_PriceOrder: 'Цена ордера',
	ModifyOrder_PriceSL: 'Стоп-лосс',
	ModifyOrder_PriceTP: 'Тейк-профит',
	ModifyOrder_LastTime: 'Дата истечения',
	ModifyOrder_LastTimeMessage: 'Выберите дату истечения для вашего ордера.',
	ModifyOrder_Accept: 'Принять',
	ModifyOrder_Cancel: 'Отменить',
	ModifyOrder_LastTimeOk: 'ОК',
	ModifyOrder_LastTimeCancel: 'Отменить',
	Orders_NoData: 'Нет открытых ордеров для отображения.',
	Orders_Order: 'Тикет',
	Orders_Lot: 'Лот',
	Orders_PriceOrder: 'Цена ордера',
	Orders_PriceSL: 'Стоп-лосс',
	Orders_PriceTP: 'Тейк-профит',
	Orders_PriceCurrent: 'Текущая цена',
	Orders_Reason: 'Причина',
	Orders_Comment: 'Комментарий',
	Orders_DeleteOrder: 'Удалить ордер',
	Orders_ModifyOrder: 'Изменить',
	Orders_Type_0: 'Клиент',
	Orders_Type_1: 'Эксперт',
	Orders_Type_2: 'Продавец',
	Orders_Type_3: 'SL',
	Orders_Type_4: 'TP',
	Orders_Type_5: 'SO',
	Orders_Type_6: 'Округление',
	Orders_Type_7: 'Внешний клиент',
	Orders_Type_8: 'V Tolerance',
	SymbolProperties_SwapRates: 'Ставки свопа',
	SymbolProperties_Sessions: 'Сессии',
	SymbolProperties_SessionsTrade: 'Сессии для торговли',
	SymbolProperties_ContractSize: 'Размер контракта',
	SymbolProperties_Spread: 'Спред',
	SymbolProperties_StopsLevel: 'Уровни стопов',
	SymbolProperties_CurrencyMargin: 'Маржа в валюте',
	SymbolProperties_VolumeMin: 'Минимальный объем',
	SymbolProperties_VolumeMax: 'Максимальный объем',
	SymbolProperties_Monday: 'Понедельник',
	SymbolProperties_Tuesday: 'Вторник',
	SymbolProperties_Wednesday: 'Среда',
	SymbolProperties_Thursday: 'Четверг',
	SymbolProperties_Friday: 'Пятница',
	SymbolProperties_Saturday: 'Суббота',
	SymbolProperties_Sunday: 'Воскресенье',
	History_Button: 'Фильтр',
	History_Positions: 'Закрытые позиции',
	History_Balance: 'История баланса',
	History_MyTransactions: 'Мои транзакции',
	History_DatePicker_StartTitle: 'Выберите начальную дату',
	History_DatePicker_EndTitle: 'Выберите конечную дату',
	History_DatePicker_Ok: 'Ок',
	History_DatePicker_Cancel: 'Отмена',
	History_NoData: 'Нет истории для отображения.',
	HistoryPositions_Symbol: 'Символ',
	HistoryPositions_PositionID: 'Позиция',
	HistoryPositions_Volume: 'Лот',
	HistoryPositions_Price: 'Цена открытия',
	HistoryPositions_PricePosition: 'Цена закрытия',
	HistoryPositions_PriceSL: 'Стоп-лосс',
	HistoryPositions_PriceTP: 'Тейк-профит',
	HistoryPositions_Swap: 'Своп',
	HistoryPositions_Profit: 'Прибыль/убыток',
	HistoryPositions_Commission: 'Комиссии',
	HistoryPositions_Action_0: 'Продажа',
	HistoryPositions_Action_1: 'Покупка',
	HistoryPositions_Action_2: 'Лимитная продажа',
	HistoryPositions_Action_3: 'Лимитная покупка',
	HistoryPositions_Action_4: 'Стоп-продажа',
	HistoryPositions_Action_5: 'Стоп-покупка',
	HistoryPositions_Action_6: 'Стоп-лимитная продажа',
	HistoryPositions_Action_7: 'Стоп-лимитная покупка',
	HistoryPositions_Action_8: 'Закрыть по ордеру',
	HistoryBalance_Time: 'Дата',
	HistoryBalance_Ticket: 'Сделка',
	HistoryBalance_Profit: 'Цена',
	HistoryBalance_Type: 'Тип',
	HistoryBalance_Comment: 'Комментарий',
	HistoryBalance_Action_2: 'Баланс',
	HistoryBalance_Action_3: 'Кредит',
	HistoryBalance_Action_4: 'Дополнительные расходы/выплаты',
	HistoryBalance_Action_5: 'Коррекция',
	HistoryBalance_Action_6: 'Бонусы',
	HistoryBalance_Action_7: 'Комиссия',
	ChangePassword_CurrentPassword: 'Текущий пароль',
	ChangePassword_NewPassword: 'Новый пароль',
	ChangePassword_ConfirmNewPassword: 'Подтвердите новый пароль',
	ChangePassword_Button: 'Сохранить',
	ChangePassword_Checker_UpperCase: 'Используйте заглавные буквы',
	ChangePassword_Checker_LowerCase: 'Используйте строчные буквы',
	ChangePassword_Checker_Number: 'Используйте цифры',
	ChangePassword_Checker_SpecialChar: 'Используйте специальные символы',
	ChangePassword_Checker_Length: 'Должен содержать как минимум 6 символов',
	ChangePassword_Checker_OriginalPassword: 'Ваш старый пароль должен быть верным',
	ChangePassword_Checker_PasswordsMatch: 'Новые пароли должны совпадать',
	ReferencesAdd_Button: 'Добавить ссылку',
	ReferencesAdd_Error: 'Ошибка при добавлении ссылки\nПожалуйста, попробуйте позже.',
	FlowMessage_Timeout: 'После транзакции не получен ответ.',
	FlowMessage_Success: 'Транзакция успешно завершена.',
	FlowMessage_Error: 'При обработке транзакции произошла ошибка. Пожалуйста, попробуйте позже.',
	FlowMessage_InvalidPrice: 'Пожалуйста, введите действительную цену.',
	FlowMessage_InfoCardCopied: 'Значение скопировано в буфер обмена.',
	FlowMessage_UnderConstruction: 'Страница, которую вы пытаетесь открыть, находится в стадии разработки.',
	FlowMessage_InvalidLogin: 'Неправильное имя пользователя или пароль.',
	FlowMessage_LoginSuccess: 'Вход выполнен успешно. Добро пожаловать!',
	FlowMessage_LoginError: 'При входе произошла ошибка! Пожалуйста, попробуйте позже.',
	FlowMessage_UnfilledFieldWarning: 'Пожалуйста, заполните все поля.',
	FlowMessage_ModifySuccess: 'Ваше изменение успешно выполнено.',
	FlowMessage_PositionClose: 'Позиция успешно закрыта.',
	FlowMessage_OrderDelete: 'Ордер успешно удален.',
	FlowMessage_PositionCloseRejected: 'Не удалось закрыть позицию.',
	FlowMessage_OrderDeleteRejected: 'Не удалось удалить ордер.',
	FlowMessage_Login_InvalidInformation: 'Неполная или неверная информация для входа.',
	FlowMessage_Login_GroupNotFound: 'Группа не найдена.',
	FlowMessage_Login_CUN_Error: 'Вам необходимо загрузить последнее приложение для входа.',
	FlowMessage_Logout_N: 'Вы успешно вышли.',
	FlowMessage_Logout_R: 'Вы вышли. Повторный вход...',
	FlowMessage_Logout_0: 'Вы вышли из-за технического обслуживания сервера.',
	FlowMessage_Logout_1: 'Вы вышли из-за потери соединения.',
	FlowMessage_Logout_UserDeleted: 'Вы вышли, потому что пользователь удален.',
	FlowMessage_Logout_DataSourceIsNotReady: 'Ваша сессия истекла. Пожалуйста, войдите снова.',
	FlowMessage_Logout_LoginFromAnotherDevice: 'Вход с другого устройства. Вы вышли.',
	FlowMessage_Logout_GroupChanged: 'Вы вышли из-за потери соединения. Пожалуйста, войдите снова.',
	FlowMessage_Logout_ConnectionFailed: 'Вы вышли из-за потери соединения. Пожалуйста, войдите снова.',
	FlowMessage_ChangeInformationSuccess: 'Ваша информация успешно обновлена. \nПеренаправление на страницу входа.',
	FlowMessage_ChangeInformationError: 'Произошла ошибка. Ваш пароль не может быть изменен. Пожалуйста, попробуйте позже.',
	FlowMessage_CustomerComplaintSuccess: 'Ваша жалоба отправлена.',
	FlowMessage_InvestorError: 'Вы не можете торговать на инвестиционном счете.',
	FlowMessage_SymbolNotFound: 'Информация о символе не найдена.',
	FlowMessage_Trade_Waiting: 'Обработка заказа, пожалуйста, подождите...',
	FlowMessage_Trade_InvalidOrderType: 'Неверный формат ордера.',
	FlowMessage_Trade_InvalidRequestType: 'Неверный формат запроса.',
	FlowMessage_Trade_InvalidVolume: 'Неверный объем.',
	FlowMessage_Trade_InvalidPrice: 'Неверная цена.',
	FlowMessage_Trade_Buy_MarketLimit: 'Цена лимита рынка не может быть меньше или равна цене покупки.',
	FlowMessage_Trade_Buy_StopLoss: 'Цена стоп-лосса не может быть больше или равна цене покупки.',
	FlowMessage_Trade_Buy_TakeProfit: 'Цена тейк-профита не может быть меньше или равна цене покупки.',
	FlowMessage_Trade_Sell_MarketLimit: 'Цена лимита рынка не может быть меньше или равна цене продажи.',
	FlowMessage_Trade_Sell_StopLoss: 'Цена стоп-лосса не может быть меньше или равна цене продажи.',
	FlowMessage_Trade_Sell_TakeProfit: 'Цена тейк-профита не может быть больше или равна цене продажи.',
	FlowMessage_Deposit: 'После уведомления о депозите сообщите своему инвестиционному специалисту.',
	FlowMessage_Withdraw: 'После уведомления о снятии средств сообщите своему инвестиционному специалисту.',
	FlowMessage_PhoneError: 'Неверный номер телефона!\nВаш номер телефона должен состоять только из цифр.',
	FlowMessage_EmailError: 'Неверный адрес электронной почты!\nПожалуйста, введите действительный адрес электронной почты.',
	FlowMessage_ReferenceAdded: 'Ссылка успешно добавлена.',
	FlowMessage_ReferenceAllReadyCollected: 'Вы уже получили свой бонус!',
	FlowMessage_ReferenceUnderReview: 'Ваш бонус еще не был одобрен!',
	FlowMessage_ReferenceNotEnough: 'У вас недостаточно ссылок для получения бонуса!',
	FlowMessage_InvalidComplaint: 'Пожалуйста, введите действительную жалобу.',
	Retcode_0: '',
	Retcode_10001: 'Запрос в процессе.',
	Retcode_10002: 'Запрос принят.',
	Retcode_10003: 'Запрос обрабатывается.',
	Retcode_10004: 'Предложение получено в ответ на запрос.',
	Retcode_10005: 'Цены предоставлены в ответ на запрос.',
	Retcode_10006: 'Запрос отклонен.',
	Retcode_10007: 'Запрос отменен.',
	Retcode_10008: 'Ордер размещен в результате запроса.',
	Retcode_10009: 'Запрос выполнен.',
	Retcode_10010: 'Запрос частично выполнен.',
	Retcode_10011: 'Общая ошибка запроса.',
	Retcode_10012: 'Время запроса истекло.',
	Retcode_10013: 'Неверный запрос.',
	Retcode_10014: 'Неверный объем.',
	Retcode_10015: 'Неверная цена.',
	Retcode_10016: 'Неверные уровни стопа или цены.',
	Retcode_10017: 'Торговля отключена.',
	Retcode_10018: 'Рынок закрыт.',
	Retcode_10019: 'Недостаточно средств.',
	Retcode_10020: 'Цена изменилась.',
	Retcode_10021: 'Цена недоступна.',
	Retcode_10022: 'Неверная продолжительность ордера.',
	Retcode_10023: 'Ордер изменен.',
	Retcode_10024: 'Слишком много запросов на сделку.',
	Retcode_10025: 'Запрос не содержит изменений.',
	Retcode_10026: 'Автоматическая торговля отключена на сервере.',
	Retcode_10027: 'Автоматическая торговля отключена на стороне клиента.',
	Retcode_10028: 'Запрос заблокирован дилером.',
	Retcode_10029: 'Изменение не удалось из-за того, что ордер или позиция слишком близко к рынку.',
	Retcode_10030: 'Режим заполнения не поддерживается.',
	Retcode_10031: 'Нет соединения.',
	Retcode_10032: 'Разрешено только для реальных счетов.',
	Retcode_10033: 'Достигнуто ограничение количества ордеров.',
	Retcode_10034: 'Достигнуто ограничение объема.',
	Retcode_10035: 'Неверный или запрещенный тип ордера.',
	Retcode_10036: 'Позиция уже закрыта.',
	Retcode_10037: 'Используется для внутренних целей.',
	Retcode_10038: 'Объем закрытия превышает текущий объем открытой позиции.',
	Retcode_10039: 'Уже есть ордер на закрытие позиции.',
	Retcode_10040: 'Количество одновременно открытых позиций в аккаунте может быть ограничено настройками группы.',
	Retcode_10041: 'Запрос отклонен, ордер отменен.',
	Retcode_10042: 'Запрос отклонен из-за установленного правила "Разрешены только длинные позиции" для символа.',
	Retcode_10043: 'Запрос отклонен из-за установленного правила "Разрешены только короткие позиции" для символа.',
	Retcode_10044: 'Запрос отклонен из-за установленного правила "Разрешены только закрывающие позиции" для символа.',
	Retcode_10045: 'Закрытие позиции не соответствует правилу FIFO.',
	Retcode_10046: 'Невозможно открыть позицию или разместить отложенный ордер из-за запрета на хеджирование позиций.',
};
