import { useContext, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

// components
import MenuItem from "../../components/MenuItem/MenuItem";
import UserCard from "../../components/UserCard";

// constants
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { formatNumber } from "../../utils/numberUtil";
import InfoCard from "../Transaction/InfoCard";
import { InvestorWarningBox } from "../../components/InvestorWarningBox";
import { useLanguage } from "../../context/Language/LanguageProvider";
import { AntDesign, MaterialCommunityIcons, SimpleLineIcons } from "@expo/vector-icons";

const Profile = ({ navigation }: any) => {
  const { words } = useLanguage();
  const { user } = useContext(AuthContext);
  const { colors } = useTheme();

  const menuItems: Array<any> = [
    {
      id: 0,
      name: "Share",
      value: "Share",
      icon: <AntDesign name="sharealt" size={24} color={colors.TEXT}  />,
    },
    {
      id: 1,
      name: "Deposit",
      value: "DepositSelection",
      icon: <MaterialCommunityIcons name="cash-fast" size={24} color={colors.TEXT} />,
    },
    {
      id: 2,
      name: "Withdraw",
      value: "Withdraw",
      icon: <MaterialCommunityIcons name="cash-fast" size={24} color={colors.TEXT} />,
    },
    // {
    //   id: 3,
    //   name: 'Kişisel Bilgilerim',
    //   value: 'PersonalInformation',
    //   icon: <AntDesign name="sharealt" size={24} color={colors.TEXT} />,
    // },
    {
      id: 3,
      name: "ChangePassword",
      value: "ChangePassword",
      icon: <MaterialCommunityIcons name="onepassword" size={24} color={colors.TEXT} />,
    },
    // {
    //   id: 4,
    //   name: 'ChangePasswordForInvestor',
    //   value: 'ChangePasswordForInvestor',
    //   icon: <AntDesign name="sharealt" size={24} color={colors.TEXT} />,
    // },
    {
      id: 5,
      name: "Transaction",
      value: "Transaction",
      icon: <MaterialCommunityIcons name="cash-register" size={24} color={colors.TEXT} />,
    },
    //{
    //  id: 5,
    //  name: 'Kimlik Doğrulama',
    //  value: 'traders',
    //  icon: <AntDesign name="sharealt" size={24} color={colors.TEXT} />,
    //},
    {
      id: 6,
      name: "References",
      value: "References",
      icon: <SimpleLineIcons name="envelope-letter" size={24} color={colors.TEXT} />,
    },
  ];

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.SCREEN_BACKGROUND,
        paddingHorizontal: 10,
      }}
    >
      {user?.IsInvestor && <InvestorWarningBox />}
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: colors.SCREEN_BACKGROUND }}
      >
        <View style={styles.userCardContainer}>
          <UserCard
            username={`${user?.Name}`}
            number={user?.Phone || words["UserCard_PhoneError"]}
            imageSource={require("../../assets/trader1.png")}
            loginID={user?.ID || "Login ID yok"}
            isProile={true}
          />
        </View>

        <InfoCard />

        <View style={styles.menuContainer}>
          {menuItems.map((item: any) => (
            <MenuItem
              key={item.id}
              name={item.name}
              route={item.value}
              Icon={item.icon}
              extraProp={{ user }}
              navigation={navigation}
              isInvestor={!!user?.IsInvestor}
            />
          ))}
        </View>
      </ScrollView>
    </View>
  );
};

export default Profile;

const styles = StyleSheet.create({
  userCardContainer: { marginVertical: 10 },
  //card

  //menu
  menuContainer: {
    marginVertical: 10,
    gap: 7,
  },
});
