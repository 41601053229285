import { createElement } from "react";

interface Props {
  value: string; // veya tarih nesnesi tipinde, bağlı olarak
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export default function DateTimePicker({ value, onChange }: Props) {
  return createElement("input", {
    type: "date",
    value: value,
    onInput: onChange,
  });
}
