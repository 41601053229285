import {useContext, useRef, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Tab from '../../components/Tab';

// views
import {IAuthContext, IOrder, IPosition} from '../../Global';
import {AuthContext} from '../../context/AuthContext';
import {useTheme} from '../../context/Theme/ThemeProvider';
import History from './History';
import InfoCard from './InfoCard';
import Portfolio from './Portfolio';
import { InvestorWarningBox } from '../../components/InvestorWarningBox';

const tabs = [
  {
    id: 1,
    name: 'Positions',
    value: 'open-positions',
  },
  {
    id: 2,
    name: 'Orders',
    value: 'open-orders',
  },
  {
    id: 3,
    name: 'History',
    value: 'history',
  },
];

const Transaction = (props: any) => {
  const {positions, orders, user} = useContext<IAuthContext>(AuthContext);
  let defaultTab = props.route.params?.defaultTab;
  const {colors} = useTheme();

  const [selectedTab, setSelectedTab] = useState<any>(defaultTab || tabs[0]);
  const newData = useRef<Array<IPosition | IOrder>>([]);

  let channel = selectedTab.value;
  if (selectedTab.value === 'open-positions') {
    if (positions) {
      newData.current = positions;
    }
  } else if (selectedTab.value === 'open-orders') {
    if (orders) {
      newData.current = orders;
    }
  }

  console.log('TRANSACTION PROPS: ', props)

  const renderByTab = () => {
    if (channel === 'history') {
      return <History />;
    } else {
      if (newData.current.length <= 0) {
        // no data
        return (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text style={{fontSize: 16, color: colors.TEXT}}>Gösterilecek {selectedTab.name.toLowerCase()} yok</Text>
          </View>
        );
      }
      return (
        <Portfolio
          data={newData.current}
          selectedTab={selectedTab}
        />
      );
    }
  };

  return (
    <View style={{flex: 1, backgroundColor: colors.SCREEN_BACKGROUND}}>
      {user?.IsInvestor && <InvestorWarningBox/>}
      <View style={styles.wrapper}>
        {/* <TransactionInfo /> */}

        <Tab
          selectedTab={selectedTab}
          tabs={tabs}
          setSelectedTab={setSelectedTab}
        />
        {selectedTab.value !== 'history' && ( //wallet cards
          <InfoCard portfolio={true}/>
        )}

        {renderByTab()}
      </View>
    </View>
  );
};

export default Transaction;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical:7,
    gap: 10,
    position: 'relative'
  },
});
