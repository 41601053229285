interface ILanguage {
	[key: string]: string;
}

export const Deutsch: ILanguage = {
	Exit: 'Beenden',
	Login: 'Anmelden',
	LoginID: 'ID',
	UserName: 'Benutzername',
	Password: 'Passwort',
	Buy: 'Kaufen',
	Sell: 'Verkaufen',
	Profit: 'Gewinn',
	Loss: 'Verlust',
	Home: 'Startseite',
	Markets: 'Märkte',
	Transaction: 'Meine Transaktionen',
	Profile: 'Profil',
	Spread: 'Spread',
	Search: 'Suchen',
	Name: 'Name',
	FirstName: 'Vorname',
	LastName: 'Nachname',
	Email: 'E-Mail',
	Phone: 'Telefon',
	Amount: 'Betrag',
	Button_SubmitDepositRequest: 'Einzahlungsantrag einreichen',
	Button_SubmitWithdrawRequest: 'Auszahlungsantrag einreichen',
	Button_SubmitYourComplaint: 'Beschwerde einreichen',
	Position: 'Position',
	Order: 'Order',
	Login_RegisteredAccounts: 'Registrierte Konten',
	Login_Servers: 'Server',
	Login_NoRegisteredAccounts: 'Keine registrierten Konten gefunden.',
	Login_ToolTip_1: 'Die Anmeldeseite wurde aktualisiert.',
	Login_ToolTip_2: 'Nachdem Sie Benutzernamen und Passwort eingegeben haben, wird durch Klicken auf die Schaltfläche "Anmelden" eine Anmeldung durchgeführt und Ihre Anmeldedaten gespeichert.',
	Login_ToolTip_3: 'Wenn Sie die Schaltfläche "Anmelden" gedrückt halten, melden Sie sich an, ohne Ihre Anmeldedaten zu speichern.',
	Login_ToolTip_4: 'Wenn Sie registrierte Benutzer haben, können Sie sich mit jedem gewünschten Benutzer anmelden.',
	Login_ToolTip_5: 'Wenn Sie einen registrierten Benutzer gedrückt halten, entfernen Sie das gedrückte Konto aus den gespeicherten.',
	Login_ToolTip_6: 'Sie müssen hier einen Server auswählen.',
	Login_ToolTip_7: 'Seien Sie besonders auf den ausgewählten Server achten beim Anmelden.',
	SideMenu_Deposit: 'Einzahlung',
	SideMenu_Withdraw: 'Auszahlung',
	SideMenu_Notifications: 'Benachrichtigungen',
	SideMenu_BankStatement: 'Bankauszug',
	SideMenu_Transaction: 'Transaktionsverlauf',
	SideMenu_ChangePassword: 'Passwort ändern',
	SideMenu_ChangePasswordForInvestor: 'Passwort für Investor ändern',
	SideMenu_Traders: 'Händler',
	SideMenu_Robots: 'Roboter',
	SideMenu_LiveSupport: 'Live-Support',
	SideMenu_CustomerComplaint: 'Kundenbeschwerde',
	SideMenu_Settings: 'Einstellungen',
	SideMenu_Share: 'Mit Freunden teilen',
	SideMenu_References: 'Verweise',
	Favorites: 'Favoriten',
	Forex: 'Forex',
	Crypto: 'Krypto',
	Indices: 'Indizes',
	Metal: 'Metalle',
	Energy: 'Energie',
	Stock: 'Aktien',
	History: 'Verlauf',
	Positions: 'Offene Positionen',
	Orders: 'Offene Orders',
	Home_RecommendedActions: 'Empfohlene Aktionen des Tages',
	Home_Announcement: 'Sie können das Paar, dem Sie folgen möchten, markieren.',
	Home_ProminentInvestors: 'Prominente Anleger',
	Router_Login: 'Anmelden',
	Router_TransactionDetails: 'Meine Transaktionen',
	Router_Trade: 'Handel',
	Router_TradingView: 'Detailierte Chart',
	Router_DepositSelection: 'Einzahlung',
	Router_DepositWithCash: 'Einzahlung per Banküberweisung',
	Router_Withdraw: 'Auszahlung per Banküberweisung',
	Router_CurrencySelection: 'Einzahlung per Banküberweisung',
	Router_BankTransaction: 'Einzahlung per Banküberweisung',
	Router_DepositWithCrypto: 'Einzahlung mit Krypto',
	Router_ChangePassword: 'Passwort ändern',
	Router_ChangePasswordForInvestor: 'Passwort für Investor ändern',
	Router_CustomerComplaint: 'Kundenbeschwerde',
	Router_References: 'Verweise',
	Router_AddReferences: 'Verweise hinzufügen',
	Router_Settings: 'Einstellungen',
	Router_Home: 'Startseite',
	Router_Profile: 'Profil',
	Router_Symbols: 'Märkte',
	Router_Transaction: 'Meine Transaktionen',
	Balance: 'Kontostand',
	Equity: 'Eigenkapital',
	Credit: 'Kredit',
	Margin: 'Verwendeter Margin',
	Margin_Free: 'Freier Margin',
	Margin_Level: 'Margin Level',
	Share: 'Mit Freunden teilen',
	Floating: 'Schwebender Gewinn/Verlust',
	UserCard_PhoneError: 'Telefonnummer ist nicht registriert!',
	DepositSelection_WithCash: 'Einzahlung per Banküberweisung',
	DepositSelection_WithCrypto: 'Einzahlung mit Krypto',
	DepositSelection_Warning: 'Bitte wählen Sie die geeignete Einzahlungsmethode aus.',
	DepositWithCash_Warning: 'Um die geeigneten Bankdaten zu erhalten, wählen Sie bitte die Bank aus, mit der Sie Ihr Konto auffüllen möchten, und wählen Sie dann die Währung aus.',
	CurrencySelection_Warning: 'Um die geeigneten Bankdaten zu erhalten, wählen Sie bitte die Bank aus, mit der Sie Ihr Konto auffüllen möchten, und wählen Sie dann die Währung aus.',
	DepositTransaction_BankName: 'Bankname',
	DepositTransaction_Name: 'Empfängername',
	DepositTransaction_Currency: 'Währung',
	DepositTransaction_IBAN: 'IBAN',
	DepositTransaction_Description: 'Beschreibung',
	DepositWithCrypto_Warning_1: 'Um die entsprechenden Kryptoinformationen zu erhalten, wählen Sie bitte die Krypto aus, mit der Sie Ihr Konto auffüllen möchten, und wählen Sie dann das Netzwerk aus.',
	DepositWithCrypto_Warning_2: 'Nach Auswahl der Netzwerkeinheit können Sie diese von der Informationskarte kopieren.',
	DepositWithCrypto_SelectCrypto: 'Krypto auswählen',
	DepositWithCrypto_SelectNetwork: 'Netzwerkeinheit auswählen',
	DepositWithCrypto_SelectNetwork_Warning: 'Wählen Sie zuerst eine Krypto aus',
	DepositWithCrypto_Message: 'Nachricht',
	CustomerComplaint_Message: 'Ihre Nachricht',
	CustomerComplaint_Warning: 'Sie können Ihr Anliegen mitteilen, indem Sie es im unten stehenden Feld eingeben. Es wird über WhatsApp übermittelt.',
	Settings_Language: 'Sprache',
	Settings_SearchLanguage: 'Sprache suchen...',
	Settings_DarkMode: 'Dunkler Modus',
	Settings_Notifications: 'Benachrichtigungen',
	Trade_AdditionalTransactions: 'Zusätzliche Transaktionen',
	Trade_MarketOrder: 'Marktauftrag',
	Trade_MarketClosed: 'Markt geschlossen',
	Trade_TakeProfit: 'Take Profit',
	Trade_StopLoss: 'Stop Loss',
	Trade_Create: 'Erstellen',
	Trade_BuyOrder: 'Kaufauftrag erstellen',
	Trade_SellOrder: 'Verkaufsauftrag erstellen',
	Trade_BuyLimit: 'Limit Kaufauftrag erstellen',
	Trade_SelllLimit: 'Limit Verkaufsauftrag erstellen',
	Trade_BuyStop: 'Stop Kaufauftrag erstellen',
	Trade_SellStop: 'Stop Verkaufsauftrag erstellen',
	TradeStatus_0: 'Markt geschlossen',
	TradeStatus_1: 'Nur Kauf erlaubt',
	TradeStatus_2: 'Nur Verkauf erlaubt',
	TradeStatus_3: 'Nur Schließen erlaubt',
	TradeStatus_4: 'Handel offen',
	TradeStatus_5: 'Angebot abwarten',
	Trade_Type_0: 'Kaufen',
	Trade_Type_1: 'Verkaufen',
	Trade_Type_2: 'Limit kaufen',
	Trade_Type_3: 'Limit verkaufen',
	Trade_Type_4: 'Stop kaufen',
	Trade_Type_5: 'Stop verkaufen',
	Trade_Type_6: 'Stop-Limit kaufen',
	Trade_Type_7: 'Stop-Limit verkaufen',
	Trade_Type_8: 'Schließen durch Auftrag',
	Positions_NoData: 'Keine offenen Positionen zum Anzeigen.',
	Positions_TimeCreate: 'Datum',
	Positions_Position: 'Position',
	Positions_VolumeCurrent: 'Lot',
	Positions_PriceCurrent: 'Aktueller Preis',
	Positions_PriceSL: 'Stop Loss',
	Positions_PriceTP: 'Take Profit',
	Positions_Swap: 'Swap',
	Positions_Profit: 'Gewinn/Verlust',
	Position_PartialClose: 'Teilschließen',
	Position_ClosePosition: 'Position schließen',
	Position_ModifyPosition: 'Ändern',
	ModifyPosition_Title: 'Position ändern',
	ModifyPosition_Position: 'Position Nr.',
	ModifyPosition_PriceCurrent: 'Aktueller Preis',
	ModifyPosition_PriceSL: 'Stop Loss',
	ModifyPosition_PriceTP: 'Take Profit',
	ModifyPosition_Accept: 'Akzeptieren',
	ModifyPosition_Cancel: 'Abbrechen',
	ModifyOrder_Title: 'Auftrag ändern',
	ModifyOrder_Order: 'Auftragsnummer',
	ModifyOrder_PriceCurrent: 'Aktueller Preis',
	ModifyOrder_PriceOrder: 'Auftragspreis',
	ModifyOrder_PriceSL: 'Stop Loss',
	ModifyOrder_PriceTP: 'Take Profit',
	ModifyOrder_LastTime: 'Verfallsdatum',
	ModifyOrder_LastTimeMessage: 'Wählen Sie ein Verfallsdatum für Ihren Auftrag.',
	ModifyOrder_Accept: 'Akzeptieren',
	ModifyOrder_Cancel: 'Abbrechen',
	ModifyOrder_LastTimeOk: 'OK',
	ModifyOrder_LastTimeCancel: 'Abbrechen',
	Orders_NoData: 'Keine offenen Aufträge zum Anzeigen.',
	Orders_Order: 'Ticket',
	Orders_Lot: 'Lot',
	Orders_PriceOrder: 'Auftragspreis',
	Orders_PriceSL: 'Stop Loss',
	Orders_PriceTP: 'Take Profit',
	Orders_PriceCurrent: 'Aktueller Preis',
	Orders_Reason: 'Grund',
	Orders_Comment: 'Kommentar',
	Orders_DeleteOrder: 'Auftrag löschen',
	Orders_ModifyOrder: 'Ändern',
	Orders_Type_0: 'Kunde',
	Orders_Type_1: 'Experte',
	Orders_Type_2: 'Verkäufer',
	Orders_Type_3: 'SL',
	Orders_Type_4: 'TP',
	Orders_Type_5: 'SO',
	Orders_Type_6: 'Rundung',
	Orders_Type_7: 'Externer Kunde',
	Orders_Type_8: 'V-Toleranz',
	SymbolProperties_SwapRates: 'Swap-Sätze',
	SymbolProperties_Sessions: 'Sitzungen',
	SymbolProperties_SessionsTrade: 'Handel',
	SymbolProperties_ContractSize: 'Vertragsgröße',
	SymbolProperties_Spread: 'Spread',
	SymbolProperties_StopsLevel: 'Stop-Levels',
	SymbolProperties_CurrencyMargin: 'Währungsmargin',
	SymbolProperties_VolumeMin: 'Minimales Volumen',
	SymbolProperties_VolumeMax: 'Maximales Volumen',
	SymbolProperties_Monday: 'Montag',
	SymbolProperties_Tuesday: 'Dienstag',
	SymbolProperties_Wednesday: 'Mittwoch',
	SymbolProperties_Thursday: 'Donnerstag',
	SymbolProperties_Friday: 'Freitag',
	SymbolProperties_Saturday: 'Samstag',
	SymbolProperties_Sunday: 'Sonntag',
	History_Button: 'Filter',
	History_Positions: 'Geschlossene Positionen',
	History_Balance: 'Kontostand Verlauf',
	History_MyTransactions: 'Meine Transaktionen',
	History_DatePicker_StartTitle: 'Startdatum auswählen',
	History_DatePicker_EndTitle: 'Enddatum auswählen',
	History_DatePicker_Ok: 'Ok',
	History_DatePicker_Cancel: 'Abbrechen',
	History_NoData: 'Keine Historie vorhanden.',
	HistoryPositions_Symbol: 'Symbol',
	HistoryPositions_PositionID: 'Position',
	HistoryPositions_Volume: 'Losgröße',
	HistoryPositions_Price: 'Eröffnungspreis',
	HistoryPositions_PricePosition: 'Schlusskurs',
	HistoryPositions_PriceSL: 'Stop Loss',
	HistoryPositions_PriceTP: 'Take Profit',
	HistoryPositions_Swap: 'Swap',
	HistoryPositions_Profit: 'Gewinn/Verlust',
	HistoryPositions_Commission: 'Kommissionen',
	HistoryPositions_Action_0: 'Verkauf',
	HistoryPositions_Action_1: 'Kauf',
	HistoryPositions_Action_2: 'Limit Verkauf',
	HistoryPositions_Action_3: 'Limit Kauf',
	HistoryPositions_Action_4: 'Stop Verkauf',
	HistoryPositions_Action_5: 'Stop Kauf',
	HistoryPositions_Action_6: 'Stop Limit Verkauf',
	HistoryPositions_Action_7: 'Stop Limit Kauf',
	HistoryPositions_Action_8: 'Durch Auftrag schließen',
	HistoryBalance_Time: 'Datum',
	HistoryBalance_Ticket: 'Deal',
	HistoryBalance_Profit: 'Preis',
	HistoryBalance_Type: 'Typ',
	HistoryBalance_Comment: 'Kommentar',
	HistoryBalance_Action_2: 'Kontostand',
	HistoryBalance_Action_3: 'Guthaben',
	HistoryBalance_Action_4: 'Zusätzliche Gebühren/Auszahlungen',
	HistoryBalance_Action_5: 'Korrektur',
	HistoryBalance_Action_6: 'Bonis',
	HistoryBalance_Action_7: 'Provision',
	ChangePassword_CurrentPassword: 'Aktuelles Passwort',
	ChangePassword_NewPassword: 'Neues Passwort',
	ChangePassword_ConfirmNewPassword: 'Neues Passwort bestätigen',
	ChangePassword_Button: 'Speichern',
	ChangePassword_Checker_UpperCase: 'Verwenden Sie Großbuchstaben',
	ChangePassword_Checker_LowerCase: 'Verwenden Sie Kleinbuchstaben',
	ChangePassword_Checker_Number: 'Verwenden Sie Zahlen',
	ChangePassword_Checker_SpecialChar: 'Verwenden Sie Sonderzeichen',
	ChangePassword_Checker_Length: 'Muss mindestens 6 Zeichen lang sein',
	ChangePassword_Checker_OriginalPassword: 'Ihr altes Passwort muss korrekt sein',
	ChangePassword_Checker_PasswordsMatch: 'Die neuen Passwörter müssen übereinstimmen',
	ReferencesAdd_Button: 'Referenz hinzufügen',
	ReferencesAdd_Error: 'Fehler beim Hinzufügen der Referenz\nBitte versuchen Sie es später erneut.',
	FlowMessage_Timeout: 'Keine Antwort nach der Transaktion erhalten.',
	FlowMessage_Success: 'Transaktion erfolgreich abgeschlossen.',
	FlowMessage_Error: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
	FlowMessage_InvalidPrice: 'Bitte geben Sie einen gültigen Preis ein.',
	FlowMessage_InfoCardCopied: 'Wert in die Zwischenablage kopiert.',
	FlowMessage_UnderConstruction: 'Die Seite, auf die Sie zugreifen möchten, wird noch erstellt.',
	FlowMessage_InvalidLogin: 'Falscher Benutzername oder falsches Passwort.',
	FlowMessage_LoginSuccess: 'Erfolgreich eingeloggt. Willkommen!',
	FlowMessage_LoginError: 'Beim Einloggen ist ein Fehler aufgetreten! Bitte versuchen Sie es später erneut.',
	FlowMessage_UnfilledFieldWarning: 'Bitte füllen Sie alle Felder aus.',
	FlowMessage_ModifySuccess: 'Ihre Änderung wurde erfolgreich abgeschlossen.',
	FlowMessage_PositionClose: 'Position erfolgreich geschlossen.',
	FlowMessage_OrderDelete: 'Auftrag erfolgreich gelöscht.',
	FlowMessage_PositionCloseRejected: 'Schließen der Position fehlgeschlagen.',
	FlowMessage_OrderDeleteRejected: 'Löschen des Auftrags fehlgeschlagen.',
	FlowMessage_Login_InvalidInformation: 'Unvollständige oder falsche Anmeldeinformationen.',
	FlowMessage_Login_GroupNotFound: 'Gruppe nicht gefunden.',
	FlowMessage_Login_CUN_Error: 'Sie müssen die neueste Anwendung herunterladen, um sich anzumelden.',
	FlowMessage_Logout_N: 'Sie haben sich erfolgreich abgemeldet.',
	FlowMessage_Logout_R: 'Sie haben sich abgemeldet. Anmeldung wird wiederholt...',
	FlowMessage_Logout_0: 'Sie wurden aufgrund von Serverwartungsarbeiten abgemeldet.',
	FlowMessage_Logout_1: 'Sie wurden aufgrund einer verlorenen Verbindung abgemeldet.',
	FlowMessage_Logout_UserDeleted: 'Sie wurden abgemeldet, da der Benutzer gelöscht wurde.',
	FlowMessage_Logout_DataSourceIsNotReady: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
	FlowMessage_Logout_LoginFromAnotherDevice: 'Von einem anderen Gerät aus angemeldet. Sie wurden abgemeldet.',
	FlowMessage_Logout_GroupChanged: 'Sie wurden aufgrund einer verlorenen Verbindung abgemeldet. Bitte melden Sie sich erneut an.',
	FlowMessage_Logout_ConnectionFailed: 'Sie wurden aufgrund einer verlorenen Verbindung abgemeldet. Bitte melden Sie sich erneut an.',
	FlowMessage_ChangeInformationSuccess: 'Ihre Informationen wurden aktualisiert. \nWeiterleitung zur Anmeldeseite.',
	FlowMessage_ChangeInformationError: 'Ein Fehler ist aufgetreten. Ihr Passwort konnte nicht geändert werden. Bitte versuchen Sie es später erneut.',
	FlowMessage_CustomerComplaintSuccess: 'Ihre Beschwerde wurde eingereicht.',
	FlowMessage_InvestorError: 'Sie können nicht in einem Investor-Konto handeln.',
	FlowMessage_SymbolNotFound: 'Symbolinformationen nicht gefunden.',
	FlowMessage_Trade_Waiting: 'Auftragsverarbeitung, bitte warten...',
	FlowMessage_Trade_InvalidOrderType: 'Ungültiges Auftragsformat.',
	FlowMessage_Trade_InvalidRequestType: 'Ungültiges Anfrageformat.',
	FlowMessage_Trade_InvalidVolume: 'Ungültiges Volumen.',
	FlowMessage_Trade_InvalidPrice: 'Ungültiger Preis.',
	FlowMessage_Trade_Buy_MarketLimit: 'Marktlimitwert darf nicht kleiner oder gleich dem Kaufpreis sein.',
	FlowMessage_Trade_Buy_StopLoss: 'Stop Loss-Wert darf nicht größer oder gleich dem Kaufpreis sein.',
	FlowMessage_Trade_Buy_TakeProfit: 'Take Profit-Wert darf nicht kleiner oder gleich dem Kaufpreis sein.',
	FlowMessage_Trade_Sell_MarketLimit: 'Marktlimitwert darf nicht kleiner oder gleich dem Verkaufspreis sein.',
	FlowMessage_Trade_Sell_StopLoss: 'Stop Loss-Wert darf nicht kleiner oder gleich dem Verkaufspreis sein.',
	FlowMessage_Trade_Sell_TakeProfit: 'Take Profit-Wert darf nicht größer oder gleich dem Verkaufspreis sein.',
	FlowMessage_Deposit: 'Nach der Einzahlungsbenachrichtigung informieren Sie Ihren Anlagespezialisten.',
	FlowMessage_Withdraw: 'Nach der Auszahlungsbenachrichtigung informieren Sie Ihren Anlagespezialisten.',
	FlowMessage_PhoneError: 'Ungültige Telefonnummer!\nIhre Telefonnummer darf nur aus Ziffern bestehen.',
	FlowMessage_EmailError: 'Ungültige E-Mail-Adresse!\nBitte geben Sie eine gültige E-Mail-Adresse ein.',
	FlowMessage_ReferenceAdded: 'Referenz erfolgreich hinzugefügt.',
	FlowMessage_ReferenceAllReadyCollected: 'Sie haben Ihren Bonus bereits abgeholt!',
	FlowMessage_ReferenceUnderReview: 'Ihr Bonus wurde noch nicht genehmigt!',
	FlowMessage_ReferenceNotEnough: 'Sie haben nicht genügend Referenzen für den Bonus!',
	FlowMessage_InvalidComplaint: 'Bitte geben Sie eine gültige Beschwerde ein.',
	Retcode_0: '',
	Retcode_10001: 'Anfrage ist unterwegs.',
	Retcode_10002: 'Anfrage akzeptiert.',
	Retcode_10003: 'Anfrage wird bearbeitet.',
	Retcode_10004: 'Angebot als Antwort auf die Anfrage erhalten.',
	Retcode_10005: 'Preise als Antwort auf die Anfrage bereitgestellt.',
	Retcode_10006: 'Anfrage abgelehnt.',
	Retcode_10007: 'Anfrage abgebrochen.',
	Retcode_10008: 'Eine Order wurde als Ergebnis der Anfrage platziert.',
	Retcode_10009: 'Anfrage abgeschlossen.',
	Retcode_10010: 'Anfrage teilweise erfüllt.',
	Retcode_10011: 'Allgemeiner Anfragefehler.',
	Retcode_10012: 'Anfragezeitüberschreitung.',
	Retcode_10013: 'Ungültige Anfrage.',
	Retcode_10014: 'Ungültiges Volumen.',
	Retcode_10015: 'Ungültiger Preis.',
	Retcode_10016: 'Falsche Stop-Levels oder Preis.',
	Retcode_10017: 'Handel deaktiviert.',
	Retcode_10018: 'Markt geschlossen.',
	Retcode_10019: 'Unzureichende Mittel.',
	Retcode_10020: 'Preis hat sich geändert.',
	Retcode_10021: 'Preis ist nicht verfügbar.',
	Retcode_10022: 'Ungültige Auftragsdauer.',
	Retcode_10023: 'Auftrag modifiziert.',
	Retcode_10024: 'Zu viele Handelsanfragen. Zum Beispiel tritt dieser Fehler auf, wenn versucht wird, mehr als 128 Handelsanfragen von einer Administrator-API-Instanz zu senden.',
	Retcode_10025: 'Anfrage enthält keine Änderungen.',
	Retcode_10026: 'Automatisierter Handel ist auf dem Server deaktiviert.',
	Retcode_10027: 'Automatisierter Handel ist auf der Clientseite deaktiviert.',
	Retcode_10028: 'Anfrage blockiert durch den Händler.',
	Retcode_10029: 'Änderung fehlgeschlagen, da der Auftrag oder die Position zu nahe am Markt liegt.',
	Retcode_10030: 'Füllmodus wird nicht unterstützt.',
	Retcode_10031: 'Keine Verbindung.',
	Retcode_10032: 'Nur für echte Konten erlaubt.',
	Retcode_10033: 'Auftragsanzahlgrenze erreicht.',
	Retcode_10034: 'Volumengrenze erreicht.',
	Retcode_10035: 'Ungültiger oder verbotener Auftragstyp.',
	Retcode_10036: 'Position bereits geschlossen. Zum Beispiel tritt dieser Fehler auf, wenn versucht wird, die Stop-Levels einer Position zu ändern.',
	Retcode_10037: 'Intern verwendet.',
	Retcode_10038: 'Das Schließvolumen übersteigt das aktuelle Volumen der offenen Position.',
	Retcode_10039:
		'Es gibt bereits einen Auftrag zur Schließung der Position. Der Fehler tritt im Hedge-Modus auf: Wenn versucht wird, eine Position mit einer entgegengesetzten Position zu schließen oder einen Teil oder die gesamte bestehende Position zu schließen, wird der Fehler generiert, wenn das Gesamtvolumen der bestehenden Schließaufträge das aktuelle Volumen der Position übersteigt und der neu platzierte Auftrag.',
	Retcode_10040:
		'Die Anzahl der gleichzeitig offenen Positionen in einem Konto kann durch Gruppeneinstellungen begrenzt sein. Wenn eine Grenze erreicht ist, gibt der Server Retcode_REQUEST_LIMIT_POSITIONS zurück, wenn versucht wird, einen Auftrag zu platzieren. Die Einschränkung funktioniert je nach Positionsberechnungstyp unterschiedlich: Netting - die Anzahl der offenen Positionen wird berücksichtigt. Wenn eine Grenze erreicht ist, deaktiviert die Plattform das Platzieren von Trades, die die Anzahl der offenen Positionen erhöhen könnten. Tatsächlich erlaubt die Plattform nur das Platzieren von Aufträgen für Symbole, die bereits offene Positionen haben. Bestehende Pending-Aufträge werden nicht berücksichtigt, da sie zwar zu Änderungen an bestehenden Positionen führen könnten, aber die Positionszählung nicht erhöhen können. Hedging - ausstehende Aufträge werden zusammen mit offenen Positionen berücksichtigt, da das Aktivieren eines ausstehenden Auftrags immer zur Eröffnung einer neuen Position führt. Wenn eine Grenze erreicht ist, deaktiviert die Plattform sowohl das Platzieren neuer Marktaufträge zur Eröffnung neuer Positionen als auch das Platzieren von Pending-Aufträgen.',
	Retcode_10041: 'Anfrage abgelehnt, Auftrag abgebrochen. Dieser Code wird zurückgegeben, wenn die Aktion IMTConRoute::ACTION_CANCEL_ORDER in einer Routingregel angewendet wird.',
	Retcode_10042: 'Anfrage abgelehnt, weil die Regel "Nur Long-Positionen erlaubt" für das Symbol festgelegt ist (IMTConSymbol::TRADE_LONGONLY).',
	Retcode_10043: 'Anfrage abgelehnt, weil die Regel "Nur Short-Positionen erlaubt" für das Symbol festgelegt ist (IMTConSymbol::TRADE_SHORTONLY).',
	Retcode_10044: 'Anfrage abgelehnt, weil die Regel "Nur Schließpositionen erlaubt" für das Symbol festgelegt ist (IMTConSymbol::TRADE_CLOSEONLY).',
	Retcode_10045: 'Positionsschließung entspricht nicht der FIFO-Regel. Dies wird für Gruppen verwendet, bei denen die Option IMTConGroup::TRADEFLAGS_FIFO_CLOSE aktiviert ist. Mit dieser Option müssen alle Positionen sequenziell geschlossen werden: Die älteste Position wird zuerst geschlossen, dann die nächste und so weiter.',
	Retcode_10046:
		'Es ist nicht möglich, eine Position zu öffnen oder einen ausstehenden Auftrag zu platzieren, da Hedge-Positionen verboten sind. Der Fehler wird zurückgegeben, wenn das Flag IMTConGroup::TRADEFLAGS_HEDGE_PROHIBIT für die Gruppe aktiviert ist und der Benutzer versucht, einen Handel durchzuführen, wenn bereits ein entgegengesetzter Auftrag oder eine entgegengesetzte Position für dasselbe Symbol vorhanden ist.',
};
