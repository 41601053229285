export interface ILanguage {
	key: number;
	value: string;
	code: string;
}

export const Languages = [
	{ key: 0, value: 'Türkçe', code: 'tr' },
	{ key: 1, value: 'English', code: 'en' },
	{ key: 2, value: 'العربية', code: 'ar' },
	{ key: 3, value: 'Türkmen', code: 'tk' },
	{ key: 4, value: 'Deutsch', code: 'de' },
	{ key: 5, value: 'Español', code: 'es' },
	{ key: 6, value: '日本語', code: 'ja' },
	{ key: 7, value: 'Русский', code: 'ru' },
	//{ key: 8, value: 'Italiano', code: 'it' },
	{ key: 9, value: '简体中文', code: 'zh' },
	//{ key: 10, value: '한국어', code: 'ko' },
	//{ key: 11, value: 'Português', code: 'pt' },
	//{ key: 12, value: 'Français', code: 'fr' },
	{ key: 13, value: 'فارسی', code: 'fa' },
	//{ key: 14, value: 'Bahasa Indonesia', code: 'key' },
	//{ key: 15, value: 'Bahasa Melayu', code: 'ms' },
	//{ key: 16, value: 'ไทย', code: 'th' },
	//{ key: 17, value: 'Tiếng Việt', code: 'vi' },
	//{ key: 18, value: 'हिन्दी', code: 'hi' },
	//{ key: 19, value: 'اردو', code: 'ur' },
	//{ key: 20, value: 'മലയാളം', code: 'ml' },
	//{ key: 21, value: 'தமிழ்', code: 'ta' },
	//{ key: 22, value: 'বাংলা', code: 'bn' },
	//{ key: 23, value: 'ಕನ್ನಡ', code: 'kn' },
	//{ key: 24, value: 'తెలుగు', code: 'te' },
	//{ key: 25, value: 'मराठी', code: 'mr' },
	//{ key: 26, value: 'ગુજરાતી', code: 'gu' },
	//{ key: 27, value: 'ଓଡ଼ିଆ', code: 'or' },
	//{ key: 28, value: 'ਪੰਜਾਬੀ', code: 'pa' },
	//{ key: 29, value: 'অসমীয়া', code: 'as' },
	//{ key: 30, value: 'Manipuri', code: 'mni' },
	//{ key: 31, value: 'Mizo', code: 'lus' },
	//{ key: 32, value: 'Naga', code: 'nag' },
	//{ key: 33, value: 'Khasi', code: 'kha' },
	//{ key: 34, value: 'Garo', code: 'grt' },
	//{ key: 35, value: 'Kom', code: 'bkm' },
];
