import { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { FONTSIZE, PADDING } from "../../Global";
import BankCardIcon from "../../components/BankCardWithIcon";
import { useTheme } from "../../context/Theme/ThemeProvider";
import apiCall from "../../utils/apiCall";
import { useLanguage } from "../../context/Language/LanguageProvider";

const DepositSelection = ({ navigation }: any) => {
  const { words } = useLanguage();
  const { colors } = useTheme();
  const [data, setData] = useState<any>();

  useEffect(() => {
    apiCall(`/banks`)
      .then((response) => {
        console.log(response, "BANKS RESPONSEEEE");
        setData(response);
      })
      .catch((err: any) => {
        console.log("banksE:", err);
      });
  }, []);

  return (
    <View style={{ flex: 1, flexDirection: 'column', gap: 5, paddingHorizontal: PADDING.S, backgroundColor: colors.SCREEN_BACKGROUND }}>
			<View style={{ backgroundColor: colors.CARD_BACKGROUND1, borderRadius: 5, marginVertical: 5 }}>
				<Text style={{ textAlign: 'justify', paddingVertical: 8, paddingHorizontal: '5%', fontSize: FONTSIZE.m, color: colors.TEXT }}>{words['DepositSelection_Warning']}</Text>
			</View>

      {data?.Banks && (
        <BankCardIcon
          title={words["DepositSelection_WithCash"]}
          icon="bank"
          route="DepositWithCash"
          param={data}
          iconIsBox={true}
          navigation={navigation}
        />
      )}

      {data?.Crypto && (
        <BankCardIcon
          title={words["DepositSelection_WithCrypto"]}
          icon="crypto"
          route="DepositWithCrypto"
          param={data}
          iconIsBox={true}
          navigation={navigation}
        />
      )}
    </View>
  );
};

export default DepositSelection;
