import React from 'react';
import {View, Text, StyleSheet} from 'react-native';

interface WalletCardProps {
  title: string;
  price: string;
  unit?: string;
  isProfit?: boolean;
  isMarginLevel?:boolean;
}

import {IColors} from '../../Global';
import { useTheme } from '../../context/Theme/ThemeProvider';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const WalletCard = ({title, price, isProfit, isMarginLevel = false}: WalletCardProps) => {
  const {colors} = useTheme();
  const styles = Styles(colors);


  const renderProfit = () => {
    const parsedPrice = parseFloat(price);
    if (parsedPrice > 0) return <Text style={styles.priceGreen}>{price}</Text>;
    else if (parsedPrice < 0)
      return <Text style={styles.priceRed}>{price}</Text>;
    else return <Text style={styles.price}>{price}</Text>;
  };

  return (
    <View style={styles.card}>
      <View>
        {/* <Image source={icon} /> */}
        <MaterialCommunityIcons name="cash-100" size={30} color={colors.CONTENT_ICON} />
      </View>
      <View>
        <Text style={styles.text}>{title}</Text>
        
        {isProfit ? renderProfit() : <Text style={styles.price}>{(isMarginLevel?'%':'') + price}</Text>}
      </View>
    </View>
  );
};

const Styles = (COLOR:IColors) => StyleSheet.create({
  card: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '48%',
    gap: 10,
    padding: 10,
    backgroundColor: COLOR.CARD_BACKGROUND1,
    borderRadius: 5,
  },
  text: {
    textAlign: 'right',
    color: COLOR.TEXT,
    fontSize: 12,
  },
  price: {
    textAlign: 'right',
    fontSize: 16,
    color: COLOR.TEXT2,
  },
  priceGreen: {
    textAlign: 'right',
    fontSize: 16,
    color: COLOR.PROFIT,
  },
  priceRed: {
    textAlign: 'right',
    fontSize: 16,
    color: COLOR.LOSS,
  },
});

export default WalletCard;
