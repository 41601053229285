import React, { Dispatch, SetStateAction } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Pressable,
  ViewStyle,
  StyleProp,
  TextStyle,
  Platform,
} from "react-native";
import { IColors, FONTSIZE, FONTWEIGHT } from "../../Global";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { Ionicons } from "@expo/vector-icons";

interface ArgsProp {
  title: string;
  value: string;
  currency?: string;
}
interface Prop {
  type:
    | "basic"
    | "password"
    | "date"
    | "email"
    | "phone"
    | "withCurrency"
    | "noTitle"
    | "noTitlePassword";
  args?: ArgsProp;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  response?: Dispatch<SetStateAction<any>>;
  keyboardType?:
    | "default"
    | "number-pad"
    | "decimal-pad"
    | "numeric"
    | "email-address"
    | "phone-pad";
}

const InputCard: React.FC<Prop> = ({
  type,
  args,
  response,
  containerStyle,
  textStyle,
  keyboardType,
}: Prop) => {
  const { colors } = useTheme();
  const style = Style(colors);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const refInput = React.useRef<TextInput>(null);

  if (type == "password") {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          },
          containerStyle,
        ]}
      >
        <Text
          style={style.Key}
          onPress={() => {
            if (refInput != null && refInput.current != null) {
              refInput.current.focus();
            }
          }}
        >
          {args?.title}
        </Text>
        <TextInput
          autoCapitalize="none"
          secureTextEntry={!passwordVisible}
          style={[style.Value, style.TextInput, textStyle]}
          defaultValue={args?.value}
          onChangeText={response}
          keyboardType={keyboardType || "default"}
          ref={refInput}
        />
        <Pressable
          onPress={() => setPasswordVisible(!passwordVisible)}
          style={{ alignItems: "flex-end" }}
        >
          {passwordVisible ? (
            <Ionicons name="eye-outline" size={22} color={colors.TEXT} />
          ) : (
            <Ionicons name="eye-off-outline" size={22} color={colors.TEXT} />
          )}
        </Pressable>
      </View>
    );
  }
  if (type == "withCurrency") {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          },
          containerStyle,
        ]}
      >
        <Text
          style={style.Key}
          onPress={() => {
            if (refInput != null && refInput.current != null) {
              refInput.current.focus();
            }
          }}
        >
          {args?.title}
        </Text>
        <TextInput
          autoCapitalize="none"
          style={[style.Value, style.TextInput, textStyle]}
          defaultValue={args?.value}
          onChangeText={response}
          keyboardType={keyboardType || "default"}
          ref={refInput}
        />
        <Text style={{ alignItems: "flex-end" }}>{args?.currency}</Text>
      </View>
    );
  } else if (type == "date") {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          },
          containerStyle,
        ]}
      >
        <Text
          style={style.Key}
          onPress={() => {
            if (refInput != null && refInput.current != null) {
              refInput.current.focus();
            }
          }}
        >
          {args?.title}
        </Text>
        <TextInput
          autoCapitalize="none"
          style={[style.Value, style.TextInput, textStyle]}
          defaultValue={args?.value}
          onChangeText={response}
          keyboardType={keyboardType || "default"}
          ref={refInput}
        />
      </View>
    );
  } else if (type == "email") {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          },
          containerStyle,
        ]}
      >
        <Text
          style={style.Key}
          onPress={() => {
            if (refInput != null && refInput.current != null) {
              refInput.current.focus();
            }
          }}
        >
          {args?.title}
        </Text>
        <TextInput
          autoCapitalize="none"
          autoCorrect={false}
          textContentType={"emailAddress"}
          style={[style.Value, style.TextInput, textStyle]}
          defaultValue={args?.value}
          onChangeText={response}
          keyboardType={keyboardType || "email-address"}
          ref={refInput}
        />
      </View>
    );
  } else if (type == "phone") {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          },
          containerStyle,
        ]}
      >
        <Text
          style={style.Key}
          onPress={() => {
            if (refInput != null && refInput.current != null) {
              refInput.current.focus();
            }
          }}
        >
          {args?.title}
        </Text>
        <TextInput
          autoCapitalize="none"
          autoCorrect={false}
          textContentType={"telephoneNumber"}
          keyboardType={keyboardType || "number-pad"}
          style={[style.Value, style.TextInput, textStyle]}
          defaultValue={args?.value}
          onChangeText={response}
          ref={refInput}
        />
      </View>
    );
  } else if (type == "noTitle") {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          },
          containerStyle,
        ]}
      >
        <TextInput
          autoCapitalize="none"
          style={[
            style.Value,
            style.TextInput,
            textStyle,
            { textAlign: "left" },
          ]}
          value={args?.value}
          onChangeText={response}
          keyboardType={keyboardType || "default"}
          ref={refInput}
          placeholder={args?.title}
        />
      </View>
    );
  } else if (type == "noTitlePassword") {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          },
          containerStyle,
        ]}
      >
        <TextInput
          autoCapitalize="none"
          style={[
            style.Value,
            style.TextInput,
            textStyle,
            { textAlign: "left"},
          ]}
          value={args?.value}
          onChangeText={response}
          keyboardType={keyboardType || "default"}
          ref={refInput}
          placeholder={args?.title}
          secureTextEntry={!passwordVisible}
        />
         <Pressable
          onPress={() => setPasswordVisible(!passwordVisible)}
          style={{ alignItems: "flex-end" }}
        >
          {passwordVisible ? (
            <Ionicons name="eye-outline" size={22} color={colors.TEXT} />
          ) : (
            <Ionicons name="eye-off-outline" size={22} color={colors.TEXT} />
          )}
        </Pressable>
      </View>
    );
  } else {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          },
          containerStyle,
        ]}
      >
        <Text
          style={style.Key}
          onPress={() => {
            if (refInput != null && refInput.current != null) {
              refInput.current.focus();
            }
          }}
        >
          {args?.title}
        </Text>
        <TextInput
          autoCapitalize="none"
          style={[style.Value, style.TextInput, textStyle]}
          defaultValue={args?.value}
          onChangeText={response}
          keyboardType={keyboardType || "default"}
          ref={refInput}
        />
      </View>
    );
  }
};

export default InputCard;

const Style = (COLOR: IColors) =>
  StyleSheet.create({
    BgColors: {
      backgroundColor: COLOR.CARD_BACKGROUND1,
      borderRadius: 5,
      paddingHorizontal: 10,
      paddingVertical: 5,
      width: "100%",
      height: 40,
      gap: 5,
    },
    Key: {
      color: COLOR.TEXT,
      fontSize: FONTSIZE.l,
      textAlign: "left",
      // cursor: "pointer",
    },
    Value: {
      color: COLOR.TEXT,
      fontSize: FONTSIZE.l,
      fontWeight: "600",
      textAlign: "right",
    },
    TextInput:
      Platform.OS === "web"
        ? {
            outlineStyle: "none",
            height: 40,
            flexGrow: 1,
          }
        : {
            height: 40,
            flexGrow: 1,
          },
  });
