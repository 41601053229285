import React, {useEffect} from 'react';
import {lightColors, darkColors} from './colors';
import {useColorScheme} from 'react-native';

export const ThemeContext = React.createContext({
  darkMode: true,
  colors: darkColors,
  //setScheme: (scheme: string) => {},
  setScheme: (dark: boolean) => {},
});

export const ThemeProvider = ({children}: any) => {
  const colorScheme = useColorScheme();
  const [isDark, setIsDark] = React.useState(colorScheme == 'dark');

  useEffect(() => {
    setIsDark(colorScheme == 'dark');
  }, [colorScheme]);

  const defaultTheme = {
    darkMode: isDark,
    colors: isDark ? darkColors : lightColors,
    setScheme: (dark: boolean) => {
      setIsDark(dark);
    },
  };

  return (
    <ThemeContext.Provider value={defaultTheme}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
