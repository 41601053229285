import { FlatList, Pressable, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { useLanguage } from "../../context/Language/LanguageProvider";

type TabObj = {
  id: number;
  name: string;
  value?: string;
};

interface TabProps {
  tabs: TabObj[];
  selectedTab: TabObj;
  setSelectedTab: React.Dispatch<React.SetStateAction<TabObj>>;
}

const Tab = ({ tabs, selectedTab, setSelectedTab }: TabProps) => {
  const { colors } = useTheme();
  const { words } = useLanguage();
  const styles = Styles(colors);

  return (
    <View style={{ width: "100%" }}>
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={tabs}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => (
          <Pressable
            onPress={() => setSelectedTab(item)}
            style={item.id === selectedTab.id ? styles.itemSelected : {}}
          >
            <Text style={styles.text}>{words[item.name]}</Text>
          </Pressable>
        )}
      />
    </View>
  );
};

export default Tab;

const Styles = (COLOR: any) =>
  StyleSheet.create({
    text: {
      color: COLOR.TEXT,
      fontSize: 16,
      fontWeight: "500",
      margin: 10,
    },
    itemSelected: {
      borderBottomWidth: 2,
      borderBottomColor: COLOR.MAIN_BUTTON_BACKGORUND,
    },
  });
