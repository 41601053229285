import { Text } from 'react-native';
import PriceFormat from '../PriceFormat';
import { IColors } from '../../Global';

export interface IPositionsFieldProps {
	id: number;
	name: string;
	field: string;
	renderCell?: (data: any, colors: IColors) => JSX.Element;
}

export const ClosedPositionFields: IPositionsFieldProps[] = [
	// {
	// 	id: 1,
	// 	name: 'Anlaşma',
	// 	field: 'PositionID',
	// },
	// {
	// 	id: 2,
	// 	name: 'Sembol',
	// 	field: 'Symbol',
	// },
	// {
	// 	id: 3,
	// 	name: 'Lot',
	// 	field: 'Volume',
	// 	renderCell: (data: any, colors: IColors) => {
	// 		return <Text style={{ color: colors.TEXT, textAlign: 'right' }}>{Number(data?.Volume / 10000)}</Text>;
	// 	},
	// },
	// {
	// 	id: 4,
	// 	name: 'Açılış Fiyatı',
	// 	field: 'Price',
	// 	renderCell: (data: any, colors: IColors) => {
	// 		return (
	// 			<PriceFormat
	// 				style={{ color: colors.TEXT, textAlign: 'right' }}
	// 				digits={data?.Digits}
	// 				value={data?.Price}
	// 			/>
	// 		);
	// 	},
	// },
	// {
	// 	id: 5,
	// 	name: 'Kapanış Fiyatı',
	// 	field: 'PricePosition',
	// 	renderCell: (data: any, colors: IColors) => {
	// 		return (
	// 			<PriceFormat
	// 				style={{ color: colors.TEXT, textAlign: 'right' }}
	// 				digits={data?.Digits}
	// 				value={data?.PricePosition}
	// 			/>
	// 		);
	// 	},
	// },
	{
		id: 6,
		name: 'S/L',
		field: 'PriceSL',
		renderCell: (data: any, colors: IColors) => {
			if (data?.PriceSL == 0) {
				return <Text style={{ color: colors.TEXT, textAlign: 'right' }}>-</Text>;
			} else {
				return (
					<PriceFormat
						style={{ color: colors.TEXT, textAlign: 'right' }}
						digits={data?.Digits}
						value={data?.PriceSL}
					/>
				);
			}
		},
	},
	{
		id: 8,
		field: 'Swap',
		name: 'Swap',
		renderCell: (data: any, colors: IColors) => {
			if (data?.Storage == 0) {
				return <Text style={{ color: colors.TEXT, textAlign: 'right' }}>-</Text>;
			} else {
				return (
					<PriceFormat
						style={{ color: colors.TEXT, textAlign: 'right' }}
						digits={data?.Digits}
						value={data?.Storage}
						showCurrency={false}
					/>
				);
			}
		},
	},
	{
		id: 7,
		name: 'T/P',
		field: 'PriceTP',
		renderCell: (data: any, colors: IColors) => {
			if (data?.PriceTP == 0) {
				return <Text style={{ color: colors.TEXT, textAlign: 'right' }}>-</Text>;
			} else {
				return (
					<PriceFormat
						style={{ color: colors.TEXT, textAlign: 'right' }}
						digits={data?.Digits}
						value={data?.PriceTP}
						showCurrency={false}
					/>
				);
			}
		},
	},
	// {
	// 	id: 9,
	// 	field: 'Profit',
	// 	name: 'Kar',
	// 	renderCell: (data: any, colors: IColors) => {
	// 		return (
	// 			<PriceFormat
	// 				style={{ color: data?.Profit > 0 ? colors.PROFIT : colors.LOSS, textAlign: 'right' }}
	// 				digits={data?.Digits}
	// 				value={data?.Profit}
	// 			/>
	// 		);
	// 	},
	// },
	{
		id: 9,
		field: 'Commission',
		name: 'Masraflar',
		renderCell: (data: any, colors: IColors) => {
			if (data?.PriceSL == 0) {
				return <Text style={{ color: colors.TEXT, textAlign: 'right' }}>-</Text>;
			} else {
				return (
					<PriceFormat
						style={{ color: colors.TEXT, textAlign: 'right' }}
						digits={data?.Digits}
						value={data?.Commission}
						showCurrency={false}
					/>
				);
			}
		},
	},
	{
		id: 10,
		field: 'Comment',
		name: 'Yorum',
		renderCell: (data: any, colors: IColors) => {
			return <Text style={{ color: colors.TEXT, textAlign: 'right' }}>{data?.Comment}</Text>;
		},
	},
];
