import { View, Text, StyleSheet, Pressable } from "react-native";
import React from "react";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { useLanguage } from "../../context/Language/LanguageProvider";
import { RADIUS } from "../../Global";

const ReconmmendedActions = () => {
  const { colors } = useTheme();
  const { words } = useLanguage();
  const styles = Styles(colors);

  return (
    <View style={styles.view}>
      <Pressable
        style={({ pressed }) => [
          {
            opacity: pressed ? 0.8 : 1,
          },
          styles.container,
        ]}
      >
        <Text style={styles.text}>{words["Home_RecommendedActions"]} (3)</Text>
      </Pressable>
    </View>
  );
};

export default ReconmmendedActions;

const Styles = (colors: any) =>
  StyleSheet.create({
    view: {
      flex: 1,
    },
    container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.MAIN_BUTTON_BACKGORUND,
      borderRadius: RADIUS.CARD,
      padding: 6,
    },

    text: {
      color: colors.MAIN_BUTTON_TEXT,
      fontSize: 18,
      fontWeight: "500",
    },
  });
