import React, { useContext } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { BalanceColumnsFields } from './constants';
import { newDayjsWithFormat } from '../../utils/dateUtil';
import { getColor } from './utils';

import { AuthContext } from '../../context/AuthContext';
import { useTheme } from '../../context/Theme/ThemeProvider';
import PriceFormat from '../PriceFormat';
import { useLanguage } from '../../context/Language/LanguageProvider';

interface HistoryBalanceCardProps {
	data: any;
	backgroundColor: string;
}

const HistoryBalanceCard: React.FC<HistoryBalanceCardProps> = ({ data, backgroundColor }) => {
	const { colors } = useTheme();
	const { words } = useLanguage();
	const styles = Styles(colors);

	return (
		<View style={{ opacity: 1, backgroundColor: backgroundColor, borderRadius: 4, marginVertical: 4 }}>
			<Pressable style={[styles.wrapper, { borderColor: colors.TEXT }]}>
				<View style={[styles.contentWrapper, { backgroundColor: backgroundColor }]}>
					{BalanceColumnsFields.map((field) => {
						return (
							<View
								key={field.id}
								style={styles.detailItem}
							>
								<Text style={{ color: colors.TEXT, flex: 2 }}>{words['HistoryBalance_' + field.field]}</Text>
								{field.renderCell ? <View style={{ flex: 4 }}>{field.renderCell(data, colors)}</View> : <Text style={{ color: colors.TEXT }}>{field.field == 'Type' ? words['HistoryBalance_Action_' + data?.Action] : data?.[field.field]}</Text>}
							</View>
						);
					})}
				</View>
			</Pressable>
		</View>
	);
};

export default HistoryBalanceCard;

const Styles = (colors: any) =>
	StyleSheet.create({
		wrapper: {
			flexDirection: 'column',
			borderWidth: 2,
			padding: 10,
			borderRadius: 4,
		},
		topWrapper: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		topLeftWrapper: {
			flex: 2.5,
			gap: 4,
		},
		symbolName: {
			color: colors.TEXT,
			fontSize: 16,
			fontWeight: 'bold',
		},
		dateText: {
			color: colors.TEXT,
			fontSize: 12,
		},
		topRightWrapper: {
			flex: 3.5,
			flexDirection: 'column',
			alignItems: 'flex-end',
			height: '100%',
		},
		contentWrapper: {
			borderRadius: 4,
			padding: 4,
			marginTop: 10,
			gap: 6,
		},
		detailItem: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	});
