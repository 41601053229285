import { createElement, useContext, useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { AuthContext } from "../../context/AuthContext";

import DatePicker from "../../components/DatePicker";

import dayjs from "dayjs";

// utils
import * as dateUtil from "../../utils/dateUtil";

// icons

// globals
import { IColors, IPosition } from "../../Global";

import { formatNumber } from "../../utils/numberUtil";

import ClosedOrderPositionsItem from "../../components/ClosedOrderPositionsItem";
import { useTheme } from "../../context/Theme/ThemeProvider";
import apiCall from "../../utils/apiCall";
import { reportTypes } from "./constants";
import { Fontisto } from "@expo/vector-icons";
import HistoryPositionsCard from "../../components/HistoryPositionCard";
import HistoryBalanceCard from "../../components/HistoryBalanceCard";
import { useLanguage } from "../../context/Language/LanguageProvider";

const History = () => {
  const { colors } = useTheme();
  const { words, language } = useLanguage();
  const { user: currentUser } = useContext(AuthContext);

  const reportTypes = [
    { id: 1, value: "Kapanan Pozisyonlar", url: "Positions" },
    { id: 2, value: "Bakiye Geçmişi", url: "Balance" },
  ];

  const [data, setData] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedReportType, setSelectedReportType] = useState(reportTypes[0]);

  const [endDate, setEndDate] = useState(
    dayjs().startOf("minute").add(1, "day").toDate()
  );
  const [startDate, setStartDate] = useState(
    dayjs().startOf("day").add(-7, "day").toDate()
  );
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const [sumProfit, setSumProfit] = useState<string>("0");

  const styles = Styles(colors);

  useEffect(() => {
    if (!data) {
      return;
    }

    let _sumProfit = 0.0;
    if (data.length < 1) {
      return;
    }
    data.map((item: any) => {
      _sumProfit += Number(item["Profit"]);
    });

    setSumProfit(formatNumber(_sumProfit) + "$");
  }, [data]);

  dayjs.extend(require("dayjs/plugin/utc"));
  const fetchHistoryData = async () => {
    try {
      setIsLoading(true);
      console.log("startDate", startDate);
      const formatedStartDate = dayjs(startDate.setHours(0, 0, 1))
        .utc()
        .toISOString();
      const formatedEndDate = dayjs(endDate.setHours(23, 59, 59, 999))
        .utc()
        .toISOString();
      const historyReq = await apiCall(
        `/history?type=${selectedReportType.url}&login=${currentUser?.ID}&from=${formatedStartDate}&to=${formatedEndDate}`
      );

      setData(historyReq as Array<IPosition>);

      setIsLoading(false);
    } catch (error) {
      setData([]);
      setIsLoading(false);
      console.log(error, "error");
    }
  };

  const handlePickerChange = (itemValue: any) => {
    setIsDropDownOpen(false);
    setSelectedReportType(itemValue);
  };

  const toggleDropDown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  const renderDropDown = () => {
    if (isDropDownOpen) {
      return reportTypes.map((item) => {
        if (item.value !== selectedReportType.value) {
          return (
            <TouchableOpacity
              key={item.value}
              onPress={() => handlePickerChange(item)}
            >
              <Text style={styles.dropDownText}>
                {words["History_" + item.url]}
              </Text>
            </TouchableOpacity>
          );
        }
      });
    }
  };

  useEffect(() => {
    fetchHistoryData();
  }, [selectedReportType]);

  useEffect(() => {
    // if startDate is greater than endDate
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      setStartDate(dayjs(endDate).startOf("day").toDate());
    }
  }, [startDate, endDate]);

  return (
    <View style={styles.container}>
      <View style={styles.filterContainer}>
        {/* report type */}
        <View style={styles.top}>
          <View style={styles.reportWrapper}>
            <TouchableOpacity
              style={styles.reportButton}
              onPress={toggleDropDown}
            >
              <Text style={styles.reportText}>
                {words["History_" + selectedReportType.url]}
              </Text>
              <Text>▼</Text>
            </TouchableOpacity>
          </View>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.dropDownWrapper}
        >
          {renderDropDown()}
        </ScrollView>

        {/* start end date */}
        <View style={styles.bottom}>
          <View style={styles.dateWrapper}>
            <DatePicker
              value={dayjs(startDate).format("YYYY-MM-DD")}
              onChange={(event) => {
                setStartDate(event.target.valueAsDate || startDate);
              }}
            />
          </View>
          <Text style={styles.dateTo}>{"to"}</Text>
          <View style={styles.dateWrapper}>
            <DatePicker
              value={dayjs(endDate).format("YYYY-MM-DD")}
              onChange={(event) => {
                setEndDate(event.target.valueAsDate || endDate);
              }}
            />
          </View>
        </View>

        {/* filterButton */}
        <View style={styles.filterButtonWrapper}>
          <TouchableOpacity
            style={styles.filterButton}
            onPress={fetchHistoryData}
          >
            <Text style={styles.filterText}>{words["History_Button"]}</Text>
          </TouchableOpacity>
        </View>
      </View>

      {/* transactions */}
      <View style={styles.transactions}>
        <View style={styles.transactionHeader}>
          <Fontisto name="history" color={colors.TEXT} size={30} />
          <Text style={styles.headerText}>
            {words["History_MyTransactions"]}
          </Text>
        </View>
        {!isLoading ? (
          data.length > 0 ? (
            <>
              <FlatList
                showsVerticalScrollIndicator={false}
                data={data}
                windowSize={2}
                initialNumToRender={3}
                renderItem={
                  ({ item, index }) =>
                    selectedReportType.url == "Positions" ? (
                      <HistoryPositionsCard
                        backgroundColor={
                          index % 2
                            ? colors.CARD_BACKGROUND1
                            : colors.CARD_BACKGROUND2
                        }
                        data={item}
                        key={index}
                      />
                    ) : (
                      <HistoryBalanceCard
                        backgroundColor={
                          index % 2
                            ? colors.CARD_BACKGROUND1
                            : colors.CARD_BACKGROUND2
                        }
                        data={item}
                        key={index}
                      />
                    )
                  // <ClosedOrderPositionsItem
                  //   data={item}
                  //   fieldOption={selectedReportType.url}
                  //   backgroundColor={index % 2 ? colors.CARD_BACKGROUND1 : colors.CARD_BACKGROUND2}
                  // />
                }
              />
              <Text
                style={{
                  color: colors.TEXT,
                  textAlign: "right",
                  marginTop: 2,
                  paddingTop: 1,
                  borderTopWidth: 0.5,
                  display:
                    selectedReportType.url == "Positions" ? "flex" : "none",
                }}
              >
                {words["Profit"] + ": " + sumProfit}
              </Text>
            </>
          ) : (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16, color: colors.TEXT }}>
                <Text style={{ fontSize: 16, color: colors.TEXT }}>
                  {words["History_NoData"]}
                </Text>
              </Text>
            </View>
          )
        ) : (
          <View style={{ flex: 1, justifyContent: "center" }}>
            <ActivityIndicator
              size={"large"}
              color={colors.ACTIVITY_INDICATOR}
            />
          </View>
        )}
      </View>
    </View>
  );
};

export default History;

const Styles = (COLOR: IColors) =>
  StyleSheet.create({
    container: {
      flex: 1,
      gap: 10,
      padding: 10,
    },

    reportWrapper: {
      borderRadius: 5,
      justifyContent: "center",
      alignItems: "flex-start",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      backgroundColor: COLOR.CARD_BACKGROUND1,
    },

    //dropdown
    reportButton: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: COLOR.CARD_BACKGROUND1,
      width: "100%",
      padding: 10,
      borderRadius: 5,
    },
    reportText: {
      fontSize: 14,
      color: COLOR.TEXT,
    },
    dropDownWrapper: {
      position: "absolute",
      backgroundColor: COLOR.CARD_BACKGROUND1,
      width: "100%",
      top: 40,
      elevation: 999,
      zIndex: 999,
      maxHeight: "100%",
      paddingHorizontal: 10,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
    dropDownText: {
      fontSize: 14,
      color: COLOR.TEXT,
      paddingVertical: 4,
    },

    // filter

    filterContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    top: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 10,
    },

    filterButton: {
      paddingVertical: 9,
      borderRadius: 5,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLOR.MAIN_BUTTON_BACKGORUND,
    },
    filterText: {
      fontSize: 16,
      fontWeight: "bold",
      color: COLOR.MAIN_BUTTON_TEXT,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    bottom: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },

    dateWrapper: {
      flex: 1,
      borderRadius: 5,
      overflow: "hidden",
      justifyContent: "center",
      alignItems: "center",
      height: 40,
      display: "flex",
      flexDirection: "row",
    },
    dateButton: {
      height: "100%",
      backgroundColor: COLOR.CARD_BACKGROUND1,
      borderRadius: 5,
      fontSize: 14,
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
    },
    dateText: {
      fontSize: 14,
      color: COLOR.TEXT,
    },
    dateTo: {
      fontSize: 14,
      color: COLOR.TEXT,
      alignSelf: "center",
      paddingHorizontal: 10,
    },
    datePicker: {
      borderRadius: 0,
      color: "red",
    },

    // picker
    picker: {
      width: "105%",
      backgroundColor: COLOR.CARD_BACKGROUND1,
      borderRadius: 10,
      shadowOffset: { width: -2, height: 4 },
      shadowColor: COLOR.SHADOW3,
      shadowOpacity: 0.08,
    },
    pickerItem: {
      fontSize: 14,
      color: COLOR.TEXT,
    },

    filterButtonWrapper: {},

    // transactions
    transactions: {
      flex: 1,
      backgroundColor: COLOR.SCREEN_BACKGROUND,
      borderRadius: 5,
      padding: 10,
      shadowOffset: { width: -2, height: 4 },
      shadowColor: COLOR.SHADOW3,
      shadowOpacity: 0.08,
    },
    transactionHeader: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 10,
      gap: 10,
    },
    headerText: {
      fontSize: 16,
      fontWeight: "bold",
      color: COLOR.TEXT,
    },
    separator: {
      height: 1,
      backgroundColor: COLOR.BOTTOM_TEXT,
      marginBottom: 10,
    },
    transactionItem: {
      // borderBottomWidth: 1,
      // borderBottomColor: '#e5e5e5',
      marginBottom: 5,
      paddingBottom: 5,
    },
    transactionRow: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 10,
    },
    transactionField: {
      fontSize: 14,
      color: COLOR.NATURAL,
      flex: 1,
      flexWrap: "wrap",
      alignSelf: "flex-start",
    },
    transactionValue: {
      fontSize: 14,
      color: COLOR.NATURAL,
      flex: 1,
      flexWrap: "wrap",
      textAlign: "right",
    },
    fieldBuy: {
      fontSize: 14,
      color: COLOR.BUY,
    },
    fieldSell: {
      fontSize: 14,
      color: COLOR.SELL,
    },
    closedWithSL: {
      borderLeftWidth: 1,
      borderColor: COLOR.SELL,
      borderRadius: 5,
      paddingLeft: "1%",
    },
    closedWithTP: {
      borderLeftWidth: 1,
      borderColor: COLOR.BUY,
      borderRadius: 5,
      paddingLeft: "1%",
    },
  });
