import { createContext, useRef, useState } from "react";
import config from "../config";
import socket from "../config/socket";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
import {
  IAuthContext,
  IGroup,
  IGroupSymbol,
  IOpenRequest,
  IOrder,
  IPosition,
  IUser,
  ILogout,
} from "../Global";
import { formatNumber } from "../utils/numberUtil";
import { hideFlowMessage, showFlowMessage, FlowMessageProps } from "../components/FlowMessage";
import apiCall from "../utils/apiCall";
import { useLanguage } from "../context/Language/LanguageProvider";
import { getServerStorage } from "../screens/Login";
import { Audio } from "expo-av";
import NetInfo from '@react-native-community/netinfo';

async function SoundLogin() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../assets/sounds/soundslogin.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

async function SoundError() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../assets/sounds/soundserror.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

const storeUser = async (value: any) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem("user", jsonValue);
  } catch (e) {
    // saving error
  }
};

const storeLogin = async (
  login: string,
  password: string,
  server: string,
  isInvestor: boolean
) => {
  try {
    const jsonValue = await AsyncStorage.getItem(`loginList-${server}`);
    const data = jsonValue != null ? JSON.parse(jsonValue) : [];
    if (data) {
      let isExist = false;
      const newData = data.map((item: any) => {
        if (
          item.login == login.toUpperCase() &&
          item.isInvestor == isInvestor
        ) {
          isExist = true;
          return {
            login: login.toUpperCase(),
            password: password,
            isInvestor: isInvestor,
          };
        }
        return item;
      });
      if (!isExist) {
        console.log("isNotExists");
        AsyncStorage.setItem(
          `loginList-${server}`,
          JSON.stringify([
            ...data,
            {
              login: login.toUpperCase(),
              password: password,
              isInvestor: isInvestor,
            },
          ])
        );
      } else {
        AsyncStorage.setItem(`loginList-${server}`, JSON.stringify(newData));
      }
    } else {
      AsyncStorage.setItem(
        `loginList-${server}`,
        JSON.stringify([
          {
            login: login.toUpperCase(),
            password: password,
            isInvestor: isInvestor,
          },
        ])
      );
    }

    await AsyncStorage.setItem(
      "lastLoginAccount",
      JSON.stringify({ login: login.toUpperCase(), isInvestor: isInvestor })
    );
  } catch (e) {
    console.log("HO");
  }
};

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: any) => {
  const { words, language } = useLanguage();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [serverIP, setServerIP] = useState(
    "https://terminal-api.pmprs.xyz:8443"
  );
  // const [isLastestVersion, setIsLastestVersion] = useState<boolean>(false);

  const [userPass, setUserPass] = useState<any>(null);
  const [user, setUser] = useState<IUser>({} as IUser);
  const [orders, setOrders] = useState<Array<IOrder>>([{}] as Array<IOrder>);
  const [positions, setPositions] = useState<Array<IPosition>>([]);
  const [group, setGroup] = useState<IGroup>({} as IGroup);
  const [openRequests, setOpenRequests] = useState<IOpenRequest>({});
  const [sliderImages, setSliderImages] = useState<Array<string | undefined>>(
    []
  );

  const isInitialized = useRef(false);
  const socketDisconnectMessage = useRef<FlowMessageProps | undefined>();


  const unsubscribe = NetInfo.addEventListener(state => {
    console.log('Connection type', state.type);
    console.log('Is connected?', state.isConnected);
    if(isInitialized.current && state.isConnected){
      socket.connect();
    }
  });


  function logout({
    message = words["FlowMessage_Logout_N"],
    type = "success",
  }: ILogout) {
    apiCall("/logout", { message: message }, "POST");
    clearLogin();
    // console.log('message', message, type);
    showFlowMessage({
      message: message,
      type: type,
      duration: 5000,
    });
  }

  const reLogin = async () => {
    console.log(user, "USER VAR");
    const userStoreStr = await AsyncStorage.getItem("user");
    const lastServer = await getServerStorage();
    console.log(userStoreStr, "USER STORE");
    console.log(lastServer, "last Server STORE");
    logout({message: words["FlowMessage_Logout_R"]});
    if (userStoreStr && lastServer) {
      const userStore = JSON.parse(userStoreStr);
      // showFlowMessage(message);
      checkLogin(
        `${userStore.ID}`,
        userStore.Password,
        true,
        lastServer,
        false
      );
    } else {
    }
  };

  const setUserParams = (userInfo: any) => {
    const {
      user: { Orders, Positions, ...User },
      group: Group,
    } = userInfo;
    setIsLoggedIn(true);
    setUser(User);

    const sortedOrders = Orders.sort(
      (a: any, b: any) => b.TimeSetup - a.TimeSetup
    );
    setOrders([...sortedOrders]);

    const sortedPositions = Positions.sort(
      (a: any, b: any) => b.TimeCreate - a.TimeCreate
    );
    console.log(sortedPositions[0], "POSITIONS SORTED");

    setPositions([...sortedPositions]);

    setGroup(Group);
    storeUser({
      ...User,
      Password: userInfo.Password,
      isInvestor: userInfo.IsInvestor,
    });
    socket.setToken(User.Token);
    // socket.setReLogin(reLogin);
    console.log(User.Token);

    function getCurrentSymbolDigits(symbolName: string) {
      const symbol = Group.Symbols.find(
        (symbol: IGroupSymbol) => symbol.Path === symbolName
      );
      return symbol?.Symbol?.Digits || 2;
    }

    socket.on('connect', () => {
      console.log('Socket connected');
      if(isInitialized.current){
        isInitialized.current = false;
        reLogin();
        if(socketDisconnectMessage.current){
          hideFlowMessage(socketDisconnectMessage.current);
        }
      }
      else{
        isInitialized.current = true;
      }
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
      socketDisconnectMessage.current = showFlowMessage({ message: words['FlowMessage_Logout_1'], type: 'error', duration: 1440 * 60 * 1000});
    });
    
    // To unsubscribe to these update, just use:
    // unsubscribe();

    // socket.on('disconnect', (data: any) => {
    // 	logout({ message: words['FlowMessage_Logout_1'], type: 'error' });
    // 	console.log('DATA: ', data);
    // });

    socket.on("order-set", (data: any) => {
      setOrders((prevOrders: any) => {
        const index = prevOrders.findIndex(
          (ord: any) => ord.Order === data.Order
        );
        if (index === -1) {
          prevOrders.push(data);
        } else {
          prevOrders[index] = data;
        }
        const sortedOrders = prevOrders.sort(
          (a: any, b: any) => b.TimeSetup - a.TimeSetup
        );
        return [...sortedOrders];
      });
    });

    socket.on("position-set", (data: any) => {
      setPositions((prevPositions: any) => {
        const index = prevPositions.findIndex(
          (pos: any) => pos.Position === data.Position
        );
        if (index === -1) {
          prevPositions.push(data);
        } else {
          prevPositions[index] = data;
        }
        const sortedPositions = prevPositions.sort(
          (a: any, b: any) => b.TimeCreate - a.TimeCreate
        );
        console.log(sortedPositions[0], "POSITIONS SORTED");

        return [...sortedPositions];
      });
    });

    socket.on("order-del", (order: number) => {
      setOrders((prevOrders: any) => {
        const index = prevOrders.findIndex((ord: any) => ord.Order === order);
        if (index > -1) {
          prevOrders.splice(index, 1);
        }
        return [...prevOrders];
      });
    });

    socket.on("position-del", (position: number) => {
      setPositions((prevPositions: any) => {
        const index = prevPositions.findIndex(
          (pos: any) => pos.Position === position
        );
        if (index > -1) {
          prevPositions.splice(index, 1);
        }
        return [...prevPositions];
      });
    });

    socket.on("symbol-set", (data: any) => {
      setGroup((prevGroup: IGroup) => {
        const index = prevGroup.Symbols.findIndex(
          (gSymbol: IGroupSymbol) => gSymbol.Path === data.Path
        );
        if (index > -1) {
          prevGroup.Symbols[index].Symbol = data;
        }
        return { ...prevGroup };
      });
    });

    socket.on("symbol-del", (symbolPath: string) => {
      setGroup((prevGroup: IGroup) => {
        const index = prevGroup.Symbols.findIndex(
          (gSymbol: IGroupSymbol) => gSymbol.Path === symbolPath
        );
        if (index > -1) {
          prevGroup.Symbols.splice(index, 1);
        }
        return { ...prevGroup };
      });
    });

    socket.on("group-set", (data: any) => {
      setGroup(data);
    });

    socket.on("group-del", (data: any) => {
      console.log("Group-DEL", data);
      logout({
        message: words["FlowMessage_Logout_GroupChanged"],
        type: "info",
      });
    });

    socket.on("user-set", (data: any) => {
      console.log("USER UPDATE SET", data, user);
      setUser(User);
      storeUser({
        ...User,
        Password: userInfo.Password,
        isInvestor: userInfo.IsInvestor,
      });
    });

    socket.on("user-del", (data: any) => {
      console.log("USER-DEL", data);
      logout({
        message: words["FlowMessage_Logout_UserDeleted"],
        type: "error",
      });
    });

    socket.on("logout", async (message) => {
      logout(message);
    });

    socket.on("re-login", reLogin);

    socket.on("message", (message: any) => {
      console.log("MESSAGE1234", message);
      setTimeout(() => {
        setOpenRequests((prevOpenRequests) => {
          delete prevOpenRequests[message.Symbol];
          return { ...prevOpenRequests };
        });
      }, 200);
      if (message.Retcode === 10009) {
        showFlowMessage({
          type: "success",
          message: `${words[`Trade_Type_${message.Type}`]} ${
            message.ResultVolume / 10000
          } ${message.Symbol} at ${formatNumber(
            message.ResultPrice || message.PriceOrder,
            getCurrentSymbolDigits(message.Symbol),
            false
          )}\nsl: ${formatNumber(
            message.PriceSL,
            getCurrentSymbolDigits(message.Symbol),
            false
          )}, tp: ${formatNumber(
            message.PriceTP,
            getCurrentSymbolDigits(message.Symbol),
            false
          )}\ndone`,
          duration: 5000,
        });
      } else if (message.Retcode !== 0) {
        showFlowMessage({
          type: "error",
          message: words[`Retcode_${message.Retcode}`],
          duration: 5000,
        });
      }
    });

    socket.on("reset-data", (resetData) => {
      const {
        user: { Orders, Positions, ...User },
        group: Group,
      } = resetData;
      setUser({ ...User });

      const sortedOrders = Orders.sort(
        (a: any, b: any) => b.TimeSetup - a.TimeSetup
      );
      setOrders([...sortedOrders]);
      console.log(sortedOrders[0], "ORDERS SORTED2");

      const sortedPositions = Positions.sort(
        (a: any, b: any) => b.TimeCreate - a.TimeCreate
      );
      console.log(sortedPositions[0], "POSITIONS SORTED2");

      setPositions([...sortedPositions]);
      setGroup({ ...Group });
    });
    // socket.onAny((eventName: string, ...args: any) => {
    // 	console.log(eventName)
    // })

    socket.connect();
  };

  const setFavorite = (symbolName: string, status: boolean) => {
    const index = user.FavoriteSymbols.indexOf(symbolName);
    if (index === -1 && status) {
      user.FavoriteSymbols.push(symbolName);
    } else if (index > -1 && !status) {
      user.FavoriteSymbols.splice(index, 1);
    }
    setUser(user);
  };

  const checkLogin = (
    userID: string,
    password: string,
    checkData: boolean = false,
    server: any = { server: "REAL", url: "" },
    save: boolean = true
  ) => {
    if (!userID || !password) {
      SoundError();
      showFlowMessage({
        message: words["FlowMessage_InvalidLogin"],
        type: "error",
      });
      return;
    }
    const regex = /wn\d+$/gi;
    const regexTest = regex.test(userID);
    if (!regexTest) {
      // Geçici kontrol
      console.log("REGEX ERROR");
      SoundError();
      showFlowMessage({
        message: words["FlowMessage_InvalidLogin"],
        type: "error",
      });
      return;
    }
    if (server?.url) {
      socket.setServer(server.url);
    }

    console.log(userID, password, checkData, server, save, "CHECK LOGIN");
    apiCall(
      "/login",
      {
        login: userID,
        password: password,
        device_type: Platform.OS,
        device_token: "123456789",
        CUN: 5,
        language: language,
      },
      "POST"
    )
      .then((data) => {
        if (data.error) {
          SoundError();
          clearLogin();
          showFlowMessage({
            // message: words[data.error],
            // TODO: below messsage is not valid. Have to fix it.
            message: words["FlowMessage_InvalidLogin"],
            type: "error",
            duration: 5000,
          });
        } else {
          if (!checkData) {
            SoundLogin();
          }
          config.setServerTime(data.serverTime);

          console.log("___LOGIN__DATA___", Date.now() - data.serverTime);
          config.setServerTimeZone(data.serverTimeZone);
          setUserPass(password);
          setUserParams({ ...data, Password: password });

          if (checkData) {
            return;
          }

          // socket.setServer(server?.server);

          if (save) {
            // console.log(userID, password, server?.server);
            storeLogin(userID, password, server?.server, data.user.IsInvestor);
          }

          showFlowMessage({
            message: words["FlowMessage_LoginSuccess"],
            type: "success",
          });
        }
      })
      .catch((cppError) => {
        console.log(cppError, ": CPP ERROR");
        SoundError();
        clearLogin();
        showFlowMessage({
          message: words["FlowMessage_LoginError"],
          type: "error",
        });
      });
  };

  const clearLogin = () => {
    console.log("clearLogin");
    setUser({} as IUser);
    setUserPass(null);
    setIsLoggedIn(false);
    setOrders([]);
    setPositions([]);
    setOpenRequests({});
    AsyncStorage.removeItem("user");
    socket.removeAllListeners();
    socket.close();
    socket.disconnect();
    isInitialized.current = false;
    unsubscribe();
    socketDisconnectMessage.current = undefined;
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        userPass,
        checkLogin,
        logout,
        orders,
        positions,
        group,
        serverIP,
        setFavorite,
        openRequests,
        setOpenRequests,
        sliderImages,
        setSliderImages,
        // checkVersion,
        //symbolsData,
        //setSymbolsData,
        //addUpdateTestData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
