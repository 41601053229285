import React, { FC } from 'react';
import { Text, View } from 'react-native';
import { ISymbol } from '../../Global';
import { useTheme } from '../../context/Theme/ThemeProvider';

interface IField {
	id: number;
	name: string;
	field: keyof ISymbol;
}

const fields:Array<IField> = [
	// {
	// 	id: 1,
	// 	field: 'Sector',
	// 	name: 'Sektör',
	// },
	{
		id: 2,
		field: 'ContractSize',
		name: 'Kontrat Büyüklüğü',
	},
	{
		id: 3,
		field: 'Spread',
		name: 'Spread',
	},
	{
		id: 4,
		field: 'StopsLevel',
		name: 'Stops Level',
	},
	{
		id: 5,
		field: 'CurrencyMargin',
		name: 'Marjin Para Birimi',
	},
	// {
	// 	id: 6,
	// 	field: 'CalcMode',
	// 	name: 'Hesaplama Modu',
	// },
	// {
	// 	id: 7,
	// 	field: 'TickChartMode',
	// 	name: 'Tick Chart Modu',
	// },
];

interface Props {
	data: any;
}
const InformationTable: FC<Props> = ({ data }:Props) => {
	const { colors } = useTheme();
	return (
		<View style={{marginBottom:10}}>
			{fields.map((item: any) => {
				return (
					<View style={{flexDirection:'row', justifyContent:'space-between'}} key={item.id}>
						<Text style={{color:colors.TEXT}}>{item.name}</Text>
						<Text style={{color:colors.TEXT}}>{data?.[item.field]}</Text>
					</View>
				);
			})}
		</View>
	);
};

export default InformationTable;
