import React, { useContext, useRef, useState } from "react";
import { Animated, Pressable, StyleSheet, Text, View } from "react-native";
import { ClosedPositionFields } from "./constants";
import { newDayjsWithFormat } from "../../utils/dateUtil";
import { getReasonColor } from "./utils";

import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/Theme/ThemeProvider";
import PriceFormat from "../PriceFormat";
import { useLanguage } from "../../context/Language/LanguageProvider";

import { formatNumber } from "../../utils/numberUtil";
import { Entypo } from "@expo/vector-icons";

interface HistoryPositionsCardProps {
  data: any;
  backgroundColor: string;
}
const HistoryPositionsCard: React.FC<HistoryPositionsCardProps> = ({
  data,
  backgroundColor,
}) => {
  const { colors } = useTheme();
  const { words } = useLanguage();
  const { group } = useContext(AuthContext);
  const styles = Styles(colors);

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const currentSymbol = group?.Symbols.find(
    (s) => s.Symbol.Symbol === data.Symbol
  );

  const handleAccordionOpen = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const profitColor =
    data?.Profit > 0
      ? colors.PROFIT
      : data?.Profit < 0
      ? colors.LOSS
      : colors.TEXT;

  return (
    <View
      style={{
        opacity: 1,
        backgroundColor: backgroundColor,
        borderRadius: 4,
        marginVertical: 4,
      }}
    >
      <Pressable
        onPress={() => {
          handleAccordionOpen();
        }}
        style={[
          styles.wrapper,
          { borderColor: getReasonColor(data?.Reason, colors) },
        ]}
      >
        <View style={styles.top}>
          <View style={styles.topLeft}>
            <Text style={styles.symbolName}>{data?.Symbol}</Text>
            <Text
              style={{
                color: data.Action % 2 ? colors.BUY : colors.SELL,
                fontWeight: "700",
              }}
            >
              {words["HistoryPositions_Action_" + data?.Action]}{" "}
              {data.VolumeClosed / 10000}
              {/* {data.VolumeClosed !== data.PositionVolumeInitial ? ` / ${data.PositionVolumeInitial / 10000}` : ''} */}
            </Text>
          </View>
          <Text style={{ color: colors.TEXT }}>#{data.PositionID}</Text>
        </View>
        <View style={styles.descriptionWrapper}>
          <Text style={styles.description}>
            {currentSymbol?.Symbol.Description}
          </Text>
        </View>

        <View style={styles.detailsWrapper}>
          <View style={styles.details}>
            <PriceFormat
              value={data?.PricePosition}
              digits={currentSymbol?.Symbol.Digits || 2}
              showCurrency={false}
              textColor={profitColor}
            />
            <Entypo
              name="chevron-right"
              size={24}
              color={profitColor}
              style={{ marginLeft: "2%", marginRight: "2%" }}
            />
            <PriceFormat
              value={data?.Price}
              digits={currentSymbol?.Symbol.Digits || 2}
              showCurrency={false}
              textColor={profitColor}
            />

            <View style={{ marginLeft: "auto" }}>
              <PriceFormat
                value={data.Profit}
                digits={currentSymbol?.Symbol.Digits || 2}
                showCurrency={false}
                textColor={profitColor}
              />
            </View>
          </View>

          {/* <View style={{ flexDirection: 'row', gap: 4, marginVertical: 4 }}>
						<Text style={{color: profitColor}}>
							Δ =
							<PriceFormat
								value={data?.Price - data.PricePosition}
								digits={currentSymbol?.Symbol.Digits || 2}
								showCurrency={false}
								textColor={profitColor}
							/>
							({formatNumber(((data?.Price - data.PricePosition) / data?.Price) * 100, 2) + '%'})
						</Text>
					</View> */}

          <View style={styles.details}>
            {/* <Text style={styles.dateText}>{newDayjsWithFormat(data?.PositionTimeDone * 1000)}</Text>
						<BoldRightArrow
							height={10}
							width={10}
							stroke={colors.TEXT}
							fill={colors.TEXT}
							style={{ marginLeft: '2%', marginRight: '2%' }}
						/> */}
            <Text style={styles.dateText}>
              {newDayjsWithFormat(data?.Time * 1000)}
            </Text>
          </View>
          {isAccordionOpen && (
            <View style={[styles.contentWrapper]}>
              {ClosedPositionFields.map((field) => {
                if (
                  field.field == "Comment" &&
                  getReasonColor(data?.Reason, colors) == colors.GRAY
                ) {
                  return;
                }

                return (
                  <View key={field.id} style={styles.detailItem}>
                    <Text style={{ color: colors.TEXT }}>
                      {words["HistoryPositions_" + field.field]}
                    </Text>
                    {field.renderCell ? (
                      <View style={{}}>{field.renderCell(data, colors)}</View>
                    ) : (
                      <Text style={{ color: colors.TEXT }}>
                        {data?.[field.field]}
                      </Text>
                    )}
                  </View>
                );
              })}
            </View>
          )}
        </View>
      </Pressable>
    </View>
  );
};

export default HistoryPositionsCard;

const Styles = (colors: any) =>
  StyleSheet.create({
    wrapper: {
      flexDirection: "column",
      borderWidth: 2,
      // padding: 10,
      borderRadius: 4,
    },
    symbolName: {
      color: colors.TEXT,
      fontSize: 16,
      fontWeight: "bold",
    },
    dateText: {
      color: colors.TEXT,
      fontSize: 12,
    },
    contentWrapper: {
      // borderRadius: 4,
      // padding: 8,
      marginTop: 10,
      // gap: 6,
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    detailItem: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "48%",
    },

    top: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: 8,
      paddingTop: 8,
    },
    descriptionWrapper: {
      paddingHorizontal: 8,
    },
    description: {
      fontSize: 11,
      color: colors.TEXT,
    },
    topLeft: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 4,
      flex: 1,
    },
    detailsWrapper: {
      backgroundColor: colors.CONTENT_BACKGROUND,
      borderRadius: 4,
      padding: 8,
      marginTop: 4,
    },
    details: {
      flexDirection: "row",
      alignItems: "center",
    },
  });
