import {StyleSheet, Text, View, FlatList} from 'react-native';
import React, { useEffect } from 'react';

//components
import OrderPositionItem from '../../components/OrderPositionsItem';
import {useTheme} from '../../context/Theme/ThemeProvider';
import socket from '../../config/socket';

interface Props {
  data: any;
  selectedTab: any;
}

const Portfolio = ({data, selectedTab}: Props) => {
  const {colors} = useTheme();

    useEffect(() => {
        return () => {
          const filteredData = data.filter((fData: any, fIndex:number) => data.findIndex((x: any) => x.Symbol === fData.Symbol) === fIndex);
          for(let item of filteredData){
            socket.off(item?.Symbol);
          }
        };
      }, [data])
  
  return (
    <FlatList
      data={data}
      windowSize={2}
      initialNumToRender={1}
      renderItem={({item, index}) => (
        <OrderPositionItem
          data={item}
          fieldOption={selectedTab.value}
          backgroundColor={index % 2 == 0 ? colors.CARD_BACKGROUND1 : colors.CARD_BACKGROUND2}
        />
      )}
      keyExtractor={(item: any, index: any) => {
        return item.Position ?? item.Order ?? item.Ticket;
      }}
      ItemSeparatorComponent={() => <View style={{marginVertical: 4}} />}
    />
  );
};

export default Portfolio;
