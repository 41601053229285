import React, { useContext, useEffect, useState } from "react";
import { Button, View } from "react-native";
import InputCard from "../components/InputCard";
import InfoCard from "../components/InfoCard";
import { showMessage } from "react-native-flash-message";
import { AuthContext } from "../context/AuthContext";
import { useTheme } from "../context/Theme/ThemeProvider";
import MainButton from "../components/MainButton";
import apiCall from "../utils/apiCall";
import { showFlowMessage } from "../components/FlowMessage";
import { getServerStorage, removeLogin } from "./Login";
import { useLanguage } from "../context/Language/LanguageProvider";
import { Audio } from "expo-av";

interface ChangePasswordChecker {
  upperCase: { title: string; value: boolean };
  lowerCase: { title: string; value: boolean };
  number: { title: string; value: boolean };
  specialChar: { title: string; value: boolean };
  length: { title: string; value: boolean };
  originalPassword: { title: string; value: boolean };
  passwordsMatch: { title: string; value: boolean };
}
async function SoundError() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../assets/sounds/soundserror.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

async function SoundAccept() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../assets/sounds/soundssuccess.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

const ChangePassword = () => {
  const { logout, user, userPass } = useContext(AuthContext);
  const { colors } = useTheme();
  const { words } = useLanguage();

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCHK, setNewPasswordCHK] = useState("");

  const lowerCaseRegex = /[a-z]/;
  const upperCaseRegex = /[A-Z]/;
  const numberRegex = /[0-9]/;
  const specialCharRegex = /[.,:@$!%*?&]/;
  const lengthRegex = /^.{6,}$/;

  const [checker, setChecker] = useState<ChangePasswordChecker>({
    upperCase: {
      title: words["ChangePassword_Checker_UpperCase"],
      value: false,
    },
    lowerCase: {
      title: words["ChangePassword_Checker_LowerCase"],
      value: false,
    },
    number: { title: words["ChangePassword_Checker_Number"], value: false },
    specialChar: {
      title: words["ChangePassword_Checker_SpecialChar"],
      value: false,
    },
    length: { title: words["ChangePassword_Checker_Length"], value: false },
    originalPassword: {
      title: words["ChangePassword_Checker_OriginalPassword"],
      value: false,
    },
    passwordsMatch: {
      title: words["ChangePassword_Checker_PasswordsMatch"],
      value: false,
    },
  });

  const [server, setServer] = useState("");

  useEffect(() => {
    getServerStorage().then((res) => {
      setServer(res.server);
    });
  }, []);

  function save() {
    if (!user) {
      return;
    }

    if (
      !(
        checker.length.value &&
        checker.lowerCase.value &&
        checker.number.value &&
        checker.specialChar.value &&
        checker.upperCase.value &&
        checker.passwordsMatch.value &&
        checker.originalPassword.value
      )
    ) {
      SoundError();
      const errors = Object.values(checker).filter((item) => {
        if (!item.value) {
          return item;
        }
      });
      showFlowMessage({
        message: errors.map((item) => item.title).join("\n"),
        type: "error",
        duration: 5000,
      });
      return;
    }

    apiCall(
      "/change-password",
      {
        type: "main",
        password,
        newPassword,
      },
      "POST"
    )
      .then((response) => {
        if (response.Retcode != 0) {
          SoundError();
          showFlowMessage({
            message: words["Retcode_10006"],
            type: "warning",
          });
          return;
        }
        SoundAccept();
        showFlowMessage({
          message: words["FlowMessage_ChangeInformationSuccess"],
          type: "success",
        });
        removeLogin(user?.ID, server, user?.IsInvestor);
        logout({});
      })
      .catch((err) => {
        console.error(err);
        showFlowMessage({
          message: words["FlowMessage_ChangeInformationError"],
          type: "warning",
        });
      });
  }

  useEffect(() => {
    setChecker({
      upperCase: {
        title: words["ChangePassword_Checker_UpperCase"],
        value: upperCaseRegex.test(newPassword),
      },
      lowerCase: {
        title: words["ChangePassword_Checker_LowerCase"],
        value: lowerCaseRegex.test(newPassword),
      },
      number: {
        title: words["ChangePassword_Checker_Number"],
        value: numberRegex.test(newPassword),
      },
      specialChar: {
        title: words["ChangePassword_Checker_SpecialChar"],
        value: specialCharRegex.test(newPassword),
      },
      length: {
        title: words["ChangePassword_Checker_Length"],
        value: lengthRegex.test(newPassword),
      },
      originalPassword: {
        title: words["ChangePassword_Checker_OriginalPassword"],
        value: password == userPass,
      },
      passwordsMatch: {
        title: words["ChangePassword_Checker_PasswordsMatch"],
        value: newPassword.length > 0 && newPassword == newPasswordCHK,
      },
    });
  }, [newPassword, newPasswordCHK, password]);

  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 20,
        padding: 10,
        flex: 1,
        backgroundColor: colors.SCREEN_BACKGROUND,
      }}
    >
      <View style={{ width: "100%", gap: 5 }}>
        <InfoCard
          type="basic"
          args={{ title: words["UserName"], value: user?.ID }}
        />
        <InputCard
          type="password"
          args={{
            title: words["ChangePassword_CurrentPassword"],
            value: password,
          }}
          response={setPassword}
        />
        <InputCard
          type="password"
          args={{
            title: words["ChangePassword_NewPassword"],
            value: newPassword,
          }}
          response={setNewPassword}
        />
        <InputCard
          type="password"
          args={{
            title: words["ChangePassword_ConfirmNewPassword"],
            value: newPasswordCHK,
          }}
          response={setNewPasswordCHK}
        />
      </View>
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <View style={{ flexBasis: "45%", borderRadius: 10 }}>
          <MainButton
            onPress={save}
            text={words["ChangePassword_Button"]}
          />
        </View>
      </View>
    </View>
  );
};

export default ChangePassword;
