import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useTheme } from '../context/Theme/ThemeProvider';
import socket from "../config/socket";
import apiCall from '../utils/apiCall';
import { dateUtil, dayjs, newDayjs } from '../utils/dateUtil';
import config from '../config';

import { AuthContext } from '../context/AuthContext';
import { IColors } from '../Global';
import { StyleSheet } from 'react-native';

const TradingView = (props: any, { navigation }: any) => {
	const { user } = useContext(AuthContext);

	const { colors } = useTheme();
	const styles = Styles(colors);

	const { symbolName, digits = 5 } = props.route.params;

	const sendMsgToWebView = (message: any) => {
    console.log('sendMsgToWebView', message)
		if (webViewRef?.current && webViewRef.current.contentWindow) {
      console.log('sendMsgToWebView OK', message)
			webViewRef.current.contentWindow.postMessage(message);
		}
	};

	const webViewRef = useRef<HTMLIFrameElement>(null);
	// const [step, setStep] = useState(0.01);
	// const [digits, setDigits] = useState(2);

	const symbolInfo = {
		ticker: symbolName,
		name: symbolName,
		description: symbolName,
		type: 'forex',
		session: '24x7',
		timezone: 'Etc/UTC',
		// exchange: 'Example Exchange',
		minmov: 1,
		pricescale: Math.pow(10, 5), // 100 cents in 1 dollar
		// has_no_volume: true,// Deprecated: use visible_plots_set instead
		visible_plots_set: 'ohlcv',
		has_daily: true,
		has_weekly_and_monthly: true,
		disable_resolution_rebuild: true,

		has_intraday: true, // the symbol includes intraday (minutes) historical data
		supported_resolutions: ['1', '5', '30', '60', '4H', '1D', '1W'],
		// intraday_multipliers: ['1'],
		volume_precision: digits, //Digits
		interval: '1',
		data_status: 'streaming',
		// time_frames: [
		// 	{ text: "5y", resolution: "1W", description: "5 Year" },
		// 	{ text: "1y", resolution: "1W", description: "1 Year" },
		// 	{ text: "3m", resolution: "1D", description: "3 Months"},
		// 	{ text: "1m", resolution: "1D", description: "1 Month" },
		// 	{ text: "1w", resolution: "60", description: "1 Week" },
		// 	{ text: "1d", resolution: "60", description: "1 Day" },
		// 	{ text: "1000y", resolution: "1D", description: "All", title: "All" }
		// ]
	};

	let lastBar = useRef({
		time: 0,
		open: 0,
		high: -1,
		low: Number.MAX_SAFE_INTEGER,
		close: 0,
		// volume: 0,
	});

	const handleMessage = async (data: any) => {
		console.log('---------------------------------------------------------------------');
		console.log('IIonMessageII', data);
		console.log('---------------------------------------------------------------------');
		try {
			if (data.action === 'getBars') {
				const from = dayjs(data.from * 1000).toISOString();
				const to = dayjs(data.to * 1000).toISOString();

				const response = await apiCall('/graph-data', {
					symbol: symbolName,
					from,
					to,
					resolution: data.resolution,
					countBack: data.countBack,
				});

				if (data.firstDataRequest) {
					console.log('=====FIRST DATA REQUEST=====', response[response.length - 1]);
					lastBar.current = response[response.length - 1];
					console.log('======== FIRST  BAR ========', lastBar.current, config.serverTimeDiff);
				}
				sendMsgToWebView({
					action: 'getBars',
					data: response.map((bar: any) => {
						return {
							...bar,
							time: bar.time * 1000 - config.serverTimeDiff,
						};
					}),
				});
			}
		} catch (err) {
			console.log('ERROR', err);
		}
	};

	useEffect(() => {
		if (!symbolName) {
			console.error('Symbol not set!');
			return;
		}

    console.warn('SYMBOL SET', symbolName);

		socket.on(symbolName, (data: any) => {
      console.log('SOCKET DATA', symbolName, data)
			// setLastTick(data);
			// console.log('data', data);
			if (lastBar.current.time === 0) {
				console.log('last bar time zero');
				return;
			}
			console.log('lastBar', lastBar.current);

			const tradeTime = data.Datetime - config.serverTimeDiff;
			const nextBarTime = getNextBarTime(lastBar.current.time);
			// newDayjs

			console.log('tradeTimes', tradeTime, nextBarTime, tradeTime - nextBarTime, tradeTime >= nextBarTime);
			let bar;
			if (tradeTime >= nextBarTime) {
				bar = {
					time: nextBarTime,
					open: data.Bid,
					high: data.Bid,
					low: data.Bid,
					close: data.Bid,
				};
				console.log('[socket] Generate new bar', bar);
			} else {
				bar = {
					...lastBar.current,
					high: Math.max(lastBar.current.high, data.Bid),
					low: Math.min(lastBar.current.low, data.Bid),
					close: data.Bid,
				};
				console.log('[socket] Update the latest bar by price', data.Bid);
			}
			lastBar.current = bar;

			sendMsgToWebView({
				action: 'subscribeBars',
				data: {
					...lastBar.current,
					time: lastBar.current.time * 1000 - config.serverTimeDiff,
				},
			});
		});

		return () => {
			socket.off(symbolName as string);
		};
	}, [symbolName]);

	function getNextBarTime(barTime: number) {
		return barTime * 1000 - config.serverTimeDiff + 60 * 1000;
	}

	// useEffect(() => {
	// 	if (webViewRef.current && webViewRef.current.contentWindow) {
	// 		(webViewRef.current.contentWindow as any).sendMessage = handleMessage;
  //     console.log('SYMBOL_INFO', symbolInfo);
  //     sendMsgToWebView({ action: 'setSymbol', data: symbolInfo });
	// 	}
	// }, [webViewRef.current]);

	return (
		<div style={{ display: 'flex', width: '100vw', height: '100vh' }}>
			<iframe
				src='/charting_library/index.htm'
				ref={webViewRef}
				style={{ flex: 1, backgroundColor: colors.SCREEN_BACKGROUND }}
				onError={(syntheticEvent) => {
					const { nativeEvent } = syntheticEvent;
					console.log('WebView error: ', nativeEvent, syntheticEvent);
				}}
				onLoad={() => {
					console.log('onLoadEnd');
					// sendMsgToWebView({ action: 'setSymbol', data: symbolInfo });
          if(webViewRef.current && webViewRef.current.contentWindow){
            console.log('SYMBOL_INFO', symbolInfo);
            (webViewRef.current.contentWindow as any).sendMessage = handleMessage;
            sendMsgToWebView({ action: 'setSymbol', data: symbolInfo });
          }
          
				}}
				onLoadStart={() => {
          console.log('onLoadStart', !!webViewRef.current);
				}}
			/>
		</div>
	);
};

export default TradingView;

const Styles = (colors: IColors) =>
	StyleSheet.create({
		container: {
			width: '100%',
			height: '100%',
			paddingHorizontal: 10,
			paddingBottom: 10,
			paddingTop: 5,
			gap: 10,
			backgroundColor: colors.SCREEN_BACKGROUND,
		},
		menuWrapper: {
			flexDirection: 'column',
			flexWrap: 'wrap',
			width: '100%',
		},
		bottomContainer: {
			flex: 1,
			paddingHorizontal: 10,
		},
		dataWrapper: {
			flex: 1,
			gap: 10,
		},
	});
