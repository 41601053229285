import React, { useContext, useEffect, useState } from "react";
import {
  Text,
  StyleSheet,
  View,
  Pressable,
  Image,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
  TouchableWithoutFeedback,
  ScrollView,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import { AuthContext } from "../context/AuthContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTheme } from "../context/Theme/ThemeProvider";
import Tooltip from "react-native-walkthrough-tooltip";
import { FONTSIZE, RADIUS } from "../Global";
import checkVersion from "react-native-store-version";
import { useLanguage } from "../context/Language/LanguageProvider";
import socket from "../config/socket";
import apiCall from "../utils/apiCall";
import { getLocales } from "expo-localization";
import { Ionicons } from "@expo/vector-icons";
import { ILanguage, Languages } from "./Settings/constants";
import CustomizedTextInput from "../components/CustomizedTextInput";
import InputCard from "../components/InputCard";
import MainButton from "../components/MainButton";

const getDeviceLanguage = async (): Promise<string> => {
  return await getLocales()[0].languageCode;
};
const getLanguage = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem("language");
    if (!jsonValue) {
      const deviceLang = await getDeviceLanguage();
      const lang =
        Languages.find((item) => item.code === deviceLang) || Languages[0];
      await setLanguageStorage(lang);
      return lang;
    }
    return JSON.parse(jsonValue);
  } catch (e) {
    // error reading value
  }
};

const setLanguageStorage = async (lang: ILanguage) => {
  try {
    await AsyncStorage.setItem("language", JSON.stringify(lang));
  } catch (e) {
    // error reading value
  }
};

// const getLastServer = async () => {
// 	try {
// 		const lastServer = await AsyncStorage.getItem('lastServer');
// 		console.log('getlastServer:', lastServer);
// 		return lastServer;
// 	} catch (e) {
// 		// error reading value
// 	}
// };

const getLastLogin = async () => {
  try {
    const lastLogin = await AsyncStorage.getItem("lastLoginAccount");
    return lastLogin != null ? JSON.parse(lastLogin) : null;
  } catch (e) {
    // error reading value
  }
};

const setTipsStatus = async () => {
  try {
    await AsyncStorage.setItem("tips", JSON.stringify(true));
  } catch (e) {
    // error reading value
  }
};
const getTipsStatus = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem("tips");
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};
const setServerStorage = async (ServerName: string, ServerIP: string) => {
  try {
    await AsyncStorage.setItem(
      "server",
      JSON.stringify({ server: ServerName, url: ServerIP })
    );
  } catch (e) {
    // error reading value
  }
};

export const getServerStorage = async () => {
  try {
    const serverStr = await AsyncStorage.getItem("server");
    return serverStr != null ? JSON.parse(serverStr) : null;
    // return { server: 'REAL', url: 'http://192.168.1.123:3051' };
  } catch (e) {
    // error reading value
  }
};
const getLogins = async (selectedServer: string) => {
  try {
    const jsonValue = await AsyncStorage.getItem(`loginList-${selectedServer}`);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

export const removeLogin = async (
  login: string,
  selectedServer: string,
  isInvestor: boolean
) => {
  try {
    console.log("removeLogin:", login, selectedServer, isInvestor);
    const jsonValue = await AsyncStorage.getItem(`loginList-${selectedServer}`);
    if (jsonValue != null) {
      const logins = JSON.parse(jsonValue);
      console.log("removeLogin:", logins);
      const newLogins = await logins.filter((item: any) => {
        if (item.login.toUpperCase() != login.toUpperCase()) {
          return true;
        } else if (item.login == login && item.isInvestor != isInvestor) {
          return true;
        }
      });
      await AsyncStorage.setItem(
        `loginList-${selectedServer}`,
        JSON.stringify(newLogins)
      );
      return newLogins;
    }
  } catch (e) {
    // error reading value
  }
};

const getDarkMode = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem(`darkMode`);
    // console.log('getDarkMode:', jsonValue);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.log("getDarkModeError: \n", e);
    return true;
  }
};

const serverList = [
  // { server: 'REAL', url: 'http://44.218.14.105:3051' },
  { server: "REAL", url: "https://api.winexterminal.com" },
  // { server: "DEMO", url: "https://terminal-api.pmprs.xyz:2053" },
  // { server: "TEST", url: "http://192.168.1.150:8443" },
];
const LoginScreen = ({}): React.ReactElement => {
  const { darkMode, colors, setScheme } = useTheme();
  const { setLanguage, words } = useLanguage();
  const { checkLogin, setSliderImages } = useContext(AuthContext);

  const styles = Styles(colors);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [selectedUserIsInvestor, setSelectedUserIsInvestor] = useState(false);

  const [lastLogin, setLastLogin] = useState<any>({
    login: "",
    password: "",
    isInvestor: false,
  });

  const [loginButtonToolTip, setLoginButtonToolTip] = useState(true);
  const [serverButtonToolTip, setServerButtonToolTip] = useState(true);
  const [loginListToolTip, setLoginListToolTip] = useState(true);

  const [server, setServer] = useState({
    server: "REAL",
    url: "https://terminal-api.pmprs.xyz:8443",
  }); //http://44.218.14.105:3051
  const [loginList, setLoginList] = useState<Array<any>>([]);
  const [newVersionAvailable, setNewVersionAvailable] =
    useState<boolean>(false);

  const handleLogin = (uname?: string, pass?: string) => {
    if (uname && pass) {
      checkLogin(uname, pass, false, server);
    } else {
      checkLogin(username, password, false, server);
      setPassword("");
    }
  };

  const handleLoginWithoutSave = async () => {
    await checkLogin(username, password, false, server, false);
    setPassword("");
  };

  useEffect(() => {
    getLanguage().then((data) => {
      setLanguage(data?.code);
    });

    getServerStorage()
      .then((lastServer) => {
        if (lastServer) {
          const _lastServer = serverList.find(
            (item) => item.server == lastServer.server
          );
          if (_lastServer) {
            setServer((prev) => _lastServer);
            setServerStorage(_lastServer.server, _lastServer.url);
          }
        } else {
          setServer((prev) => serverList[0]);
        }
      })
      .catch(() => {
        setServer((prev) => serverList[0]);
      });

    AsyncStorage.removeItem("user"); // User bilgisini kaldırıyorum.
    getLastLogin().then((data) => {
      if (data) {
        setLastLogin(data);
      }
    });

    getDarkMode().then((data) => {
      setScheme(!!data);
    });

    getTipsStatus().then((data) => {
      if (data) {
        setLoginButtonToolTip(false);
        setServerButtonToolTip(false);
        setLoginListToolTip(false);
      }
    });

  }, []);

  useEffect(() => {
    // AsyncStorage.setItem('lastServer', JSON.stringify(server));
    setServerStorage(server.server, server.url);
    getLogins(server.server).then((data) => {
      setUsername("");
      setPassword("");
      setSelectedUserIsInvestor(false);
      if (data) {
        setLoginList(data);
      } else {
        setLoginList([]);
      }
    });
    setServerStorage(server.server, server.url);
    socket.setServer(server.url);
  }, [server]);

  useEffect(() => {
    if (loginList) {
      if (loginList.length > 0) {
        const tempLogin = loginList.find(
          (item: any) =>
            item.login == lastLogin.login &&
            item.isInvestor == lastLogin.isInvestor
        );
        if (tempLogin) {
          setUsername(tempLogin.login);
          setPassword(tempLogin.password);
          setSelectedUserIsInvestor(tempLogin.isInvestor);
        }
      }
    }
  }, [loginList]);
  const isValid = username && password;

  return (
    <View style={styles.container}>
      {/* {isLoading ? (
        <View style={{justifyContent: 'center', position: 'absolute', alignSelf: 'center', width:'100%', height:'100%'}}>
          <ActivityIndicator
            size={'large'}
            color={colors.ACTIVITY_INDICATOR}
          />
        </View>
      ): (<View></View>)} */}
      <View style={[styles.containers, { flex: 2, opacity: 1 }]}>
        <Image
          source={require("../assets/logo_winexmarkets.png")}
          style={[
            styles.image,
            darkMode ? { tintColor: colors.BOTTOM_ICON_FOCUSED } : null,
            { flex: 2 },
          ]}
        />
      </View>
      <View
        style={[styles.containers, { flexGrow: 0, flexShrink: 1, opacity: 1 }]}
      >
        <View
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <InputCard
            type="noTitle"
            args={{
              title: words["UserName"],
              value: username,
            }}
            response={setUsername}
            containerStyle={styles.inputView}
          />
          {/* <View style={styles.inputView}>
            <CustomizedTextInput
              style={styles.input}
              placeholder={words[`UserName`]}
              placeholderTextColor={colors.TEXT}
              onChangeText={(text) => setUsername(text)}
              value={username}
              keyboardType="default"
              autoCorrect={false}
            />
          </View> */}
          <InputCard
            type="noTitlePassword"
            args={{
              title: words["Password"],
              value: password,
            }}
            response={setPassword}
            containerStyle={styles.inputView}
          />
          {/* <View style={styles.inputView}>
            <CustomizedTextInput
              style={[styles.input, { width: "90%" }]}
              placeholder={words[`Password`]}
              placeholderTextColor={colors.TEXT}
              onChangeText={(text) => setPassword(text)}
              value={password}
              secureTextEntry={!passwordVisible}
              keyboardType="default"
              autoCorrect={false}
            />
            <Pressable
              onPress={() => setPasswordVisible(!passwordVisible)}
              style={{ width: 32, height: "100%", justifyContent: "center", alignItems: "center"}}
            >
              {passwordVisible ? (
                <Ionicons
                  name="eye-outline"
                  size={20}
                  color={colors.TEXT}
                />
              ) : (
                <Ionicons
                  name="eye-off-outline"
                  size={20}
                  color={colors.TEXT}
                />
              )}
            </Pressable>
          </View> */}
        </View>
        <View
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Tooltip
            isVisible={loginButtonToolTip}
            content={
              <View style={{ width: "100%" }}>
                <Text
                  style={{
                    color: colors.TEXT,
                    textAlign: "center",
                    fontWeight: "800",
                  }}
                >
                  {words["Login_ToolTip_1"]}{" "}
                </Text>
                <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                  {words["Login_ToolTip_2"]}{" "}
                </Text>
                <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                  {words["Login_ToolTip_3"]}{" "}
                </Text>
              </View>
            }
            parentWrapperStyle={{
              width: "100%",
              alignItems: "center",
              maxHeight: "auto",
            }}
            onClose={() => {
              setLoginButtonToolTip(false);
            }}
            placement="center"
            // below is for the status bar of react navigation bar
            contentStyle={{
              width: "100%",
              alignItems: "center",
              maxHeight: "auto",
              backgroundColor: colors.CARD_BACKGROUND2,
            }}
            topAdjustment={0}
          >
            <MainButton
              onPress={() => {
                handleLogin();
              }}
              onLongPress={() => {
                handleLoginWithoutSave();
              }}
              text={words["Login"]}
              buttonStyle={
                !isValid ? { opacity: 0.5, width: "80%" } : { width: "80%" }
              }
              disabled={!isValid}
            />
            {/* <Pressable
              style={[
                !isValid ? [styles.button, { opacity: 0.5 }] : styles.button,
              ]}
              onPress={() => {
                handleLogin();
              }}
              onLongPress={() => {
                handleLoginWithoutSave();
              }}
              disabled={!isValid}
            >
              <Text style={styles.buttonText}>{words["Login"]}</Text>
            </Pressable> */}
          </Tooltip>
        </View>
      </View>
      <View style={[styles.containers, { flex: 2, opacity: 1 }]}>
        <View style={[styles.containers, { flex: 1, opacity: 1 }]}>
          <Tooltip
            isVisible={loginListToolTip && !loginButtonToolTip}
            content={
              <View style={{ width: "100%" }}>
                <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                  {words["Login_ToolTip_4"]}
                </Text>
                <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                  {words["Login_ToolTip_5"]}
                </Text>
              </View>
            }
            parentWrapperStyle={{
              width: "100%",
              alignItems: "center",
              maxHeight: "auto",
            }}
            onClose={() => {
              setLoginListToolTip(false);
            }}
            placement="center"
            // below is for the status bar of react navigation bar
            contentStyle={{
              width: "100%",
              alignItems: "center",
              maxHeight: "auto",
              backgroundColor: colors.CARD_BACKGROUND2,
            }}
            topAdjustment={0}
          >
            <View
              style={[
                styles.containers,
                { borderTopWidth: 0.5, borderColor: colors.TEXT },
              ]}
            >
              <Text
                style={{
                  color: colors.TEXT,
                  position: "relative",
                  width: "40%",
                  textAlign: "center",
                  top: -12,
                  backgroundColor: colors.SCREEN_BACKGROUND,
                }}
              >
                {words["Login_RegisteredAccounts"]}
              </Text>
              {loginList.length > 0 ? (
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  horizontal={true}
                  style={{ maxWidth: "100%" }}
                >
                  {loginList.map((item, index) => (
                    <Pressable
                      key={index}
                      style={[
                        item.login == username &&
                        item.isInvestor == selectedUserIsInvestor
                          ? [
                              styles.serverAndLoginsContainerSelected,
                              item.isInvestor && styles.investorButtonSelected,
                            ]
                          : [
                              styles.serverAndLoginsContainer,
                              item.isInvestor && styles.investorButton,
                            ],
                      ]}
                      onPress={() => {
                        setUsername(item.login);
                        setPassword(item.password);
                        setSelectedUserIsInvestor(item.isInvestor);
                        //handleLogin(item.login, item.password);
                      }}
                      onLongPress={async () => {
                        setLoginList(
                          await removeLogin(
                            item.login,
                            server.server,
                            item?.isInvestor
                          )
                        );
                      }}
                    >
                      <Text
                        style={[
                          item.login == username &&
                          item.isInvestor == selectedUserIsInvestor
                            ? styles.serverAndLoginsContainerSelectedText
                            : styles.serverAndLoginsContainerText,
                        ]}
                      >
                        {item.login}
                      </Text>
                    </Pressable>
                  ))}
                </ScrollView>
              ) : (
                <Text style={{ color: colors.TEXT }}>
                  {words["Login_NoRegisteredAccounts"]}
                </Text>
              )}
            </View>
          </Tooltip>
        </View>
        <View style={[styles.containers, { flex: 1, opacity: 1 }]}>
          <Tooltip
            isVisible={
              !loginListToolTip && !loginButtonToolTip && serverButtonToolTip
            }
            content={
              <View style={{ width: "100%" }}>
                <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                  {words["Login_ToolTip_6"]}
                </Text>
                <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                  {words["Login_ToolTip_7"]}
                </Text>
              </View>
            }
            parentWrapperStyle={{
              width: "100%",
              alignItems: "center",
              maxHeight: "auto",
            }}
            onClose={() => {
              setServerButtonToolTip(false);
              setTipsStatus();
            }}
            placement="center"
            // below is for the status bar of react navigation bar
            contentStyle={{
              width: "100%",
              alignItems: "center",
              maxHeight: "auto",
              backgroundColor: colors.CARD_BACKGROUND2,
            }}
            topAdjustment={0}
          >
            <View
              style={[
                styles.containers,
                { borderTopWidth: 0.5, borderColor: colors.TEXT },
              ]}
            >
              <Text
                style={{
                  color: colors.TEXT,
                  position: "relative",
                  width: "30%",
                  textAlign: "center",
                  top: -12,
                  backgroundColor: colors.SCREEN_BACKGROUND,
                }}
              >
                {words["Login_Servers"]}
              </Text>
              {serverList.length > 0 ? (
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  horizontal={true}
                  style={{ maxWidth: "100%" }}
                >
                  {serverList.map((item, index) => (
                    <Pressable
                      key={index}
                      style={
                        item.server != server.server
                          ? styles.serverAndLoginsContainer
                          : styles.serverAndLoginsContainerSelected
                      }
                      onPress={() => setServer(item)}
                    >
                      <Text
                        style={
                          item.server != server.server
                            ? styles.serverAndLoginsContainerText
                            : styles.serverAndLoginsContainerSelectedText
                        }
                      >
                        {item.server}
                      </Text>
                    </Pressable>
                  ))}
                </ScrollView>
              ) : (
                <Text style={{ color: colors.TEXT }}>
                  Sistemde sunucu bulunmamaktadır.
                </Text>
              )}
            </View>
          </Tooltip>
        </View>
      </View>
      <Text
        style={{
          position: "absolute",
          fontWeight: "300",
          fontSize: FONTSIZE.m,
          marginHorizontal: "auto",
          color: colors.TEXT,
          bottom: 10,
        }}
      >
        {" "}
        v1.0.0
      </Text>
    </View>
  );
};

const Styles = (COLOR: any) =>
  StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLOR.SCREEN_BACKGROUND,
    },
    containers: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    input: {
      height: 40,
      lineHeight: 40,
      width: "100%",
      color: COLOR.TEXT,
      outlineStyle: "none",
    },
    inputView: {
      width: "80%",
      borderColor: "gray",
      borderWidth: 1,
      marginBottom: 10,
      backgroundColor: COLOR.SCREEN_BACKGROUND,
      flexDirection: "row",
    },
    button: {
      width: "80%",
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLOR.MAIN_BUTTON_BACKGORUND,
      borderRadius: 5,
    },
    saveButton: {
      width: "38%",
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLOR.MAIN_BUTTON_BACKGORUND,
      borderRadius: 5,
    },
    buttonText: {
      color: COLOR.MAIN_BUTTON_TEXT,
    },
    image: {
      width: "80%",
      height: 200,
      resizeMode: "contain",
      marginBottom: 20,
    },
    serverAndLoginsContainer: {
      width: Dimensions.get("window").width / 4,
      padding: 10,
      margin: 10,
      borderRadius: RADIUS.CARD,
      borderWidth: 0.5,
      borderColor: COLOR.TEXT,
    },
    serverAndLoginsContainerSelected: {
      width: Dimensions.get("window").width / 4,
      padding: 10,
      margin: 10,
      borderRadius: RADIUS.CARD,
      backgroundColor: COLOR.MAIN_BUTTON_BACKGORUND,
      borderColor: COLOR.MAIN_BUTTON_BACKGORUND,
      borderWidth: 2,
    },
    serverAndLoginsContainerText: {
      color: COLOR.TEXT,
      textAlign: "center",
    },
    serverAndLoginsContainerSelectedText: {
      color: COLOR.MAIN_BUTTON_TEXT,
      textAlign: "center",
    },

    investorButton: {
      borderColor: COLOR.ORANGE,
      borderWidth: 2,
    },
    investorButtonSelected: {
      backgroundColor: COLOR.ORANGE,
      borderColor: COLOR.ORANGE,
      borderWidth: 2,
    },
    investorButtonSelectedText: {
      color: COLOR.BLACK,
    },
  });

export default LoginScreen;
