import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import { CONSTANTS } from '../Global';

const socketSettings:Partial<ManagerOptions & SocketOptions> = {
      //transports: ['websocket'],
      withCredentials: true,
      autoConnect: false,
      reconnection: true,
      reconnectionDelay: 2500,
      timeout: 15000,
      reconnectionAttempts: 15,
};

class SocketConnection {
  private socket: Socket;
  private accessToken: string;
  private server: string = CONSTANTS.CPP_URL;
  // private initialized: boolean = false;
  // private reLogin?: () => void;
  // private events: Map<string, (data: any) => void>;

  constructor() {
    // this.server = CONSTANTS.CPP_URL;
    // this.events = new Map();
    this.socket = io(this.server, {
      ...socketSettings,
    });

    this.accessToken = '';
  }

  setToken(token: string) {
    this.accessToken = token;
  }

  setServer(server: string) {
    this.server = server;

    const socket = io(server, {
      ...socketSettings,
    });
    console.log('Socket server changed to:', server);

    // this.events.forEach((callback, event) => {
    //   socket.on(event, callback);
    //   this.socket.off(event);
    // })

    this.socket = socket;

    // this.socket.disconnect();
  }

  // setReLogin(reLogin: () => void) {
  //   this.reLogin = reLogin;
  // }

  get serverUrl() {
    return this.server;
  }

  get token() {
    return this.accessToken;
  }

  connect() {
    console.log('Connecting to socket');
    if (!this.accessToken) {
      console.error('Token not found');
      throw new Error('Token not found');
    }

    this.socket.io.opts.extraHeaders = {
      Authorization: `Bearer ${this.accessToken}`,
    };

    this.socket.io.opts.query = {
      im: 'client',
    };

    // this.on('connect', () => {
    //   console.log('Socket connected');
    //   if(this.initialized){
    //     this.initialized = false;
    //     this.reLogin && this.reLogin();
    //   }
    //   else{
    //     this.initialized = true;
    //   }
    // });

    // this.on('disconnect', () => {
    //   console.log('Socket disconnected');
    // });

    this.socket.connect();
  }

  on(event: string, callback: (data: any) => void) {
    // this.events.set(event, callback);
    this.socket.on(event, callback);

  }

  onAny(callback: (eventName: string, ...args: any) => void) {
    this.socket.onAny(callback);
  }

  off(event: string) {
    // this.events.delete(event);
    this.socket.off(event);
  }

  disconnect() {
    this.socket.disconnect();
  }

  removeAllListeners() {
    this.socket.removeAllListeners();
  }

  close() {
    this.socket.close();
  }

  get connected() {
    return this.socket.connected;
  }
}

const socket = new SocketConnection();

export default socket;
