import { useContext, useState } from "react";
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";

import Swiper from "react-native-web-swiper";
import ProminentInvestors from "../components/ProminentInvestors";
import ReconmmendedActions from "../components/RecommendedActions";
import { AuthContext } from "../context/AuthContext";
import { IGroupSymbol, ISymbol, PADDING, RADIUS } from "../Global";
import { useTheme } from "../context/Theme/ThemeProvider";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { showFlowMessage } from "../components/FlowMessage";
import { NoVisitorsAllowed } from "../config/visitor";
import { InvestorWarningBox } from "../components/InvestorWarningBox";
import { useLanguage } from "../context/Language/LanguageProvider";
import SymbolListItemLogic from "../components/Symbol/SymbolListItem/SymbolListItemLogic";
import apiCall from "../utils/apiCall";

const defaultSliderImages = [
  require("../assets/slide/s1.png"),
  require("../assets/slide/s2.png"),
  require("../assets/slide/s3.png"),
  require("../assets/slide/s4.png"),
];

const Home = ({ navigation }: any) => {
  const { group, user } = useContext(AuthContext);
  const { colors } = useTheme();
  const styles = Styles(colors);
  const { words } = useLanguage();
  const [sliderImages, setSliderImages] = useState<Array<string>>([]);

  if (!group?.Symbols || !user || !user?.FavoriteSymbols) {
    return <View></View>;
  }

  apiCall(
    "/slider-list",
    {
      broker: "WN",
    },
    "POST"
  )
    .then((data) => {
      if (typeof data?.SliderURLs != "object") {
        return;
      }
      if (data?.SliderURLs && data?.SliderURLs.length > 0) {
        setSliderImages(data.SliderURLs);
      }
    })
    .catch((CheckSliderImagesError) => {
      setSliderImages([]);
      console.log("CheckSliderImagesError:", CheckSliderImagesError);
    });

  const menuObj = [
    {
      id: 1,
      name: "Deposit",
      value: "DepositSelection",
      icon: (
        <MaterialCommunityIcons
          name="cash-fast"
          size={24}
          color={colors.CONTENT_ICON}
        />
      ),
      onClick: () => {
        navigation.navigate("DepositSelection");
      },
    },
    {
      id: 2,
      name: "Withdraw",
      value: "Withdraw",
      icon: (
        <MaterialCommunityIcons
          name="cash-fast"
          size={24}
          color={colors.CONTENT_ICON}
        />
      ),
      onClick: () => {
        navigation.navigate("Withdraw", { user });
      },
    },
    {
      id: 3,
      name: "Traders",
      value: "Traders",
      icon: <Ionicons name="people" size={24} color={colors.CONTENT_ICON} />,
      onClick: () => {
        showFlowMessage({
          message: words["FlowMessage_UnderConstruction"],
          type: "warning",
          duration: 750,
        });
      },
    },
    {
      id: 4,
      name: "Robots",
      value: "Robots",
      icon: (
        <MaterialCommunityIcons
          name="robot-excited-outline"
          size={24}
          color={colors.CONTENT_ICON}
        />
      ),
      onClick: () => {
        showFlowMessage({
          message: words["FlowMessage_UnderConstruction"],
          type: "warning",
          duration: 750,
        });
      },
    },
  ];

  const swiperImages = () => {
    if (sliderImages?.length > 0) {
      console.log("sliderImages", sliderImages);
      return sliderImages.map((item: any, index: number) => {
        return (
          <View key={index} style={styles.slide}>
            <Image style={styles.slideImage} source={{ uri: item }} />
          </View>
        );
      });
    } else {
      console.log("defaultSliderImages", defaultSliderImages);
      return defaultSliderImages.map((item: any, index: number) => {
        return (
          <View key={index} style={styles.slide}>
            <Image style={styles.slideImage} source={item} />
          </View>
        );
      });
    }
  };

  return (
    <View style={{ flex: 1, backgroundColor: colors.SCREEN_BACKGROUND }}>
      {user.IsInvestor && <InvestorWarningBox />}
      <ScrollView
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={true}
      >
        <View style={styles.container}>
          <View style={styles.carousel}>
            <Swiper
              vertical={false}
              loop={true}
              timeout={8}
              controlsEnabled={false}
              containerStyle={styles.wrapper}
            >
              {swiperImages()}
            </Swiper>
          </View>

          {/* Verifiction Alert */}
          {/* <VerificationAlert /> */}
          {/* Buttons */}
          <View style={styles.menuWrapper}>
            {menuObj.map((item: any) => {
              if (user?.IsInvestor && NoVisitorsAllowed.includes(item.value)) {
                return null;
              }
              console.log(item.value);
              return (
                <View key={item.name} style={styles.menuItem}>
                  <Pressable
                    key={item.name}
                    style={styles.menuItemButton}
                    onPress={item.onClick}
                  >
                    <View>{item.icon}</View>
                    <Text style={styles.menuItemText}>
                      {words[`SideMenu_${item.name}`]}
                    </Text>
                  </Pressable>
                </View>
              );
            })}
            {/* Recommeded Action Button */}
          </View>
          <ReconmmendedActions />
          <ProminentInvestors />
          <View style={styles.infoWrapper}>
            <Image
              source={require("../assets/campaign.png")}
              style={{ width: 25, height: 25, marginLeft: 6 }}
            />
            <Text
              style={{
                color: colors.TEXT,
                flex: 1,
                paddingHorizontal: PADDING.XS,
                fontSize: 12.8,
              }}
            >
              {words["Home_Announcement"]}
            </Text>
          </View>
          <View>
            {group.Symbols?.filter((item: IGroupSymbol) =>
              item?.Symbol.Category.includes("popular")
            )
              .slice(0, 10)
              .map((item: IGroupSymbol, index: number) => {
                return (
                  <SymbolListItemLogic
                    key={item.Symbol.Symbol}
                    groupSymbol={item}
                    navigation={navigation}
                    backgroundColor={
                      index % 2 === 0
                        ? colors.CARD_BACKGROUND1
                        : colors.CARD_BACKGROUND2
                    }
                    textColor={
                      index % 2 === 0 ? colors.CARD_TEXT1 : colors.CARD_TEXT2
                    }
                  />
                );
              })}
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default Home;

const Styles = (COLOR: any) =>
  StyleSheet.create({
    container: {
      flex: 1,
      gap: 10,
      padding: 10,
      backgroundColor: COLOR.SCREEN_BACKGROUND,
    },
    carousel: {
      height: 180,
      borderRadius: 10,
      width: "100%",
    },
    menuWrapper: {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 6,
    },
    slideImage: {
      flex: 1,
      height: 100,
      width: "100%",
      resizeMode: "contain",
    },
    menuItem: {
      flexShrink: 0,
      flexGrow: 1,
    },
    menuItemButton: {
      backgroundColor: COLOR.CARD_BACKGROUND1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: 10,
      padding: 10,

      flex: 1,
      borderRadius: RADIUS.CARD,
      shadowOffset: { width: -2, height: 4 },
      shadowColor: COLOR.SHADOW1,
      shadowOpacity: 0.08,
    },
    menuItemText: {
      color: COLOR.TEXT,
      fontSize: 16,
    },
    infoWrapper: {
      flexDirection: "row",
      alignItems: "center",
      padding: 4,
      gap: 8,
      backgroundColor: COLOR.CARD_BACKGROUND1,
      borderRadius: 4,
    },
    wrapper: { width: "100%" },
    slide: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
    },

    text: {
      color: COLOR.TEXT_OPPOSITE,
      fontSize: 30,
      fontWeight: "bold",
    },
  });
