import {ScrollView, Text, View} from 'react-native';
import {FONTSIZE, PADDING} from '../../Global';
import BankCardIcon from '../../components/BankCardWithIcon';
import {useTheme} from '../../context/Theme/ThemeProvider';
import { useLanguage } from '../../context/Language/LanguageProvider';

const DepositWithCash = (props: any) => {
  const Banks = props.route.params.Banks;
  const Phone = props.route.params.Phones.bank.deposit;
  const {colors, darkMode} = useTheme();
  const { words } = useLanguage();

  return (
    <ScrollView style={{ flex: 1, flexDirection: 'column', paddingHorizontal: PADDING.S, backgroundColor: colors.SCREEN_BACKGROUND }}>
			<View style={{ backgroundColor: colors.CARD_BACKGROUND1, borderRadius: 5, marginVertical: 5 }}>
				<Text style={{ textAlign: 'justify', paddingVertical: 8, paddingHorizontal: '5%', fontSize: FONTSIZE.m, color: colors.TEXT }}>{words['DepositWithCash_Warning']}</Text>
			</View>
			{Banks.map((item: any) => (
				<BankCardIcon
					key={item.id}
					title={item.title}
					icon={item.id}
					route='CurrencySelection'
					param={{ Bank: item, Phone: Phone }}
					navigation={props.navigation}
					style={{ marginVertical: 5 }}
					iconColor={darkMode ? colors.WHITE: undefined}
				/>
			))}
		</ScrollView>
  );
};

export default DepositWithCash;
